import { Component } from '@angular/core';
import { FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

export interface DateRangepickerProps extends FormlyFieldProps {
  format?: string;
}
@Component({
  selector: 'thk-formly-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.css']
})
export class FormlyDateRangePickerComponent extends FieldType<FieldTypeConfig<DateRangepickerProps>> {
}
