import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, tap, throwError } from 'rxjs';
import { NotificationMessage } from '../../components';
import { ErrorService } from '../../store';
import { ActionTrackerService } from '../action-tracker.service';
import { ModalService } from '../modal.service';

export const PUBLIC_RESOURCES = new HttpContextToken<boolean>(() => false);
export const TRACKER_ID = new HttpContextToken<string>(() => '');
export const GUEST_CART_TOKEN = new HttpContextToken<string>(() => '');
export const RES_TYPE = new HttpContextToken<string>(() => '');
interface HttpMetadata {
  publicResources?: boolean;
  trackerId?: string;
  guestCartToken?: string;
  responseType?: string;
}

export type HttpQueryParams = {
  [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
};

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private handleHttpErrorsSubject = new Subject<any>();
  handleHttpErrors$ = this.handleHttpErrorsSubject.asObservable();

  constructor(
    private readonly http: HttpClient,
    private readonly modalService: ModalService,
    private actionTrackerService: ActionTrackerService,
    private errorService: ErrorService
  ) {}

  get<T>(path: string, params?: HttpQueryParams, options?: HttpMetadata): Observable<T> {
    return this.http.get<T>(`${path}`, { params, context: this.getHttpContext(options) }).pipe(
      tap(() => this.handleSuccess(options)),
      catchError((err) => this.handleError(err, { path, trackerId: options?.trackerId }))
    );
  }

  post<T, D = unknown>(path: string, data: D, params?: HttpQueryParams, options?: HttpMetadata): Observable<T> {
    return this.http.post<T>(`${path}`, data, { params, context: this.getHttpContext(options) }).pipe(
      tap(() => this.handleSuccess(options)),
      catchError((err) => this.handleError(err, { path, trackerId: options?.trackerId }))
    );
  }

  patch<T, D = unknown>(path: string, data: D, params?: HttpQueryParams, options?: HttpMetadata): Observable<T> {
    return this.http.patch<T>(`${path}`, data, { params, context: this.getHttpContext(options) }).pipe(
      tap(() => this.handleSuccess(options)),
      catchError((err) => this.handleError(err, { path, trackerId: options?.trackerId }))
    );
  }

  put<T, D = unknown>(path: string, data: D, params?: HttpQueryParams, options?: HttpMetadata): Observable<T> {
    return this.http.put<T>(`${path}`, data, { params, context: this.getHttpContext(options) }).pipe(
      tap(() => this.handleSuccess(options)),
      catchError((err) => this.handleError(err, { path, trackerId: options?.trackerId }))
    );
  }

  delete<T>(path: string, params?: HttpQueryParams, options?: HttpMetadata): Observable<T> {
    return this.http.delete<T>(`${path}`, { params, context: this.getHttpContext(options) }).pipe(
      tap(() => this.handleSuccess(options)),
      catchError((err) => this.handleError(err, { path, trackerId: options?.trackerId }))
    );
  }

  private emitError(trackerId: string, error: { errors: { code: string }[] }) {
    const errorMessages: NotificationMessage[] = (error.errors || [])
      .map((err) => err.code)
      .filter(Boolean)
      .map((err) => ({ type: 'error', message: err }));

    this.actionTrackerService.fail(trackerId, { messages: errorMessages });
  }

  private getHttpContext(options?: HttpMetadata) {
    return new HttpContext()
      .set(PUBLIC_RESOURCES, !!options?.publicResources)
      .set(TRACKER_ID, options?.trackerId)
      .set(GUEST_CART_TOKEN, options?.guestCartToken)
      .set(RES_TYPE, options?.responseType);
  }

  private handleError(err: any, { trackerId, path }: { trackerId?: string; path: string }) {
    //log.error(`Error: ${context}: `, err);
    if (err.status >= 400 && err.status <= 499) {
      if (trackerId) {
        this.emitError(trackerId, err.error);
      }
      this.handleHttpErrorsSubject.next({
        path,
        status: err.status,
        error: this.errorService.getErrorMessage(err.error),
      }); // Dispatch client error
    }
    if (err.status >= 500) {
      // Server error responses (500 – 599) Display this message if server error only
      this.modalService.message({ title: 'Error', message: err.message });
    }
    return throwError(() => err);
  }

  private handleSuccess(options?: HttpMetadata) {
    options?.trackerId && this.actionTrackerService.success(options.trackerId);
  }
}
