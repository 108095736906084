<div class="course-overview p-4">
  <!-- <div class="flex items-center justify-between mb-4">
    <div class="page-heading">
      <h3 *ngIf="transactionsPagination" class="text-2xl font-semibold text-neutral-800">
        {{transactionsPagination.results[0]?.instructor?.fullname || ''}}'s Payout Request
      </h3>
    </div>
  </div> -->
  <div class="flex flex-col gap-4 mb-4">
    <div class="flex flex-row gap-4">
      <app-metric-card
        class="w-full"
        [data]="{ title: 'Total Amount Requested', value: (request.requested_amount | currency:request.currency_symbol) || '' }">
      </app-metric-card>
      <app-metric-card
        class="w-full"
        [data]="{ title: 'Payout Method', value: request.payment_method }">
      </app-metric-card>
      <app-metric-card
        class="w-full"
        [data]="{ title: 'Total Transactions', value: request.no_of_transactions + '' }">
      </app-metric-card>
      <app-metric-card
        class="w-full"
        [data]="{ title: 'Created On', value: (request.date_created| date:'dd MMMM yyyy hh:mm:ss') || '' }">
      </app-metric-card>
    </div>
  </div>
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="actions flex gap-4">
      <!-- Filter -->
      <div class="relative">
        <button (click)="dropdown('filter')" class="btn-neutral">
          <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
          <span>Filter</span>
        </button>
        <div [ngClass]="isDropdown === 'filter' ? '' : 'hidden'"
          class="bg-white shadow-lg rounded px-5 py-5 w-[200px] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
          <div class="form">
            <form [formGroup]="filterForm">
              <formly-form [form]="filterForm" [fields]="filterFormFields">
              </formly-form>
            </form>
          </div>
          <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
            <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">
              <span>Reset</span>
            </button>
          </div>
        </div>
      </div>
      <thk-date-range-picker ngModel (ngModelChange)="filterByDate($event)" format="YYYY-MM-DD" placeholder="Select range date"></thk-date-range-picker>
      <!-- Search With Dropdown -->
      <div class="input-combine flex">
        <div class="select2">
          <div class="relative">
            <a (click)="dropdown('search')" type="button" aria-haspopup="listbox" aria-expanded="true"
              aria-labelledby="listbox-label" class="btn-neutral rounded-r-none">
              <div class="selected-item truncate">Course ID</div>
              <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
            </a>
            <ul [ngClass]="isDropdown === 'search' ? '' : 'hidden'" tabindex="-1" role="listbox"
              aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3" class="select-items "
              ng-reflect-ng-class="hidden">
              <li (click)="dropdown('search')" id="listbox-option-0" role="option" class="item">Item 1</li>
            </ul>
          </div>
        </div>
        <div class="input-group">
          <div class="group !rounded-l-none">
            <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
            <input placeholder="Enter your full name" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-3 w-full overflow-hidden card rounded-lg border border-neutral-100">
    <div class="card-subheader flex items-center justify-between px-6 py-5">
      <div class="flex items-center gap-1">
        <h6>Transactions</h6>
        <div *ngIf="transactionsPagination" class="tag tag-sm">{{transactionsPagination.count}} Transactions</div>
      </div>
    </div>
    <div class="card-body">
      <div class="courses-list">
        <!-- Table -->
        <div class=" datagrid-table relative overflow-x-auto">
          <div *ngIf="!transactionsPagination" class="flex justify-center py-6" [thkLibProcessing]="true"></div>
          <table *ngIf="transactionsPagination" class="w-full min-w-[850px] text-left">
            <thead class="heading">
              <tr>
                <!-- <th scope="col" class="w-28 pl-4">
                   <div class="col-name flex items-center gap-2">
                      <input type="checkbox" [checked]="isAllCheckBoxChecked()"
                         (change)="toggleSelectTransactions($event)">
                      {{getCheckedAmount()}} Selected
                   </div>
                </th> -->
                <th scope="col" class="w-28 pl-4">
                  <div class="col-name">Transaction ID</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Date</div>
                </th>
                <th scope="col" class="w-28">
                  <div class="col-name">Channel</div>
                </th>
                <th scope="col" class="w-28">
                  <div class="col-name">Sale Price</div>
                </th>
                <th scope="col" class="w-28">
                  <div class="col-name">Net Amount</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Instructor Share</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Status</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Action</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of transactionsPagination?.results; trackBy: transTrackBy" class="hover:cursor-pointer">
                <!-- <td>
                  <input (click)="checked($event, data)" type="checkbox"
                     name="" id="">
               </td> -->
                <td>
                  <span class="course-id">
                    <span class="text-blue-600 cursor-pointer">
                      {{ data.transaction_id }}
                    </span>
                  </span>
                </td>
                <td>
                  <span class="course-id">
                    <span class="">
                      {{ data.date_created | date:'d MMMM y' }}
                    </span>
                  </span>
                </td>
                <td class="max-w-[170px]">
                  <span class="">
                    {{ data.channel_type }}
                  </span>
                </td>
                <td>
                  {{data.sale_price | currency}}
                </td>
                <td>
                  {{data.net_amount | currency}}
                </td>
                <td>
                  <span class="course-price flex items-center">
                    <button class="btn btn-transparent p-1">
                      <svg-icon src="@assets/images/icons/down-circle.svg" tooltip="Co-Instructor Earning"
                        class="w-5 h-5">
                      </svg-icon>
                    </button>
                    {{ data.instructor_share| currency }}
                  </span>
                </td>
                <td>
                  {{data.payout_status}}
                </td>
                <td>
                  <button *ngIf="data.payout_status === 'requested'" (click)="openConfirmHoldingTransaction(data)" class="btn btn-danger btn-sm">Hold</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="transactionsPagination" class="p-4">
          <thk-pagination [sizeChangable]="true" (paginationChange)="goToTransPage($event)" [total]="transactionsPagination.count"></thk-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Rejection confirm modal -->
<common-modal #holdConfirmed (closeEvent)="closeHoldingConfirmationModal()" customClass="sm:w-full sm:max-w-[788px] rounded-none" title="Hold Confirmation">
  <!-- <ng-container header>
    <div class="p-6 flex flex-row gap-4 justify-between border-b border-neutral-100">
      <div class="form max-w-4xl">
        <h1 class="text-black font-semibold text-xl">Reason</h1>
      </div>
      <button (click)="closeHoldingConfirmationModal()" class="text-slate-500 hover:text-slate-800 w-4">
        <svg-icon src="@assets/images/icons/x-mark.svg" class="w-4"></svg-icon>
      </button>
    </div>
  </ng-container> -->
  <ng-container content>
    <div class="flex flex-col gap-4 px-6 py-5">
      <div class="flex flex-col gap-2">
        <h6 class="text-base font-semibold">Are you sure?</h6>
        <span>You want to hold the transaction? You cant undo this status.</span>
      </div>
      <div class="form">
        <form [formGroup]="holdForm" (ngSubmit)="holdTransaction()">
          <formly-form [form]="holdForm" [fields]="holdFormFields">
          </formly-form>
          <button class="hidden" type="submit" #holdFormBtn></button>
        </form>
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
      <div *ngIf="holdingTran" class="flex gap-4">
        <button (click)="closeHoldingConfirmationModal()" class="btn bg-neutral-100 border-none">
          <span class="text-neutral-900 text-sm font-semibold">Cancel</span>
        </button>
        <button (click)="holdFormBtn.click()" class="btn btn-danger" [thkLibProcessing]="processingHolding">
          Yes, Confirm
        </button>
      </div>
    </div>
  </ng-container>
</common-modal>

<!-- <common-modal #holdAllModal title="Hold Transaction">
  <div content class="py-4 px-6 gap-2 border-b border-b-neutral-100">
    <div class="flex gap-2 p-2 items-center justify-between">
      <h3 class="text-2xl font-bold">Do you really want to hold this transaction?</h3>
      <div class="h-25 w-25">
        <svg-icon src="@assets/images/icons/hold-transaction.svg" class="h-25 w-25"></svg-icon>
      </div>
    </div>
    <app-messages *ngIf="!requestAnalytics" [messages]="[{ message: 'You have selected ' + getCheckedAmount() + ' transactions.', type: 'warning' }]"></app-messages>
    <app-messages *ngIf="requestAnalytics" [messages]="[{ message: requestAnalytics.requested_transaction_count + ' transactions will be holded', type: 'warning' }]"></app-messages>
    <div class="mt-4">
      <div class="form">
        <form [formGroup]="holdForm" (ngSubmit)="holdAll()">
          <formly-form [form]="holdForm" [fields]="holdFormFields">
          </formly-form>
          <button #holdFormSubmitBtn type="submit" class="hidden"></button>
        </form>
      </div>
    </div>
  </div>
  <div footer class="flex justify-end gap-2 py-4 px-6">
    <button class="btn btn-white" (click)="holdAllModal.close()">Cancel</button>
    <button class="btn btn-danger" [thkLibProcessing]="holdingAll" (click)="holdFormSubmitBtn.click()">Confirm & Hold</button>
  </div>
</common-modal> -->
