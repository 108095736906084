import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Breadcrumb, BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { filter, tap } from 'rxjs';
import { Logger, isDefined } from 'thkee-common';

const log = new Logger('Breadcrump component');

@UntilDestroy()
@Component({
  selector: 'app-common-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  @Input() enableBackBtn = true;
  items: Breadcrumb[] = [];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private location: Location,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.breadcrumbs$
      .pipe(
        filter(isDefined),
        tap((data) => log.debug(data)),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.items = data;
        this.cdr.detectChanges();
      });
  }

  back() {
    this.location.back();
  }
}
