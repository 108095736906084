import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  Column,
  DrawerComponent,
  INSTRUCTOR_DRAWER_TAB,
  INSTRUCTOR_SETTINGS_DRAWER_TAB,
  ORDER_DETAILS_TABLE_COLUMN,
  OrderDetails,
  OrderDetailsItems,
  OrderDetailsResponse,
  OrderQueryParams,
  PRODUCT_DRAWER_TAB,
  TabItem,
} from '@shared';
import { TransactionsService } from '@shared/services';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { FileProps, LANGUAGES, PaginationService, RouterStoreService, ToastService, UtilsService } from 'thkee-common';
const pageSize: number = 10;

@Component({
  selector: 'app-order-details-overview',
  templateUrl: './order-details-overview.component.html',
  styleUrls: ['./order-details-overview.component.scss'],
})
export class OrderDetailsOverviewComponent implements OnInit, OnDestroy {
  @Input() id: any;
  @Input() isSidebarComponent: boolean = false;
  @Input() orderDetails!: OrderDetails;
  queryParams: OrderQueryParams = { page: 1, page_size: pageSize, order_id: '' };

  //Product TAB
  productTabLists: TabItem[] = PRODUCT_DRAWER_TAB;
  instructorTabLists: TabItem[] = INSTRUCTOR_DRAWER_TAB;
  instructorSettingsTabLists: TabItem[] = INSTRUCTOR_SETTINGS_DRAWER_TAB;

  // Create product form
  productForm = new FormGroup({});
  productFormModel: any = {};
  productFormFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'card block py-9 px-6 mb-4',
      fieldGroup: [
        {
          className: 'section-label block border-b-[2px] border-neutral-200 pb-3 mb-3',
          template: '<h5>General</h5>',
        },
        {
          key: 'title',
          type: 'input',
          wrappers: ['feedback-field'],
          defaultValue: '#SAP MM Material Management',
          props: {
            label: 'Title',
            minLength: 6,
            maxLength: 60,
            placeholder: 'e.g. Learn Blender in 60 minutes',
            disabled: true,
            tips: 'This is a tip message for course title',
          },
          validation: {
            messages: {
              minLength: "Title can't be lower than 6 characters.",
            },
          },
          expressions: {},
        },
        {
          key: 'subtitle',
          type: 'input',
          wrappers: ['feedback-field'],
          defaultValue: 'Introduction to SAP MM Material Management',
          props: {
            label: 'Subtitle',
            minLength: 6,
            maxLength: 60,
            placeholder: 'e.g. Introduction to SAP MM Material Management!',
            disabled: true,
            tips: 'This is a tip message for course sub title',
          },
          validation: {
            messages: {
              minLength: "This field can't be lower than 6 characters.",
            },
          },
          expressions: {},
        },
        {
          key: 'topics',
          type: 'select',
          defaultValue: ['uiux', 'frontend'],
          wrappers: ['feedback-field'],
          props: {
            label: 'Topics',
            placeholder: 'Choose topic',
            multiple: true,
            stayPlaceholder: true,
            disabled: true,
            tips: 'This is a tip message for course title',
            stylish: true,
            options: [
              { label: 'UI/UX', value: 'uiux' },
              { label: 'Frontend', value: 'frontend' },
              { label: 'Backend', value: 'backend' },
            ],
          },
          expressions: {},
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-6 border-b border-neutral-200 mb-3',
          fieldGroup: [
            {
              key: 'skill_level',
              type: 'select',
              defaultValue: 'Beginner',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Level',
                placeholder: 'Choose a level',
                stylish: true,
                options: [
                  { label: 'All', value: 'All' },
                  { label: 'Beginner', value: 'Beginner' },
                  { label: 'Intermediate', value: 'Intermediate' },
                  { label: 'Expert', value: 'Expert' },
                ],
                disabled: true,
                tips: 'This is a tip message for course title',
              },
              expressions: {},
            },
            {
              key: 'language',
              type: 'select',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Language',
                placeholder: 'Select language',
                stylish: true,
                options: _.sortBy(
                  _.entries(LANGUAGES).map(([key, value]) => ({ label: value, value: key })),
                  (lang) => lang.label
                ),
                disabled: true,
                tips: 'This is a tip message for course title',
              },
              expressions: {},
            },
          ],
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-6',
          fieldGroup: [
            {
              key: 'category',
              type: 'select',
              defaultValue: 'business',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Category',
                placeholder: 'Choose a category',
                stylish: true,
                disabled: true,
                tips: 'This is a tip message for course title',
                options: [
                  { label: 'Business', value: 'business' },
                  { label: 'Design', value: 'design' },
                  { label: 'Language', value: 'language' },
                ],
              },
              expressions: {},
            },
            {
              key: 'subcategory',
              type: 'select',
              defaultValue: 'graphic-design',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Sub category',
                placeholder: 'Choose sub-category',
                stylish: true,
                disabled: true,
                tips: 'This is a tip message for course title',
                options: [
                  { label: 'Graphic Design', value: 'graphic-design' },
                  { label: 'Web Design', value: 'web-design' },
                  { label: 'UI/UX Design', value: 'uiux-design' },
                ],
              },
              expressions: {},
            },
          ],
        },
      ],
    },

    {
      className: 'block border-t border-neutral-100 card mb-4 pt-6 px-6',
      key: 'image',
      type: 'file',
      // wrappers: ['feedback-field'],
      props: {
        wrapAppendClass: ['border-none'],
        label: 'Image Cover',
        placeholder: 'Upload your course image here. Supported files are .jpg, .jpeg, or .png.',
        preview: true,
        previewType: 'image',
        allowedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
        uploadType: 'dashboard',
        disabled: false,
        tips: 'This is a tip message for course title',
      } as FileProps,
      expressions: {},
    },
    {
      className: 'block border-t border-neutral-100 card mb-4 pt-6 px-6',
      key: 'promo_video',
      type: 'file',
      // wrappers: ['feedback-field'],
      props: {
        wrapAppendClass: ['border-none'],
        label: 'Promotional Video',
        placeholder: "Upload your course's promotional video here. Maximum of 200MB.",
        preview: true,
        previewType: 'video',
        hideLabel: false,
        allowedTypes: ['video/mp4', 'video/mkv', 'video/mov'],
        uploadType: 'dashboard',
        disabled: false,
        tips: 'This is a tip message for course title',
        onUpload: (upload, field) => {
          // log.debug('upload: ', upload);
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadStart({
          //       referenceId: `${courseId}-${field.key}`,
          //       fileName: upload.name,
          //       fileType: upload.type,
          //     })
          //   );
          // });
        },
        onProgress: (progress, field) => {
          // log.debug('progress: ', progress);
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadProgress({
          //       referenceId: `${courseId}-${field.key}`,
          //       bytesTotal: progress.bytesTotal,
          //       bytesUploaded: progress.bytesUploaded,
          //       progressPercent: progress.progressPercent,
          //     })
          //   );
          // });
        },
        onComplete: (field) => {
          // log.debug('complete');
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadComplete({
          //       referenceId: `${courseId}-${field.key}`,
          //     })
          //   );
          // });
        },
      } as FileProps,
      expressions: {
        // 'props.metadata': combineLatest([this.course$, this.userId$]).pipe(
        //   map(([course, userId]) => ({
        //     user: userId,
        //     course: course.id,
        //   })),
        //   distinctUntilChanged()
        // ),
        // 'props.previewUrl': this.course$.pipe(
        //   map((course) => {
        //     return course.promo_video_url;
        //   }),
        //   distinctUntilChanged()
        // ),
        // 'props.feedback': this.getFeedbackCount('promo_video'),
      },
    },
  ];

  // Rejection Reason form
  refundReasonForm = new FormGroup({});
  refundReasonFormModel = { reason: '', description: '' };
  refundReasonFormFields: FormlyFieldConfig[] = [
    {
      key: 'reason',
      type: 'select',
      defaultValue: ['Outdated Course Material'],
      props: {
        label: $localize`Reason`,
        placeholder: $localize`Choose topic`,
        multiple: false,
        stayPlaceholder: true,
        tips: $localize`Refund reason`,
        stylish: true,
        required: true,
        options: [
          { value: 'Outdated Course Material', label: $localize`Outdated Course Material` },
          { value: 'Instructor Performance Issues', label: $localize`Instructor Performance Issues` },
          { value: 'Course Too Difficult', label: $localize`Course Too Difficult` },
          { value: 'Course Too Easy', label: $localize`Course Too Easy` },
          { value: 'Course Not Needed', label: $localize`Course Not Needed` },
          { value: 'Technical Issues', label: $localize`Technical Issues` },
          { value: 'Unauthorized Transaction', label: $localize`Unauthorized Transaction` },
          { value: 'Better Price Found', label: $localize`Better Price Found` },
          { value: 'Incorrect Charge', label: $localize`Incorrect Charge` },
          { value: 'Duplicate Charge', label: $localize`Duplicate Charge` },
          { value: 'Exceptional Circumstances', label: $localize`Exceptional Circumstances` },
        ],
      },
      expressions: {},
    },
    {
      key: 'description',
      type: 'textarea',
      props: {
        label: $localize`Description`,
        labelClass: 'font-bold text-black text-lg pb-2.5',
        tips: $localize`Reason description`,
        placeholder: $localize`Enter a description...`,
        required: true,
        minHeight: '100px',
        maxHeight: '150px',
      },
    },
  ];

  private subscriptions = new Subscription();

  isDropdown: boolean[] = [];
  columns: Column[] = ORDER_DETAILS_TABLE_COLUMN;
  orderItems: OrderDetailsItems[] = [];
  isLoading: boolean = false;
  totalItems: number = 0;

  @ViewChild('productDrawer') private productDrawer!: DrawerComponent;
  @ViewChild('instructorDrawer') private instructorDrawer!: DrawerComponent;
  @ViewChild('refundConfirmed') refundConfirmed: any;
  refundItem!: OrderDetailsItems;
  private refundSubscriptions = new Subscription();

  constructor(
    private routerStore: RouterStoreService,
    private router: Router,
    private transactionsService: TransactionsService,
    private paginationService: PaginationService,
    private utilsService: UtilsService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getOrderItems();
  }

  // Checked function
  checkAllCheckBox(ev: any) {
    this.orderItems.forEach((x: any) => (x.checked = ev.target.checked));
  }
  isAllCheckBoxChecked() {
    this.checkedItemsCount();
    return this.orderItems.every((p: any) => p.checked);
  }
  checkedItemsCount() {
    let count = 0;
    this.orderItems.forEach((x: any) => {
      if (x.checked) count++;
    });
    return count;
  }

  // Column action
  colAction(event: any, data: any, type: any) {
    event.stopPropagation();
    if (type === 'transaction_id') {
      if (data.transaction_id) {
        this.router.navigate([`/ecommerce/sales/transaction/${data.transaction_id}`]);
      } else {
        this.toastService.error({
          message: $localize`Transaction ID not found!`,
        });
      }
    } else if (type === 'product_title') {
      this.productDrawer.open();
    } else {
      this.instructorDrawer.open();
    }
  }

  // Refund confirm modal
  refundConfirmedPopup(refundItem: OrderDetailsItems) {
    this.refundItem = refundItem;
    this.refundConfirmed.open();
  }
  refundConfirmedSubmit() {
    const payload = {
      reason: this.refundReasonFormModel.reason,
      order_line_item: this.refundItem.id,
      description: this.refundReasonFormModel.description,
      payment_mode: {
        mode: this.orderDetails.payment_gateway,
      },
    };
    this.refundSubscriptions.add(
      this.transactionsService.refundRequest(payload).subscribe({
        next: (res: any) => {
          this.refundConfirmed.close();
          this.toastService.message({
            message: $localize`Order refund success!`,
          });
          this.refundReasonForm.reset();
          this.getOrderItems();
        },
        error: (error) => {
          this.refundConfirmed.close();
          this.toastService.error({
            message: error.error.errors[0].detail,
          });
        },
      })
    );
  }

  private getOrderItems() {
    this.queryParams.order_id = this.id;
    this.isLoading = true;
    this.subscriptions.add(
      this.transactionsService.getOrderDetailsItems(this.queryParams, this.id).subscribe({
        next: (res: OrderDetailsResponse) => {
          res.results.forEach((items) => {
            items.checked = false;
          });
          this.orderItems = res.results;
          this.totalItems = res.count;
          this.paginationService.getPager(res.count, this.queryParams.page, this.queryParams.page_size);
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
        },
      })
    );
  }

  // Pagination
  paginat(page_number: number) {
    this.queryParams = Object.assign({}, this.queryParams);
    this.queryParams.page = page_number;
    this.getOrderItems();
  }

  // Dropdown
  dropdown(type: number) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  // Show/Hide column
  toggleColumn(col: Column): void {
    col.visible = !col.visible;
  }

  // Color tag
  getStatusTagColor(status: string): string {
    if (this.utilsService.isStatus(status)) {
      return this.utilsService.getStatusColor(status);
    }
    return 'green';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.refundSubscriptions.unsubscribe();
  }
}
