import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@shared';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { distinctUntilChanged } from 'rxjs';
import { selectCourse } from 'thkee-common';
import { HeaderActions } from '../components/course-header/course-header.component';
import { CourseIntendedComponent } from '../intended/intended.component';
@UntilDestroy()
@Component({
  selector: 'app-course-revision',
  templateUrl: './course-revision.component.html',
  styleUrls: ['./course-revision.component.scss'],
})
export class CourseRevisionComponent implements OnInit {
  constructor(private store: Store, private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.store
      .select(selectCourse)
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((course) => {
        this.breadcrumbGenerator(course.title);
      });
  }

  breadcrumb: { label: string; url: string }[] = [];
  breadcrumbGenerator(courseTitle: string) {
    this.breadcrumb = [
      {
        label: 'Courses',
        url: '/courses',
      },
      {
        label: 'To Review',
        url: '/courses/review',
      },
      {
        label: `${courseTitle}`,
        url: '',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(this.breadcrumb);
  }

  // Stepper
  currentStep = 0;
  steps = [{ title: 'Intended Learner' }, { title: 'Detail' }, { title: 'Curriculum' }, { title: 'Pricing' }];

  setStep(step: number) {
    this.currentStep = step;
  }

  nextStep() {
    if (this.currentStep < this.steps.length) {
      this.currentStep++;
    }
  }

  prevStep() {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  submit() {
    alert('Form submitted');
  }

  // Header action
  @ViewChild('intend') private intend!: CourseIntendedComponent;
  actionEvent(event: HeaderActions) {
    this.intend.actionEvent(event);
  }
}
