import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_COUNTRY_LIST, PricingCountryList } from '../../../models';
import { encodeURL } from '../../../utils';
import { HttpService } from '../../http';
import { Logger } from '../../logger';

const log = new Logger('PricingCountryAPIService');

@Injectable({
    providedIn: 'root',
})
export class PricingCountryAPIService {
    constructor(private readonly http: HttpService) { }

    list(params = {} as object): Observable<any> {
        return this.http.get<PricingCountryList[]>(encodeURL(API_COUNTRY_LIST, params));
    }
}