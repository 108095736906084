import { AsyncPipe, DOCUMENT, NgClass, NgIf } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterLink, RouterLinkActive, RouterModule } from '@angular/router';
import { environment } from '@env/environment';
import { SharedModule } from '@shared';
import { UserService } from '@shared/services';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ThkeeCommonModule, ToastService } from 'thkee-common';

@Component({
  selector: 'app-manage-user',
  standalone: true,
  imports: [
    RouterModule,
    RouterLink,
    RouterLinkActive,
    AngularSvgIconModule,
    ReactiveFormsModule,
    NgIf,
    AsyncPipe,
    ThkeeCommonModule,
    SharedModule,
    NgClass,
  ],
  templateUrl: './manage-user.component.html',
  styleUrl: './manage-user.component.scss',
})
export class ManageUserComponent implements OnInit {
  loginForm!: FormGroup;
  isSubmitted: boolean = false;
  loginAsURL: string = '';

  constructor(
    private userService: UserService,
    private toastService: ToastService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });

    const isCredential = sessionStorage.getItem('login_as_admin_url');
    if (isCredential) {
      this.loginAsURL = isCredential;
    }
  }

  loginAsForm() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    this.isSubmitted = true;
    console.log(this.loginForm.value);
    this.userService.adminAsUserLogin({ user_email: this.loginForm.value.email }).subscribe({
      next: (res) => {
        this.isSubmitted = false;
        const targetUrl = new URL(environment.authUrl);
        targetUrl.searchParams.set('refresh', res.refresh);
        targetUrl.searchParams.set('token', res.access);
        // if (isPlatformBrowser(this.platformId)) {
        //   window.open(targetUrl.toString(), '_blank', 'width=800,height=600,scrollbars=yes,resizable=yes');
        // }
        this.copyToClipboard(targetUrl.toString());
        this.loginForm.reset();

        sessionStorage.setItem('login_as_admin_url', targetUrl.toString());
        this.loginAsURL = targetUrl.toString();
      },
      error: (err) => {
        this.isSubmitted = false;
        this.toastService.error({
          message: 'Sign in failed! Please try again.',
        });
      },
    });
  }

  copyToClipboard(url: string) {
    navigator.clipboard.writeText(url).then(() => {
      this.toastService.message({
        message: 'URL copied to clipboard. Please open it in a private window.',
      });
    });
  }

  get email() {
    return this.loginForm.get('email');
  }
}
