<div class="course-overview p-4 pt-0">
  <div class="flex flex-col gap-4 mb-4">
    <div *ngIf="!requestsSummary" class="flex justify-center py-6"
      [thkLibProcessing]="true"></div>
    <ng-container *ngIf="requestsSummary">
      <div class="flex flex-row gap-4">
        <app-metric-card *ngFor="let item of summaryItems" [variant]="item.id === query.payout_method ? 'primary' : 'default'" class="w-full"
          [data]="item" (click)="filterByPayoutMethod(item.id)">
        </app-metric-card>
        <!-- <app-metric-card class="w-full"
          [data]="{ title: 'Amount for paypal ', value: (requestsSummary.paypal_amount| currency:requestsSummary.currency_symbol) || ''}">
        </app-metric-card>
        <app-metric-card class="w-full"
          [data]="{ title: 'Amount for payoneer', value: (requestsSummary.payoneer_amount | currency:requestsSummary.currency_symbol) || ''}">
        </app-metric-card>
        <app-metric-card class="w-full"
          [data]="{ title: 'Amount for bank', value: (requestsSummary.bank_amount | currency:requestsSummary.currency_symbol) || ''}">
        </app-metric-card> -->
      </div>
    </ng-container>
  </div>
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="actions flex gap-4">
      <!-- Filter -->
      <div class="relative">
        <button (click)="dropdown('filter')" class="btn-neutral">
          <svg-icon src="@assets/images/icons/filter.svg"
            class="btn-icon"></svg-icon>Filter
        </button>
        <div [ngClass]="isDropdown === 'filter' ? '' : 'hidden'"
          class="bg-white shadow-lg rounded px-4 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
          <div class="form">
            <formly-form #formlyFilter [form]="filterForm"
              [fields]="filterFormFields">
            </formly-form>
          </div>
          <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
            <button (click)="resetFilterRequests()"
              class="btn btn-outline-dark border-neutral-600">Reset</button>
          </div>
        </div>
      </div>
      <app-combination-single-input [keys]="searchOptionMetadata"
        [model]="searchValue"
        (valueChanges)="search($event)"></app-combination-single-input>
    </div>
  </div>

  <div class="courses-list">
    <div class="card rounded-lg border border-neutral-100">
      <div class="card-subheader flex items-center justify-between px-6 py-5">
        <div class="flex items-center gap-1">
          <h6 class="text-base"> Payouts Requests</h6>
          <div class="text-slate-400"
            class="tag tag-sm">{{requestPagination?.count}} Payee</div>
        </div>
        <div class="actions flex flex-row gap-4">
          <button *ngIf="!disableAllButton && selectedRequests.length"
            class="btn btn-danger" (click)="openConfirmation(deactivateManyModal, selectedRequests)">Deactivate
            ({{selectedRequests.length}})</button>
          <button *ngIf="!disableAllButton && selectedRequests.length"
            class="btn btn-teal" (click)="openConfirmation(releaseManyModal, selectedRequests)">Release
            ({{selectedRequests.length}})</button>
          <div class="select2">
            <div class="relative">
              <button class="btn btn-white !pr-2"
                (click)="dropdown('batchOption')">
                Batch Option
                <svg-icon src="@assets/images/icons/chevron-down-solid.svg"
                  class="btn-icon"></svg-icon>
              </button>
              <ul [ngClass]="isDropdown === 'batchOption' ? '' : 'hidden'"
                tabindex="-1" role="listbox" class="select-items min-w-[150px]">
                <li (click)="openConfirmationAllReadyRequests(releaseAllModal)" id="listbox-option-0"
                  role="option" class="item">Release All</li>
                <li (click)="openConfirmationAllReadyRequests(deactivateAllModal)" id="listbox-option-0"
                  role="option" class="item">Deactivate All</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="courses-list">
          <!-- Table -->
          <div class="datagrid-table relative overflow-x-auto">
            <table class="w-full text-left" thkNestedCheckbox #payoutCheckboxes (selectedChange)="handleSelectedPayoutsChange($event)">
              <thead class="heading">
                <tr>
                  <th scope="col" class="w-28 pl-4">
                    <div class="col-name flex items-center gap-2">
                      <input type="checkbox" thkNestedCheckboxChild [leader]="true">
                      {{selectedRequests.length}} Selected
                    </div>
                  </th>
                  <th scope="col" class>
                    <div class="col-name">ID</div>
                  </th>
                  <th scope="col" class>
                    <div class="col-name">Instructor</div>
                  </th>
                  <th scope="col" class="w-28">
                    <div class="col-name">Transactions</div>
                  </th>
                  <th scope="col" class>
                    <div class="col-name">Payout Method</div>
                  </th>
                  <th scope="col" class>
                    <div class="col-name">Payout Type</div>
                  </th>
                  <th scope="col" class>
                    <div class="col-name">Created on</div>
                  </th>
                  <th scope="col" class>
                    <div class="col-name">Total Amount</div>
                  </th>
                  <th scope="col" class>
                    <div class="col-name">Status</div>
                  </th>
                  <th scope="col" class>
                    <div class="col-name">Actions</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let data of requestPagination?.results; let i = index"
                  class="hover:cursor-pointer">
                  <td>
                    <input thkNestedCheckboxChild type="checkbox" [data]="data">
                  </td>
                  <td>
                    <span (click)="openTransactions(data)"
                      class="course-id">
                      <span class="text-blue-600 cursor-pointer">
                        {{ data.id }}
                      </span>
                    </span>
                  </td>
                  <td>
                    <span
                      (click)="openRequestDetail(data, requestDetailDrawer)"
                      class="course-id">
                      <span class="text-blue-600 cursor-pointer">
                        {{ data.instructor.fullname }}
                      </span>
                    </span>
                  </td>
                  <td>
                    {{data.no_of_transactions}}
                  </td>
                  <td>
                    {{data.payment_method}}
                  </td>
                  <td class="max-w-[170px]">
                    <span class>
                      {{ data.payout_type }}
                    </span>
                  </td>
                  <td>
                    <div class="course-id">
                      {{ data.date_created| date:'d MMMM y' }}
                      <div class="text-slate-400">{{ data.date_created|
                        date:'h:mm:ss a' }}</div>
                    </div>
                  </td>
                  <td>
                    {{data.requested_amount | currency:data.currency_symbol}}
                  </td>
                  <td>
                    {{data.status}}
                  </td>
                  <td>
                    <div class="flex gap-2">
                      <!-- <ng-container *ngIf="data.status === 'ready'">
                        <button class="btn btn-sm btn-secondary"
                          (click)="openConfirmation(deactivateManyModal, [data])">
                          Deactivate
                        </button>
                        <button (click)="openConfirmation(releaseManyModal, [data])"
                          class="btn btn-sm btn-outline-teal">
                          Release
                        </button>
                      </ng-container> -->
                      <div class="select2">
                        <div class="relative">
                          <ng-container *ngIf="data.status !== 'paid'">
                            <button
                              (click)="toggleDropdownTranId(data.id)"
                              [disabled]="data.status === 'processing'"
                              class="btn-neutral px-3">
                              <svg-icon
                                src="@assets/images/icons/dots-vertical.svg"
                                class="btn-icon"></svg-icon>
                            </button>
                            <ul
                              [ngClass]="{ hidden: openDropdownId !== data.id, 'top-0 translate-y-[-100%]': i > 0 }"
                              tabindex="-1" role="listbox"
                              class="select-items min-w-[150px] left-0 translate-x-[-100%]">
                              <li *ngIf="data.status === 'failed' && data.payment_method === 'paypal'" (click)="openRetryReleaseModal(data)"
                                id="listbox-option-0"
                                role="option" class="item">Retry</li>
                              <li *ngIf="data.status === 'ready' || data.status === 'failed' || data.status === 'deactivated'" (click)="markAsPaidComp.show(data)"
                                id="listbox-option-0"
                                role="option" class="item">Mark as Paid</li>
                              <li *ngIf="data.status === 'ready' && data.payment_method === 'bank_account'" (click)="openMarkAsFailedModal(data)"
                                id="listbox-option-0"
                                role="option" class="item">Fail</li>
                              <li *ngIf="data.status === 'requested' || data.status === 'ready'" (click)="openConfirmation(deactivateManyModal, [data])"
                                id="listbox-option-0"
                                role="option" class="item">Deactivate</li>
                              <li *ngIf="data.status === 'ready' && data?.payment_method === 'paypal'" (click)="openConfirmation(releaseManyModal, [data])"
                                id="listbox-option-0"
                                role="option" class="item">Release</li>
                            </ul>
                          </ng-container>
                          <ng-container *ngIf="data.status === 'paid'">
                            <a *ngIf="data.receipt" [href]="data.receipt" target="_blank">
                              <button class="btn px-2">
                                <svg-icon src="@assets/images/icons/document-text.svg" class="text-slate-500"></svg-icon>
                                Receipt</button>
                            </a>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div *ngIf="requestPagination" class="px-4 py-2">
            <thk-pagination [size]="10" [total]="requestPagination.count"
              (paginationChange)="paginate($event)"></thk-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #notFound>
  <div *ngIf="requestPagination" style="height: calc(100% - 108px);"
    class="flex flex-col items-center justify-center">
    <div class="max-w-md text-center">
      <h2 class="text-5xl text-[#9094FF] font-black">Oops!</h2>
      <h5 class="text-base text-neutral-600 font-normal">There is no payout request currently, please check later
      </h5>
    </div>
  </div>
</ng-template>

<common-modal #markAsFailedModal title="Mark as Failed">
  <div content class="flex flex-col py-4 px-6 gap-4">
    <div class="flex flex-col gap-2">
      <span class="text-slate-500">Please provide a reason for marking this failed.</span>
    </div>
    <div class="form">
      <form [formGroup]="markAsFailedForm" (ngSubmit)="markAsFail()">
        <formly-form [form]="markAsFailedForm" [fields]="markAsFailedFormFields">
        </formly-form>
        <button class="hidden" type="submit" #markAsFailedRequestBtn></button>
      </form>
    </div>
  </div>
  <div footer class="flex justify-end gap-2 p-4">
    <button class="btn btn-secondary"
      (click)="markAsFailedModal.close()">Cancel</button>
    <button class="btn btn-danger" [thkLibProcessing]="requestingChange"
      (click)="markAsFailedRequestBtn.click()">Yes, Confirm</button>
  </div>
</common-modal>

<common-modal #retryReleaseModal title="Refresh & Retry Payout Method" (closeEvent)="processingRequest = undefined">
  <div content class="flex flex-col py-4 gap-4">
    <div *ngIf="!retryPayoutInfor" class="flex justify-center w-full p-2" [thkLibProcessing]="true"></div>
    <ng-container *ngIf="retryPayoutInfor">
      <div *ngIf="retryPayoutInfor.current_payout_method !== retryPayoutInfor.new_payment_method" class="text-center p-2 bg-[#EEF2FF] text-[#4338CA]">
        Payment method is changed!
      </div>
      <div class="flex flex-col gap-5">
        <div class="px-4">
          <div class="flex flex-col p-4 gap-2 bg-slate-50">
            <div class="flex">
              <div class="w-[50%] pr-2 font-bold">Instructor:</div>
              <div class="w-[50%]">{{retryPayoutInfor.instructor.fullname}}</div>
            </div>
            <div class="flex">
              <div class="w-[50%] pr-2 font-bold">Current  Method:</div>
              <div class="w-[50%]">{{retryPayoutInfor.current_payout_method}}</div>
            </div>
            <div class="flex">
              <div class="w-[50%] pr-2 font-bold">Total Amount:</div>
              <div class="w-[50%]">{{retryPayoutInfor.requested_amount | currency:retryPayoutInfor.currency_symbol}}</div>
            </div>
            <div class="flex">
              <div class="w-[50%] pr-2 font-bold">Status:</div>
              <div class="w-[50%]">{{retryPayoutInfor.status}}</div>
            </div>
          </div>
        </div>
        <div *ngIf="retryPayoutInfor.current_payout_method !== retryPayoutInfor.new_payment_method" class="flex flex-col gap-4 px-4">
          <div class="flex gap-2">
            <span class="font-bold">New Payment Method:</span><span>{{retryPayoutInfor.new_payment_method}}</span>
          </div>
          <div>
            This instructors payment method is changed from {{retryPayoutInfor.current_payout_method}} to <span class="font-bold">{{retryPayoutInfor.new_payment_method}}</span>.
          </div>
          <div>
            Do you want to go for <span class="font-bold">{{retryPayoutInfor.new_payment_method}}</span>?
          </div>
        </div>
        <div *ngIf="retryPayoutInfor.current_payout_method === retryPayoutInfor.new_payment_method" class="px-4">
          Are you sure to retry this payout?
        </div>
      </div>
    </ng-container>
  </div>
  <div footer class="flex justify-end gap-2 p-4">
    <button class="btn btn-secondary"
      (click)="releaseAllModal.close()">Cancel</button>
    <button class="btn btn-danger" [thkLibProcessing]="requestingChange"
      (click)="retryRelease()">Update & Confirm</button>
  </div>
</common-modal>
<common-modal #releaseAllModal title="Confirm Release">
  <div content class="flex flex-col py-4 px-6 gap-4">
    <div class="flex flex-col gap-2">
      <h6 class="text-base">Are you sure you want to proceed?</h6>
      <div *ngIf="!totalReadyRequest" class="flex justify-center" [thkLibProcessing]="true"></div>
      <span *ngIf="totalReadyRequest" class="text-slate-500">This action will release payments for {{totalReadyRequest}} payout requests.</span>
    </div>
    <div class="flex flex-col gap-2">
      <div class="text-sm">Please type "<span class="text-bold">RELEASE</span>" to confirm this process</div>
      <div><input type="text" class="input-field w-full" ngModel
          #releaseConfirmInput></div>
    </div>
  </div>
  <div *ngIf="totalReadyRequest" footer class="flex justify-end gap-2 p-4">
    <button class="btn btn-secondary"
      (click)="releaseAllModal.close()">Cancel</button>
    <button [disabled]="releaseConfirmInput.value !== 'RELEASE'"
      class="btn btn-teal" [thkLibProcessing]="requestingChange"
      (click)="releaseMany(releaseAllModal)">Yes, Confirm</button>
  </div>
</common-modal>

<common-modal #releaseManyModal title="Confirm Release">
  <div content class="flex flex-col py-4 px-6 gap-4">
    <div class="flex flex-col gap-2">
      <h6 class="text-base">Are you sure you want to proceed?</h6>
      <span class="text-slate-500">You are about to release
        {{planToProcessingPayouts?.length}} Instructors payout requests.</span>
    </div>
    <div class="flex flex-col gap-2">
      <ul class="list-disc list-inside text-slate-500">
        <li *ngFor="let item of planToProcessingPayouts">{{item.instructor.fullname}}</li>
      </ul>
    </div>
  </div>
  <div footer class="flex justify-end gap-2 p-4">
    <button class="btn btn-secondary"
      (click)="releaseManyModal.close()">Cancel</button>
    <button class="btn btn-teal" [thkLibProcessing]="requestingChange"
      (click)="releaseMany(releaseManyModal)">Yes, Confirm</button>
  </div>
</common-modal>

<common-modal #deactivateManyModal title="Confirm Deactivate">
  <div content class="flex flex-col py-4 px-6 gap-4">
    <div class="flex flex-col gap-2">
      <h6 class="text-base">Are you sure you want to proceed?</h6>
      <span class="text-slate-500">You are about to deactivate
        {{planToProcessingPayouts?.length}} Instructors pay requests.</span>
    </div>
    <div class="flex flex-col gap-2">
      <ul class="list-disc list-inside text-slate-500">
        <li *ngFor="let item of planToProcessingPayouts">{{item.instructor.fullname}}</li>
      </ul>
    </div>
  </div>
  <div footer class="flex justify-end gap-2 py-4 px-6">
    <button class="btn btn-secondary"
      (click)="deactivateManyModal.close()">Cancel</button>
    <button class="btn btn-danger" [thkLibProcessing]="requestingChange"
      (click)="deactivateManyRequests(deactivateManyModal)">Yes, Deactivate</button>
  </div>
</common-modal>

<common-modal #deactivateAllModal title="Confirm Deactivate">
  <div content class="flex flex-col py-4 px-6 gap-4">
    <div class="flex flex-col gap-2">
      <h6 class="text-base">Are you sure you want to proceed?</h6>
      <div *ngIf="!totalReadyRequest" class="flex justify-center" [thkLibProcessing]="true"></div>
      <span *ngIf="totalReadyRequest" class="text-slate-500">You are about to deactivate
        {{totalReadyRequest}} Instructors pay requests.</span>
    </div>
    <div class="flex flex-col gap-2">
      <div class="text-sm">Please type "<span
          class="text-bold">DEACTIVATE</span>" to confirm this process</div>
      <div><input type="text" class="input-field w-full" ngModel
          #deactivateConfirmInput></div>
    </div>
  </div>
  <div *ngIf="totalReadyRequest" footer class="flex justify-end gap-2 py-4 px-6">
    <button class="btn btn-secondary"
      (click)="deactivateAllModal.close()">Cancel</button>
    <button [disabled]="'DEACTIVATE' !== deactivateConfirmInput.value"
      class="btn btn-danger" [thkLibProcessing]="requestingChange"
      (click)="deactivateManyRequests(deactivateAllModal)">Yes, Deactivate</button>
  </div>
</common-modal>

<!-- Transaction details drawer -->
<app-drawer #transactionsDrawer (closeDrawer)="processingRequest = undefined"
  [closable]="true">
  <ng-container header>
    <!-- Header -->
    <div *ngIf="processingRequest"
      class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
      <h1
        class="text-black font-semibold text-xl">{{processingRequest.instructor.fullname}}
        Request</h1>
    </div>
  </ng-container>
  <ng-container content>
    <app-ready-request-transactions *ngIf="processingRequest && transactionsDrawer.isOpened"
      [request]="processingRequest"></app-ready-request-transactions>
  </ng-container>
</app-drawer>

<app-drawer #requestDetailDrawer
  [closable]="true">
  <ng-container header>
    <!-- Header -->
    <div
      class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
      <h1 *ngIf="processingRequest"
        class="text-black font-semibold text-xl">{{processingRequest.instructor.fullname}}
        Request</h1>
    </div>
  </ng-container>
  <div content>
    <div *ngIf="!requestDetail" class="py-10 flex justify-center" [thkLibProcessing]="true"></div>
    <app-ready-request-detail *ngIf="requestDetail" [request]="requestDetail"></app-ready-request-detail>
  </div>

  <div *ngIf="processingRequest?.status === 'ready'" footer class="mt-auto flex justify-end p-4 bg-slate-50 gap-2">
    <button *ngIf="processingRequest?.payment_method === 'bank_account'" class="btn btn-danger" (click)="openMarkAsFailedModal(processingRequest!)">Fail</button>
    <button *ngIf="processingRequest?.payment_method === 'paypal'" class="btn btn-teal" (click)="openConfirmation(releaseManyModal, [processingRequest!])">Release</button>
  </div>
</app-drawer>
<app-mark-payout-as-paid #markAsPaidComp (response)="handlePayoutPaid($event)"></app-mark-payout-as-paid>
