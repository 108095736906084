import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import {
  AdminFailedPayoutsQuery,
  AdminHoldTransactionQuery,
  AdminHoldTransactionsSummary,
  AdminInactivePayoutsQuery,
  AdminInstructorBalance,
  AdminInstructorPayoutsQuery,
  AdminInstructorWithdrawActivitiesQuery,
  AdminInstructorWithdrawActivity,
  AdminManyPayoutRequestSummary,
  AdminPaidPayoutsQuery,
  AdminPayoutInstructorBalancesQuery,
  AdminPayoutInstructorBalanceTransactionsQuery,
  AdminPayoutPaidRequestsSummary,
  AdminPayoutReadyRequestsSummary,
  AdminPayoutRequest,
  AdminPayoutRequestDetailSummary,
  AdminPayoutRequestHistoryEvent,
  AdminPayoutRequestMetadata,
  AdminPayoutRequestsQuery,
  AdminPayoutRequestsSummary,
  AdminPayoutRequestTransactionQuery,
  AdminPayoutRetryInformation,
  AdminPayoutTransaction,
  AdminPayoutTransactionDetail,
  AdminReadyRequestDetail,
  API_ADMIN_PAYOUT_ALL_REQUESTS_SUMMARY,
  API_ADMIN_PAYOUT_APPROVE,
  API_ADMIN_PAYOUT_APPROVE_ALL,
  API_ADMIN_PAYOUT_DEACTIVATE,
  API_ADMIN_PAYOUT_DEACTIVATE_ALL,
  API_ADMIN_PAYOUT_FAILED_REQUESTS,
  API_ADMIN_PAYOUT_HOLD_ALL_TRANSACTIONS,
  API_ADMIN_PAYOUT_HOLD_MANY_TRANSACTIONS,
  API_ADMIN_PAYOUT_HOLD_TRANSACTIONS_SUMMARY,
  API_ADMIN_PAYOUT_INACTIVE,
  API_ADMIN_PAYOUT_INACTIVE_ACTIVATE,
  API_ADMIN_PAYOUT_INACTIVE_ACTIVATE_PAYOUT,
  API_ADMIN_PAYOUT_INACTIVE_DETAILS_FOR_PAID,
  API_ADMIN_PAYOUT_INACTIVE_TRANSACTIONS,
  API_ADMIN_PAYOUT_INSTRUCTOR_BALANCE_PAYOUTS,
  API_ADMIN_PAYOUT_INSTRUCTOR_BALANCE_TRANSACTIONS,
  API_ADMIN_PAYOUT_INSTRUCTOR_BALANCES,
  API_ADMIN_PAYOUT_INSTRUCTOR_WITHDRAWS,
  API_ADMIN_PAYOUT_LIST_HOLD_TRANSACTIONS,
  API_ADMIN_PAYOUT_MANY_REQUESTS_SUMMARY,
  API_ADMIN_PAYOUT_MARK_AS_PAID,
  API_ADMIN_PAYOUT_PAID_REQUESTS,
  API_ADMIN_PAYOUT_PAID_REQUESTS_SUMMARY,
  API_ADMIN_PAYOUT_READY_REQUEST_DETAILS,
  API_ADMIN_PAYOUT_READY_REQUESTS,
  API_ADMIN_PAYOUT_READY_REQUESTS_CONFIRM_HOLD,
  API_ADMIN_PAYOUT_READY_REQUESTS_DEACTIVATE,
  API_ADMIN_PAYOUT_READY_REQUESTS_DEACTIVATE_ALL,
  API_ADMIN_PAYOUT_READY_REQUESTS_MARK_AS_FAILED,
  API_ADMIN_PAYOUT_READY_REQUESTS_RELEASE,
  API_ADMIN_PAYOUT_READY_REQUESTS_RELEASE_ALL,
  API_ADMIN_PAYOUT_READY_REQUESTS_SUMMARY,
  API_ADMIN_PAYOUT_READY_REQUESTS_TRANSACTIONS,
  API_ADMIN_PAYOUT_REQUEST_DETAIL_SUMMARY,
  API_ADMIN_PAYOUT_REQUEST_HISTORY,
  API_ADMIN_PAYOUT_REQUEST_TRANSACTION_DETAIL,
  API_ADMIN_PAYOUT_REQUEST_TRANSACTIONS,
  API_ADMIN_PAYOUT_REQUESTS,
  API_ADMIN_PAYOUT_REQUESTS_SUMMARY,
  API_ADMIN_PAYOUT_RETRY_REQUEST_DETAILS,
  API_ADMIN_PAYOUT_RETRY_REQUESTS,
  API_ADMIN_PAYOUT_TRANSACTION_APPROVE,
  API_ADMIN_PAYOUT_TRANSACTION_APPROVE_ALL,
  API_ADMIN_PAYOUT_UNHOLD_REQUEST_TRANSACTION,
  API_ADMIN_PAYOUT_UNHOLD_TRANSACTION,
  API_GET_ADMIN_FAILED_PAYOUT_SUMMARY,
  API_GET_ADMIN_INACTIVATION_PAYOUT_SUMMARY,
  encodeURL,
  FailedPayoutSummary,
  HttpService,
  InactivePayoutSummary,
  Pagination,
} from 'thkee-common';

@Injectable({
  providedIn: 'root',
})
export class PayoutService {
  constructor(private http: HttpService) {}

  getInactivePayouts(query?: AdminInactivePayoutsQuery) {
    return this.http.get<Pagination<AdminPayoutRequest>>(encodeURL(API_ADMIN_PAYOUT_INACTIVE, query));
  }

  getInactivePayout(requestId: number) {
    return this.getInactivePayouts({ page: 1, page_size: 1, search: ['id', requestId].join(':') }).pipe(
      map((pagination) => pagination.results[0])
    );
  }

  getInactivePayoutRequestTransactions(
    payoutRequestId: number,
    query?: { page?: number; page_size?: number; name?: string }
  ) {
    return this.http.get<Pagination<AdminPayoutTransaction>>(
      encodeURL(API_ADMIN_PAYOUT_INACTIVE_TRANSACTIONS(payoutRequestId), query)
    );
  }

  getPayoutRequests(query?: AdminPayoutRequestsQuery) {
    return this.http.get<Pagination<AdminPayoutRequest>>(encodeURL(API_ADMIN_PAYOUT_REQUESTS, query));
  }

  getSelectedPayoutRequestsSummary(payout_request_ids?: number[]) {
    return this.http.get<AdminManyPayoutRequestSummary>(
      payout_request_ids
        ? encodeURL(API_ADMIN_PAYOUT_MANY_REQUESTS_SUMMARY, { payout_request_ids })
        : API_ADMIN_PAYOUT_ALL_REQUESTS_SUMMARY
    );
  }

  /**
   * HACK:: for showing some request information like instructor
   */
  getPayoutRequest(requestId: number) {
    return this.getPayoutRequestTransactions(requestId, { page_size: 1 }).pipe(map((data) => data.results[0]));
  }

  getPayoutRequestTransactions(payoutRequestId: number, query?: AdminPayoutRequestTransactionQuery) {
    return this.http.get<Pagination<AdminPayoutTransaction>>(
      encodeURL(API_ADMIN_PAYOUT_REQUEST_TRANSACTIONS(payoutRequestId), query)
    );
  }

  getTransactionDetail(transactionId: number) {
    return this.http.get<AdminPayoutTransactionDetail>(API_ADMIN_PAYOUT_REQUEST_TRANSACTION_DETAIL(transactionId));
  }

  getHoldTransactions(query?: AdminHoldTransactionQuery) {
    return this.http.get<Pagination<AdminPayoutTransaction>>(encodeURL(API_ADMIN_PAYOUT_LIST_HOLD_TRANSACTIONS, query));
  }

  getHoldTransactionsSummary() {
    return this.http.get<AdminHoldTransactionsSummary>(API_ADMIN_PAYOUT_HOLD_TRANSACTIONS_SUMMARY).pipe(
      catchError(() =>
        of({
          currency_symbol: '$',
          total_amount: 330,
          total_hold_transactions: 2,
        } as AdminHoldTransactionsSummary)
      )
    );
  }

  holdTransactions(requestId: number, data: { transaction_ids?: number[]; reason: string; description: string }) {
    return this.http.post(
      data.transaction_ids
        ? API_ADMIN_PAYOUT_HOLD_MANY_TRANSACTIONS(requestId)
        : API_ADMIN_PAYOUT_HOLD_ALL_TRANSACTIONS(requestId),
      data
    );
  }

  unholdRequestTransaction(reid: number, transactionId: number) {
    return this.http.post<AdminPayoutTransactionDetail>(
      API_ADMIN_PAYOUT_UNHOLD_REQUEST_TRANSACTION(reid, transactionId),
      {}
    );
  }

  // unapproveTransaction(transactionId: number) {
  //   // FIXME: need a api to revert the transaction from approved to requested
  //   return this.http.post<AdminPayoutTransactionDetail>(API_ADMIN_PAYOUT_UNHOLD_REQUEST_TRANSACTION(reqId, transactionId), {});
  // }

  getPayoutRequestsSummary() {
    return this.http.get<AdminPayoutRequestsSummary>(API_ADMIN_PAYOUT_REQUESTS_SUMMARY).pipe(
      catchError(() =>
        of({
          total_sales: 0,
          bank_balance: 0,
          paypal_balance: 0,
          payoneer_balance: 0,
          total_amount_to_pay: 270,
          currency_symbol: '$',
          total_transactions: 1,
          total_payee: 1,
          bank_payment: 0,
          paypal_payment: 270,
          payoneer_payment: 0,
          created_date: '2024-07-03T10:03:42.087501Z',
          approval_end_date: '2024-07-05T10:03:42.087501Z',
        } as AdminPayoutRequestsSummary)
      )
    );
  }

  getPayoutRequestDetailSummary(requestId: number) {
    return this.http.get<AdminPayoutRequestDetailSummary>(API_ADMIN_PAYOUT_REQUEST_DETAIL_SUMMARY(requestId)).pipe(
      catchError(() =>
        of({
          total_requested_amount: 200,
          currency_symbol: '$',
          payout_method: 'bank_account',
          total_transactions: 2,
          created_date: '2024-07-03T10:10:42.688741Z',
          approved_transaction_count: 0,
          hold_transaction_count: 1,
          period_start: '2024-06-25',
          period_end: '2024-06-25',
        } as AdminPayoutRequestDetailSummary)
      )
    );
  }

  getInactivePayoutRequestMetadata(requestId: number) {
    return this.http.get<AdminPayoutRequestMetadata>(API_ADMIN_PAYOUT_INACTIVE_ACTIVATE(requestId)).pipe(
      catchError(() =>
        of({
          instructor: {
            id: 44,
            fullname: 'Brijesh Test',
            instructor_type: 'premium',
          },
          period: 'June 2024',
          currency_symbol: '$',
          payment_method: 'bank_account',
          requested_amount: 100,
          payout_feature: false,
          account_status: true,
        } as AdminPayoutRequestMetadata)
      )
    );
  }

  getPayoutRequestMetadata(requestId: number) {
    return this.http.get<AdminPayoutRequestMetadata>(API_ADMIN_PAYOUT_INACTIVE_DETAILS_FOR_PAID(requestId)).pipe(
      catchError(() =>
        of({
          instructor: {
            id: 44,
            fullname: 'Brijesh Test',
            instructor_type: 'premium',
          },
          period: 'June 2024',
          currency_symbol: '$',
          payment_method: 'bank_account',
          requested_amount: 100,
          payout_feature: false,
          account_status: true,
        } as AdminPayoutRequestMetadata)
      )
    );
  }

  activatePayoutRequest(requestId: number, data: { reason: string }) {
    return this.http
      .post<AdminPayoutRequestMetadata>(API_ADMIN_PAYOUT_INACTIVE_ACTIVATE_PAYOUT(requestId), data)
      .pipe(catchError(() => of(true)));
  }

  markPayoutRequestAsPaid(requestId: number, data: { reason: string; receipt: string; reference_id: string }) {
    return this.http.post<AdminPayoutRequestMetadata>(API_ADMIN_PAYOUT_MARK_AS_PAID(requestId), data);
  }

  approveRequests(payout_request_ids?: number[]) {
    return this.http.post(payout_request_ids ? API_ADMIN_PAYOUT_APPROVE : API_ADMIN_PAYOUT_APPROVE_ALL, {
      ...(payout_request_ids && { payout_request_ids }),
      action: 'APPROVE',
    });
  }

  approveRequestTransactions(requestId: number, transaction_ids?: number[]) {
    return this.http
      .post(
        transaction_ids
          ? API_ADMIN_PAYOUT_TRANSACTION_APPROVE(requestId)
          : API_ADMIN_PAYOUT_TRANSACTION_APPROVE_ALL(requestId),
        { ...(transaction_ids && { transaction_ids }) }
      )
      .pipe(catchError(() => of(true)));
  }

  deactivateRequests(payout_request_ids?: number[]) {
    return this.http
      .post(payout_request_ids ? API_ADMIN_PAYOUT_DEACTIVATE : API_ADMIN_PAYOUT_DEACTIVATE_ALL, {
        ...(payout_request_ids && { payout_request_ids }),
        action: 'DEACTIVATE',
      })
      .pipe(catchError(() => of(true)));
  }

  markReadyRequestAsFailed(payout_request_id: number, reason: string) {
    return this.http.post(API_ADMIN_PAYOUT_READY_REQUESTS_MARK_AS_FAILED(payout_request_id), { reason });
  }

  getReadyRequestDetails(payout_request_id: number) {
    return this.http.get<AdminReadyRequestDetail>(API_ADMIN_PAYOUT_READY_REQUEST_DETAILS(payout_request_id));
  }

  getPayoutRequestedTransactionAmount(requestId: number) {
    return this.getPayoutRequestTransactions(requestId, { payout_status: 'requested', page_size: 1, page: 1 }).pipe(
      map((res) => res.count)
    );
  }

  getReadyRequests(query: AdminPayoutRequestsQuery) {
    return this.http.get<Pagination<AdminPayoutRequest>>(encodeURL(API_ADMIN_PAYOUT_READY_REQUESTS, query));
  }

  getReadyRequestsAmount() {
    return this.getReadyRequests({ page: 1, page_size: 1, status: ['ready'] }).pipe(
      map((pagination) => pagination.count || 0)
    );
  }

  deactivateReadyRequests(requestIds?: number[]) {
    return this.http.post(
      requestIds ? API_ADMIN_PAYOUT_READY_REQUESTS_DEACTIVATE : API_ADMIN_PAYOUT_READY_REQUESTS_DEACTIVATE_ALL,
      {
        ...(requestIds ? { payout_request_ids: requestIds } : undefined),
      }
    );
  }

  releaseReadyRequests(payout_request_ids?: number[]) {
    return this.http.post(
      payout_request_ids ? API_ADMIN_PAYOUT_READY_REQUESTS_RELEASE : API_ADMIN_PAYOUT_READY_REQUESTS_RELEASE_ALL,
      payout_request_ids ? { payout_request_ids } : {}
    );
  }

  getReadyRequestsSummary() {
    return this.http.get<AdminPayoutReadyRequestsSummary>(API_ADMIN_PAYOUT_READY_REQUESTS_SUMMARY);
  }

  getReadyRequestTransactions(
    requestId: number,
    query: { page?: number; page_size?: number; search?: string; payout_status?: string }
  ) {
    return this.http.get<Pagination<AdminPayoutTransaction>>(
      encodeURL(API_ADMIN_PAYOUT_READY_REQUESTS_TRANSACTIONS(requestId), query)
    );
  }

  getInstructorBalances(query: AdminPayoutInstructorBalancesQuery) {
    return this.http.get<Pagination<AdminInstructorBalance>>(encodeURL(API_ADMIN_PAYOUT_INSTRUCTOR_BALANCES, query));
  }

  getInstructorTransactions(instructorId: number, query: AdminPayoutInstructorBalanceTransactionsQuery) {
    return this.http.get<Pagination<AdminPayoutTransaction>>(
      encodeURL(API_ADMIN_PAYOUT_INSTRUCTOR_BALANCE_TRANSACTIONS(instructorId), query)
    );
  }

  getInstructorPayouts(instructorId: number, query: AdminInstructorPayoutsQuery) {
    return this.http.get<Pagination<AdminPayoutRequest>>(
      encodeURL(API_ADMIN_PAYOUT_INSTRUCTOR_BALANCE_PAYOUTS(instructorId), query)
    );
  }

  getInstructorWithdrawActivities(instructorId: number, query: AdminInstructorWithdrawActivitiesQuery) {
    return this.http.get<Pagination<AdminInstructorWithdrawActivity>>(
      encodeURL(API_ADMIN_PAYOUT_INSTRUCTOR_WITHDRAWS(instructorId), query)
    );
  }

  getInstructorBalance(instructor_id: number) {
    return this.getInstructorBalances({ instructor_id, page: 1, page_size: 1 }).pipe(
      map((data) => data.results && data.results[0])
    );
  }

  holdReadyRequestTransaction(requestId: number, tranId: number, reason: string) {
    return this.http.post(API_ADMIN_PAYOUT_READY_REQUESTS_CONFIRM_HOLD(requestId, tranId), { reason });
  }

  unholdTransaction(tranId: number) {
    return this.http.post(API_ADMIN_PAYOUT_UNHOLD_TRANSACTION(tranId), {});
  }

  getPaidPayouts(query: AdminPaidPayoutsQuery) {
    return this.http.get<Pagination<AdminPayoutRequest>>(encodeURL(API_ADMIN_PAYOUT_PAID_REQUESTS, query));
  }

  getFailedPayouts(query: AdminFailedPayoutsQuery) {
    return this.http.get<Pagination<AdminPayoutRequest>>(encodeURL(API_ADMIN_PAYOUT_FAILED_REQUESTS, query));
  }

  retryFailedPayout(requestId: number) {
    return this.http.post(API_ADMIN_PAYOUT_RETRY_REQUESTS(requestId), {});
  }

  getRetryPayoutDetail(requestId: number) {
    return this.http.get<AdminPayoutRetryInformation>(API_ADMIN_PAYOUT_RETRY_REQUEST_DETAILS(requestId));
  }

  getPaidPayoutsSummary() {
    return this.http.get<AdminPayoutPaidRequestsSummary>(API_ADMIN_PAYOUT_PAID_REQUESTS_SUMMARY).pipe(
      catchError(() =>
        of({
          currency_symbol: '$',
          total_paid_amount: 120,
          total_payees: 1,
          total_payouts: 1,
          total_transactions: 1,
          paypal_amount: 0,
          bank_transfer_amount: 120,
          payoneer_amount: 0,
        } as AdminPayoutPaidRequestsSummary)
      )
    );
  }

  getPayoutRequestHistory(requestId: number) {
    return this.http
      .get<{ history: AdminPayoutRequestHistoryEvent[] }>(API_ADMIN_PAYOUT_REQUEST_HISTORY(requestId))
      .pipe(map((res) => res.history));
  }

  getInactivePayoutSummary(): Observable<InactivePayoutSummary> {
    return this.http.get<InactivePayoutSummary>(API_GET_ADMIN_INACTIVATION_PAYOUT_SUMMARY);
  }

  getFailedPayoutSummary(): Observable<FailedPayoutSummary> {
    return this.http.get<FailedPayoutSummary>(API_GET_ADMIN_FAILED_PAYOUT_SUMMARY);
  }
}
