import { ManageUserComponent } from './manage-user.component';

export * from './manage-user.component';

export const Components = [ManageUserComponent];

export const Routing = [
  {
    path: 'login-as',
    component: ManageUserComponent,
    data: { title: 'Login As', breadcrumb: 'Login As' },
  },
];
