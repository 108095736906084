import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { I18nService } from '@app/i18n';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { AuthActions, ModalConfirmComponent, ToastComponent, UntilDestroy, untilDestroyed } from '@shared';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { CategoryActions, CredentialsService, Logger, ModalService, ToastService, TopicActions, isDefined } from 'thkee-common';

const log = new Logger('App');

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('modalConfirm')
  private modalConfirm!: ModalConfirmComponent;

  @ViewChild('toastComponent')
  private toastComponent!: ToastComponent;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private store: Store,
    private credentialsService: CredentialsService,
    private modalService: ModalService,
    private toastService: ToastService,
    private breadCrumb: BreadcrumbService
  ) {}

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });

    // Init modal
    this.modalService.modal$
      .pipe(
        filter(isDefined),
        tap((data) => log.debug(data)),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.modalConfirm.open();
      });

    this.toastService.toast$
      .pipe(
        filter(isDefined),
        tap((data) => log.debug(data)),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        this.toastComponent.open();
      });

    if (this.credentialsService.hasCredentials()) {
      // Init ngrx store
      this.store.dispatch(CategoryActions.loadCategories());
      this.store.dispatch(TopicActions.loadTopics());
      this.store.dispatch(AuthActions.userLoad());
    } else {
    }
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }

  get modal$() {
    return this.modalService.modal$;
  }

  get toast$() {
    return this.toastService.toast$;
  }
}
