<div #dateRangePicker date-rangepicker class="flex items-center">
  <div class="flex gap-2">
    <div class="inset-y-0 flex items-center pointer-events-none">
         <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
        </svg>
    </div>
    <!-- changeDate event ref: https://github.com/themesberg/flowbite-datepicker/issues/22 -->
    <div class="flex gap-1 form-field form-sm relative">
      <div class="flex gap-1 items-center">
        <div class="relative items-center flex">
          <div class="absolute">
            <input #start [disabled]="isDisabled" (changeDate)="changeStart($event)" name="start" type="text" class="!outline-none w-full form-field form-sm opacity-0 !ring-0 !border-0">
          </div>
          <span *ngIf="model?.start || model?.end; else placeholderView" (click)="showStart()" class="z-10 text-slate-300">{{start.value || ''}}</span>
          <ng-template #placeholderView><span (click)="showStart()" class="p-1 text-nowrap z-10">{{placeholder || ''}}</span></ng-template>
        </div>
        <div [class.hidden]="!(model?.end || model?.start)" class="flex gap-1 items-center">
          -
          <div class="relative flex items-center overflow-hidden">
            <div class="absolute">
              <input [disabled]="isDisabled" #end (changeDate)="changeEnd($event)" name="end" type="text" class="!outline-none w-full form-field form-sm opacity-0 !ring-0 !border-0">
            </div>
            <span class="z-10" (click)="showEnd()">{{end.value || ''}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
