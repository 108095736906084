<div class="flex max-h-fit w-full flex-col items-center justify-center bg-white">
  <div>
    <h3 class="text-xl">Instructions for Using the "Login As User" Feature</h3>
    <ul class="pl-5">
      <li class="mb-1 list-decimal">
        <p class="mb-0">Open a Private/Incognito Window</p>
        <p class="font-bold">Before proceeding, open a private or incognito browser window:</p>
        <ul class="mt-1 pl-5">
          <li class="mb-1 list-[circle]">
            Chrome/Edge:
            <span class="bg-slate-700 px-2 py-0.5 text-xs text-white">Ctrl + Shift + N</span> (Windows) or
            <span class="bg-slate-700 px-2 py-0.5 text-xs text-white">Cmd + Shift + N</span> (Mac)
          </li>
          <li class="mb-1 list-[circle]">
            Firefox: <span class="bg-slate-700 px-2 py-0.5 text-xs text-white">Ctrl + Shift + P</span>
          </li>
          <li class="mb-1 list-[circle]">
            Safari: <span class="bg-slate-700 px-2 py-0.5 text-xs text-white">Cmd + Shift + N</span>
          </li>
        </ul>
      </li>
      <li class="mb-1 list-decimal">
        <p class="mb-0">Copy the Private Link</p>
        <ul class="mt-1 pl-5">
          <li class="mb-1 list-[circle]">
            When you click the "Sign In As" button, a private link will be automatically copied to your clipboard.
          </li>
          <li class="mb-1 list-[circle]">
            <b>Important</b>: Paste this link into the private/incognito window to access the user's session.
          </li>
        </ul>
      </li>
      <li class="mb-1 list-decimal">
        <p class="mb-0">Perform Your Actions</p>
        <ul class="mt-1 pl-5">
          <li class="mb-1 list-[circle]">
            Complete the necessary actions on behalf of the user in the private/incognito window.
          </li>
        </ul>
      </li>
      <li class="mb-1 list-decimal">
        <p class="mb-0">Sign Out Immediately</p>
        <ul class="mt-1 pl-5">
          <li class="mb-1 list-[circle]">Please, please sign out as soon as you are done.</li>
          <li class="mb-1 list-[circle]">
            This step is critical to ensure the security of the user's session and to prevent any session conflicts.
          </li>
        </ul>
      </li>
      <li class="mb-1 list-decimal">
        <p class="mb-0">Close the Private/Incognito Window</p>
        <ul class="mt-1 pl-5">
          <li class="mb-1 list-[circle]">After signing out, close the private/incognito window immediately.</li>
        </ul>
      </li>
    </ul>
    <ul>
      <p class="mb-0 font-bold">Important Notes:</p>
      <li class="mb-0 ml-5 list-decimal">
        Always use a private/incognito window to avoid mixing sessions with your admin account or personal user session.
      </li>
      <li class="mb-0 ml-5 list-decimal">
        If you encounter issues, verify that the private/incognito window is being used correctly or contact support.
      </li>
    </ul>
  </div>
  <div class="w-full max-w-md">
    <div class="container relative z-10 flex flex-col items-center justify-between gap-2 py-4 lg:gap-4">
      <form class="flex w-full flex-col items-center md:flex-row" [formGroup]="loginForm" (submit)="loginAsForm()">
        <input
          class="mb-3 h-full w-full border-slate-500 p-2.5 outline-none md:mb-0"
          type="email"
          formControlName="email"
          name="email"
          i18n-placeholder
          placeholder="Enter user email"
        />
        <button
          [disabled]="isSubmitted"
          [thkLibProcessing]="isSubmitted"
          variant="white"
          class="flex w-full items-center justify-center gap-2 text-nowrap bg-gradient-to-r from-[#AA2BFF] to-[#7A1BFF] px-6 py-2.5 text-base text-white md:w-auto"
          type="submit"
          i18n
        >
          Sign In
        </button>
      </form>
      <div
        *ngIf="email?.invalid && (email?.dirty || email?.touched)"
        class="w-full max-w-[600px] rounded-md bg-red-50 px-6 py-3 font-bold text-red-400"
      >
        <div *ngIf="email?.hasError('required')" i18n>Email is required.</div>
        <div *ngIf="email?.hasError('email')" i18n>Please provide your valid email.</div>
      </div>
    </div>
    <div *ngIf="loginAsURL.length" class="relative flex flex-row items-center gap-3">
      <span class="w-full overflow-hidden text-ellipsis whitespace-nowrap">{{ loginAsURL }}</span>
      <thk-ui-snippets [type]="'clipboardButton'" [clipboardInput]="loginAsURL" customClass="w-11 h-11 !rounded-full">
      </thk-ui-snippets>
    </div>
  </div>
</div>
