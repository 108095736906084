<div class="relative flex flex-col gap-1 border rounded-lg p-4 bg-white w-full h-full" [ngClass]="styles[variant].border">
  <div class="absolute left-0 top-0 py-4 h-full">
    <span class="w-[5px] rounded-tr-[50px] rounded-br-[50px] block h-full" [ngClass]="styles[variant].bg"></span>
  </div>
  <div class="flex flex-col gap-2">
    <h5 class="text-xs font-normal" [ngClass]="styles[variant].color">{{data.title}}</h5>
    <h5 class="text-lg font-semibold">{{data.value}}</h5>
    <ng-content select="[subMessage]"></ng-content>
  </div>
  <div class="absolute right-4 flex gap-2">
    <svg-icon *ngIf="data.description" [tooltip]="data.description" src="@assets/images/icons/question-mark-circle.svg" class="btn-icon text-amber-600"></svg-icon>
    <ng-content select="[informative]"></ng-content>
  </div>
</div>
