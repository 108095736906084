import { Shell } from '@app/shell/shell.service';
import * as InstructorGroup from './instructor-group';
import * as PayoutActivation from './payout-activation';
// Standalone
import * as ManageUser from './manage-user';

export const Components = [...InstructorGroup.Components, ...PayoutActivation.Components];

export const Routing = Shell.childUserRoutes([
  ...InstructorGroup.Routing,
  ...PayoutActivation.Routing,
  ...ManageUser.Routing,
]);
