import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalComponent, UntilDestroy, untilDestroyed } from '@shared';
import * as _ from 'lodash';
import { Observable, combineLatest, distinctUntilChanged, firstValueFrom, map, take } from 'rxjs';
import {
  ActivityState,
  AnnotationDetail,
  AnnotationService,
  AssignmentDetailV2,
  AssignmentQuestionV2,
  ContentTypeApps,
  ContentTypeModels,
  CourseActions,
  CourseDetailV2,
  FeedbackState,
  LectureDetailV2,
  LoaderService,
  Logger,
  ProjectService,
  QuizDetailV2,
  QuizQuestionDetailV2,
  QuizQuestionV2,
  RouterStoreService,
  SectionDetailV2,
  SectionV2,
  SubsectionDetailV2,
  SubsectionType,
  generateUuid,
  isDefined,
  selectActivityState,
  selectContentType,
  selectCourseDetail,
  selectFeedback,
  selectIsActivityModalOpen,
  selectSelectedActivityId,
  selectSelectedSectionId,
} from 'thkee-common';

const log = new Logger('CourseCurriculumComponent');

@UntilDestroy()
@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss'],
})
export class CourseCurriculumComponent implements OnInit {
  @Input() isDefaultView: boolean = true;
  @ViewChild('sectionModal') sectionModal!: ModalComponent;
  @ViewChild('activityModal') activityModal!: ModalComponent;
  @ViewChild('feedbackModal') feedbackModal!: ModalComponent;

  courseState: string = '0';
  readOnly: boolean = true;
  courseId: string = '';

  sectionsData: SectionDetailV2[] = [];
  sectionEdit: boolean[] = [];
  sectionCollapse: boolean[] = [];
  subSectionCollapse: boolean[] = [];
  sectionData: Record<string, SubsectionDetailV2[]> = {};
  quizCollapse: boolean[] = [];

  activitySize = {
    type_lecture: 'sm:w-full sm:max-w-xl',
    type_quiz: 'sm:w-full sm:max-w-xl',
    type_assignment: 'sm:w-full sm:max-w-xl',
    lecture_video: 'sm:w-full sm:max-w-xl',
    lecture_article: 'sm:w-full sm:max-w-xl',
    video_library: 'sm:w-full sm:max-w-3xl',
  };

  haveFeedbackHistory: boolean = false;
  annotation$!: Observable<AnnotationDetail> | undefined;
  courseDetail$!: Observable<CourseDetailV2>;
  feedback$!: Observable<FeedbackState>;

  activityState$ = this.store.select(selectActivityState);
  private courseId$ = this.routerStore.getParam('courseId');
  private selectedSectionId$ = this.store.select(selectSelectedSectionId);
  private selectedActivityId$ = this.store.select(selectSelectedActivityId);
  isLoading$ = this.loaderService.response('loading-instructor-courses-details');

  constructor(
    private store: Store,
    private routerStore: RouterStoreService,
    private projectService: ProjectService,
    private annotationService: AnnotationService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.courseDetail$ = this.store.select(selectCourseDetail);

    this.courseDetail$.pipe(untilDestroyed(this)).subscribe((courseDetail) => {
      this.sectionsData = _.sortBy(courseDetail.sections, (section) => section.position);
      this.sectionData = _.groupBy(
        courseDetail.sections?.flatMap((section) => section.subsections),
        (subsection) => subsection.section!
      );
      log.debug('courseDetail', courseDetail);
      log.debug('sectionsData', this.sectionsData);
      log.debug('sectionData', this.sectionData);
    });

    this.store
      .select(selectIsActivityModalOpen)
      .pipe(untilDestroyed(this))
      .subscribe((isActivityModalOpen) => {
        if (isActivityModalOpen) {
          this.activityModal?.open();
        } else {
          this.activityModal?.close();
        }
      });

    this.courseId$.pipe(untilDestroyed(this)).subscribe((courseId) => {
      this.courseId = courseId;
    });

    this.feedback$ = this.store.select(selectFeedback);

    // handle feedback modal open/close
    this.feedback$
      .pipe(
        map((feedback) => feedback.isFeedbackModalOpen),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((isFeedbackModalOpen) => {
        if (this.feedbackModal) {
          if (isFeedbackModalOpen) {
            this.feedbackModal.open();
          } else {
            this.feedbackModal.close();
          }
        }
      });
  }

  initFields(disabled: boolean = this.readOnly) {}

  actionEvent(event: any) {
    this.readOnly = true;
    this.initFields(true);
    this.courseState = '0';

    if (event === 'accept') {
      firstValueFrom(this.courseDetail$).then((courseDetail) => {
        this.projectService.toStatePublished(courseDetail.project.id);
      });
    } else if (event === 'reject') {
      firstValueFrom(this.courseDetail$).then((courseDetail) => {
        this.projectService.toStateRejected(courseDetail.project.id);
      });
    }
  }

  async sectionHandler(action: 'created' | 'cancel', data: any) {
    log.debug('sectionHandler Emit -- ', action, data);
    const model: { title: string } = _.cloneDeep(data);
    if (action === 'created') {
      await firstValueFrom(this.courseId$).then((courseId) => {
        this.store.dispatch(
          CourseActions.upsertSection({ section: { ...model, course: courseId, id: generateUuid() } })
        );
      });
    }
    this.sectionModal.close();
  }

  createActivity(sectionId: string, type: string) {
    this.store.dispatch(CourseActions.createActivity({ sectionId }));
  }

  editActivity(sectionId: string, subsectionId: string, activityId: string, activityState: ActivityState) {
    this.store.dispatch(CourseActions.editActivity({ sectionId, subsectionId, activityId, activityState }));
  }

  onActivityModalNext(activityState: string) {
    console.log('activityState--');
    this.store.dispatch(CourseActions.modalNext({ activityState: activityState as ActivityState }));
  }

  lectureHandler(action: 'created' | 'saved' | 'cancel', data: any) {
    log.debug('lectureHandler Emit -- ', action, data);
    // clone data to prevent sending readonly data from store due to formly update to model itself
    const lecture: LectureDetailV2 = _.cloneDeep(data);
    if (action === 'created') {
      const lectureId = generateUuid();
      firstValueFrom(this.selectedSectionId$).then((sectionId) => {
        this.store.dispatch(
          CourseActions.createLecture({
            subsection: { id: generateUuid(), section: sectionId, lecture: lectureId, type: SubsectionType.LECTURE },
            lecture: {
              ...lecture,
              id: lectureId,
              section: sectionId,
            },
          })
        );
      });
    } else if (action === 'saved') {
      this.store.dispatch(
        CourseActions.upsertLecture({
          lecture: {
            ...lecture,
          },
          lectureResources: (lecture.resources ?? []).filter(isDefined).map((resource) => ({
            ...resource,
            id: resource.id ?? generateUuid(),
            lecture: lecture.id,
          })),
        })
      );
      this.closeActivity();
    } else if (action === 'cancel') {
      this.closeActivity();
    }
  }

  quizHandler(action: 'quiz_created' | 'quiz_save' | 'qa_created' | 'qa_saved' | 'cancel' | 'qa_deleted', data: any) {
    // clone data to prevent sending readonly data from store due to formly update to model itself
    data = _.cloneDeep(data);
    log.debug('quizHandler Emit -- ', action, data);
    if (action === 'quiz_created') {
      const quizDetail: QuizDetailV2 = data;
      const quizId = generateUuid();
      firstValueFrom(this.selectedSectionId$).then((sectionId) => {
        this.store.dispatch(
          CourseActions.createQuiz({
            subsection: { id: generateUuid(), section: sectionId, quiz: quizId, type: SubsectionType.QUIZ },
            quiz: {
              id: quizId,
              section: sectionId,
              title: quizDetail.title,
            },
          })
        );
      });
    } else if (action === 'quiz_save') {
      const quizDetail: QuizDetailV2 = data;
      combineLatest([this.selectedSectionId$, this.selectedActivityId$])
        .pipe(take(1))
        .subscribe(([sectionId, quizId]) => {
          this.store.dispatch(
            CourseActions.upsertQuiz({
              quiz: {
                id: quizId,
                section: sectionId,
                title: quizDetail.title,
              },
            })
          );
        });
      this.closeActivity();
    } else if (action === 'qa_created') {
      const questionDetail: QuizQuestionDetailV2 = data;
      const quizQuestionId = questionDetail.id ?? generateUuid();
      firstValueFrom(this.selectedActivityId$).then((quizId) => {
        this.store.dispatch(
          CourseActions.upsertQuizQuestion({
            quizQuestion: { id: quizQuestionId, quiz: quizId, question: questionDetail.question },
            quizAnswers: questionDetail.answers.map((answer) => ({
              ...answer,
              id: answer.id ?? generateUuid(),
              quiz_question: quizQuestionId,
            })),
          })
        );
      });
    } else if (action === 'qa_saved') {
      const questionDetail: QuizQuestionDetailV2 = data;
      this.store.dispatch(
        CourseActions.upsertQuizQuestion({
          quizQuestion: questionDetail,
          quizAnswers: questionDetail.answers.map((answer) => ({
            ...answer,
            id: answer.id ?? generateUuid(),
            quiz_question: questionDetail.id,
          })),
        })
      );
    } else if (action === 'qa_deleted') {
      const questionId: string = data;
      this.store.dispatch(
        CourseActions.deleteQuizQuestion({
          quizQuestionId: questionId,
        })
      );
    } else if (action === 'cancel') {
      this.closeActivity();
    }
  }

  assignmentHandler(action: 'created' | 'save' | 'cancel', data: any) {
    log.debug('assignmentHandler Emit -- ', action, data);
    data = _.cloneDeep(data);
    if (action === 'created') {
      const assignmetnDetail: AssignmentDetailV2 = data;
      const assignmentId = generateUuid();
      firstValueFrom(this.selectedSectionId$).then((sectionId) => {
        this.store.dispatch(
          CourseActions.createAssignment({
            subsection: {
              id: generateUuid(),
              section: sectionId,
              assignment: assignmentId,
              type: SubsectionType.ASSIGNMENT,
            },
            assignment: {
              id: assignmentId,
              section: sectionId,
              title: assignmetnDetail.title,
            },
          })
        );
      });
    } else if (action == 'save') {
      const assignmentDetail: AssignmentDetailV2 = data;
      combineLatest([this.selectedSectionId$, this.selectedActivityId$])
        .pipe(take(1))
        .subscribe(([sectionId, assignmentId]) => {
          this.store.dispatch(
            CourseActions.upsertAssignment({
              assignment: { id: assignmentId, section: sectionId, title: assignmentDetail.title },
              assignmentQuestions: assignmentDetail.questions.map((question) => ({
                ...question,
                id: question.id ?? generateUuid(),
                assignment: assignmentId,
              })),
            })
          );
        });
      this.closeActivity();
    } else if (action == 'cancel') {
      this.closeActivity();
    }
  }

  editSection(sectionId: string, title: string) {
    this.store.dispatch(CourseActions.upsertSection({ section: { id: sectionId, title } }));
  }

  deleteSection(sectionId: string) {
    // this.store.dispatch(CourseActions.deleteSection({ sectionId }));

    const params = { course: this.courseId };
    this.store.dispatch(CourseActions.deleteSection({ sectionId, params }));
  }

  deleteSubsection(subsectionId: string) {
    this.store.dispatch(CourseActions.deleteSubsection({ subsectionId }));
  }

  closeActivity() {
    this.store.dispatch(CourseActions.closeActivity());
  }

  onFeedbackOpenSection(section: SectionV2) {
    log.debug('onFeedbackOpenSection', section);

    this.store.dispatch(
      CourseActions.openFeedbackModal({
        fieldKey: 'title',
        fieldValue: section.title,
        fieldModel: ContentTypeModels.SECTION,
        fieldId: section.id,
      })
    );
  }

  private subsectionContentTypeMap = {
    [SubsectionType.LECTURE]: ContentTypeModels.LECTURE,
    [SubsectionType.QUIZ]: ContentTypeModels.QUIZ,
    [SubsectionType.ASSIGNMENT]: ContentTypeModels.ASSIGNMENT,
  };
  onFeedbackOpenActivity(subsection: SubsectionDetailV2) {
    log.debug('onFeedbackOpenActivity', subsection);

    this.store.dispatch(
      CourseActions.openFeedbackModal({
        fieldKey: 'title',
        fieldValue: subsection[subsection.type!]?.title ?? '',
        fieldModel: this.subsectionContentTypeMap[subsection.type!],
        fieldId: subsection[subsection.type!]?.id ?? '',
      })
    );
  }

  onFeedbackOpenQuizQuestion(quizQuestion: QuizQuestionDetailV2) {
    log.debug('onFeedbackOpenQuizQuestion', quizQuestion);

    this.store.dispatch(
      CourseActions.openFeedbackModal({
        fieldKey: 'question',
        fieldValue: quizQuestion.question,
        fieldModel: ContentTypeModels.QUIZ_QUESTION,
        fieldId: quizQuestion.id,
      })
    );
  }

  onFeedbackOpenAssignmentQuestion(assignmentQuestion: AssignmentQuestionV2) {
    log.debug('onFeedbackOpenAssignmentQuestion', assignmentQuestion);

    this.store.dispatch(
      CourseActions.openFeedbackModal({
        fieldKey: 'question',
        fieldValue: assignmentQuestion.question,
        fieldModel: ContentTypeModels.ASSIGNMENT_QUESTION,
        fieldId: assignmentQuestion.id,
      })
    );
  }

  async onFeedbackSubmit(annotation: Partial<AnnotationDetail>) {
    log.debug('onFeedbackSubmit');
    const feedback = await firstValueFrom(this.feedback$);
    const contentType = await firstValueFrom(
      this.store.select(selectContentType(ContentTypeApps.COURSES, feedback.fieldModel))
    );
    this.store.dispatch(
      CourseActions.upsertAnnotation({
        annotation: {
          ...annotation,
          id: annotation.id ?? generateUuid(),
          content_type: contentType?.id ?? 0,
          course: this.courseId,
          feedback: annotation.feedback ?? '',
          field: feedback.fieldKey,
          object_id: feedback.fieldId,
          required_action: annotation.required_action ?? false,
          model: feedback.fieldModel,
        },
      })
    );
  }

  onFeedbackCancel() {
    log.debug('onFeedbackCancel');

    this.store.dispatch(CourseActions.closeFeedbackModal());
  }

  getAnnotationCountSection(section: SectionV2): Observable<number> {
    return this.annotationService.getAnnotationCount('title', ContentTypeModels.SECTION, section.id);
  }

  getAnnotationCountActivity(subsection: SubsectionDetailV2): Observable<number> {
    const typeMap = {
      [SubsectionType.LECTURE]: ContentTypeModels.LECTURE,
      [SubsectionType.QUIZ]: ContentTypeModels.QUIZ,
      [SubsectionType.ASSIGNMENT]: ContentTypeModels.ASSIGNMENT,
    };

    const contentType = typeMap[subsection.type ?? ('' as keyof typeof typeMap)];
    const content = subsection[subsection.type as keyof SubsectionDetailV2];

    const contentId = typeof content === 'object' && content !== null ? content.id ?? '' : '';

    return this.annotationService.getAnnotationCount(
      'title',
      contentType,
      contentId
      // typeMap[subsection.type ?? ''],
      // subsection[subsection.type ?? ''].id ?? ''
    );
  }

  getAnnotationCountAssignmentQuestion(assignmentQuestion: AssignmentQuestionV2): Observable<number> {
    return this.annotationService.getAnnotationCount(
      'question',
      ContentTypeModels.ASSIGNMENT_QUESTION,
      assignmentQuestion.id
    );
  }

  getAnnotationCountQuizQuestion(quizQuestion: QuizQuestionV2): Observable<number> {
    return this.annotationService.getAnnotationCount('question', ContentTypeModels.QUIZ_QUESTION, quizQuestion.id);
  }
}
