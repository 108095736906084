<div class="course-overview p-4 pt-0">
   <div class="flex items-center justify-between mb-4">
      <div class="page-heading">
         <h3 class="text-2xl font-semibold text-neutral-800">
            Inactive Payout
         </h3>
      </div>
   </div>
   <div class="flex flex-col gap-4 mb-4">
      <ng-container *ngIf="isLoadingInactivePayoutSummary; else loadingInactivePayoutSummary">
         <div class="flex justify-center items-center h-12" [thkLibProcessing]="true"></div>
      </ng-container>
      <ng-template #loadingInactivePayoutSummary>
         <div class="flex flex-row gap-4">
            <div
               class="relative flex flex-col gap-1 border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
               <span
                  class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-blue-500"></span>
               <h5 class="text-xs font-normal text-[#666666]">Total inactive Amount</h5>
               <h5 class="text-lg font-bold text-[#3941FE]">{{paymentHistory.currency_symbol}} {{paymentHistory.total_inactive_amount}} </h5>
               <svg-icon [tooltip]="'Total inactive Amount'" src="@assets/images/icons/question-mark-circle.svg"
                  class="btn-icon absolute right-4 text-amber-600"></svg-icon>
            </div>
            <div
               class="relative flex flex-col gap-1 border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
               <span
                  class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-blue-500"></span>
               <h5 class="text-xs font-normal text-[#666666]">Inactive Payouts</h5>
               <h5 class="text-lg font-bold text-[#3941FE]">{{paymentHistory.total_inactive_payouts}} </h5>
               <svg-icon [tooltip]="'Inactive Payouts'" src="@assets/images/icons/question-mark-circle.svg"
                  class="btn-icon absolute right-4 text-amber-600"></svg-icon>
            </div>
            <div
               class="relative flex flex-col gap-1 border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
               <span
                  class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-blue-500"></span>
               <h5 class="text-xs font-normal text-[#666666]">Total Transactions</h5>
               <h5 class="text-lg font-bold text-[#3941FE]">{{paymentHistory.total_transactions}} </h5>
               <svg-icon [tooltip]="'Total Transactions'" src="@assets/images/icons/question-mark-circle.svg"
                  class="btn-icon absolute right-4 text-amber-600"></svg-icon>
            </div>
         </div>
      </ng-template>
   </div>
   <div class="page-heading mb-4 flex items-center justify-between">
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="toggleDropdown('filter')" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="showingDropdown === 'filter' ? '' : 'hidden'"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
               <div class="form">
                  <form [formGroup]="filterForm">
                     <formly-form [form]="filterForm" [fields]="filterFormFields" [model]="filterFormModel">
                     </formly-form>
                  </form>
               </div>
               <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                  <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">
                     <span>Reset</span>
                  </button>
               </div>
            </div>
         </div>
         <app-combination-single-input [keys]="searchOptions" (valueChanges)="search($event)" [model]="searchValue"></app-combination-single-input>
      </div>
   </div>

   <div class="flex-3 w-full overflow-hidden card rounded-lg border border-neutral-100">
      <div class="card-subheader flex items-center justify-between px-6 py-5">
         <div class="flex items-center gap-1">
            <h6>Inactive Payouts</h6>
            <div *ngIf="payoutsPagination" class="tag tag-sm">{{payoutsPagination.count}} Payee</div>
         </div>
      </div>
      <div class="card-body">
         <div class="courses-list">
            <!-- Table -->
            <div class=" datagrid-table relative overflow-x-auto">
               <table class="w-full min-w-[1250px] text-left">
                  <thead class="heading">
                     <tr>
                        <th scope="col" class="">
                           <div class="col-name">Period</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Instructor</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Account Status</div>
                        </th>
                        <th scope="col" class="w-28">
                           <div class="col-name">Payout Method</div>
                        </th>
                        <th scope="col" class="w-28">
                           <div class="col-name">Inactive Reason</div>
                        </th>
                        <th scope="col" class="w-28">
                           <div class="col-name">Payout Amount</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Transactions</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Status</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Inactivated by</div>
                        </th>
                        <th scope="col" class="w-56 text-end">
                           <div class="col-name">Action</div>
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr class="hover:cursor-pointer" *ngFor="let data of payoutsPagination?.results; let i = index">
                        <td>
                           <span class="course-id">
                              <span class="">
                                 {{ data.period | date:'MMMM y' }}
                              </span>
                           </span>
                        </td>
                        <td>
                           <span [routerLink]="'/ecommerce/payout/inactive-payouts/' + data.id"
                              class="course-id">
                              <span class="text-blue-600 cursor-pointer">
                                 {{ data.instructor.fullname }}
                              </span>
                           </span>
                        </td>
                        <td>
                           <div [ngClass]="getStatus(data.account_status).bg"
                              class="flex flex-col px-2.5 py-[5px] rounded-2xl w-max">
                              <span [ngClass]="getStatus(data.account_status).text"
                                 class="text-sm">{{data.account_status ? 'active' : 'inactive'}}</span>
                           </div>
                        </td>
                        <td class="max-w-[170px]">
                           <span class="">
                              {{ data.payment_method ? data.payment_method:'-' }}
                           </span>
                        </td>
                        <td>
                           {{data.inactive_reason ? data.inactive_reason:'-'}}
                        </td>
                        <td>
                           {{data.requested_amount | currency}}
                        </td>
                        <td>
                           <span class="course-price flex items-center">
                              {{ data.no_of_transactions }}
                           </span>
                        </td>
                        <td>
                           {{ data.status }}
                        </td>
                        <td>
                           <span class="rounded-full px-2 py-[2px] bg-amber-100 text-amber-600 text-xs">
                              {{ data.inactivated_by }}
                           </span>
                        </td>
                        <td>
                           <div class="flex justify-end gap-3">
                              <button (click)="markAsPaidComp.show(data)" class="btn bg-teal-50 text-teal-600 btn-sm border-none w-max">
                                 <span>Mark as Paid</span>
                              </button>
                              <button *ngIf="data.status === 'deactivated'" (click)="openConfirmation(activateConfirmModal, data)" class="btn bg-teal-600 text-white btn-sm">
                                 <span>Activate</span>
                              </button>
                           </div>
                        </td>
                     </tr>
                     <tr *ngIf="isLoadingInactivePayout">
                        <td colspan="10" class="text-center">
                           <div class="flex justify-center" [thkLibProcessing]="true"></div>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>

            <!-- Pagination -->
            <div *ngIf="payoutsPagination?.count" class="pagination flex items-center justify-between p-5">
               <thk-pagination class="w-full" (paginationChange)="paginate($event)" [sizeChangable]="true" [total]="payoutsPagination?.count || 0" [page]="query?.page" [size]="query?.page_size || 10"></thk-pagination>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- Active confirm modal -->
<common-modal #activateConfirmModal customClass="sm:w-full sm:max-w-[788px] rounded-none" title="Activate">
   <ng-container content>
      <ng-container [ngTemplateOutlet]="summaryConfirmationAnalytics"></ng-container>
      <!-- Warnings -->
      <app-messages *ngIf="!processingPayout?.account_status" [messages]="[{ type: 'error', title: 'Attention', message: 'This Account is inactivated by Admin, its recomnded to activavte the account first. You still can send this payout to the instructor. \<b> Are you sure to payout this instructor for May 2020?\</b>'}]"></app-messages>

      <div class="card-subheader flex flex-col items-center justify-center px-6 py-5 border-b border-b-neutral-100">
         <div class="form w-full">
            <form [formGroup]="activePayoutForm" (ngSubmit)="activate(activateConfirmModal)">
               <formly-form [form]="activePayoutForm" [fields]="activePayoutFormFields" [model]="activePayoutFormModel">
               </formly-form>
               <button class="hidden" #activateSubmitBtn></button>
            </form>
         </div>
      </div>
   </ng-container>
   <ng-container footer>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
         <div class="flex gap-4">
            <button (click)="activateConfirmModal.close()" class="btn bg-neutral-100 border-none">
               <span class="text-neutral-900 text-sm font-semibold">Cancel</span>
            </button>
            <button (click)="activateSubmitBtn.click()" class="btn bg-teal-600 border-none" [thkLibProcessing]="requesting">
               <span class="text-white text-sm font-semibold">Confirm</span>
            </button>
         </div>
      </div>
   </ng-container>
</common-modal>

<!-- Buyer drawer -->
<app-drawer #instructorDrawer>
   <!-- Header -->
   <ng-container header>
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center border-b border-b-neutral-100">
         <h3 class="text-xl text-black font-semibold">Faris Friansyah</h3>
         <div class="flex justify-between items-center gap-3">
            <button
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to Instructor detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="instructorDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>

   <ng-container content>
      <!-- Summery -->
      <div class="flex flex-row p-6 border-b border-b-neutral-100">
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">ID Instructor</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">#325</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Courses</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">25</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Total Income</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">$500</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Join Date</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">Nov 08, 2022, 2:03 PM</h3>
         </div>
      </div>

      <!-- Tab -->
      <div class="flex gap-2.5 flex-row p-6 border-b border-b-neutral-100 flex-wrap">
         <ng-container *ngFor="let tab of instructorTabLists">
            <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border">
               {{tab.displayName}}
            </button>
         </ng-container>
      </div>

      <!-- Details -->
      <div class="flex flex-col p-6 pb-0">
         <!-- Settings Tab -->
         <div class="section-label block my-3 w-full">
            <h5 class="text-2xl font-semibold text-black">Settings</h5>
         </div>
         <div class="flex gap-2.5 flex-row py-6 border-b border-b-neutral-100 flex-wrap">
            <ng-container *ngFor="let tab of instructorSettingsTabLists">
               <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border border-none">
                  {{tab.displayName}}
               </button>
            </ng-container>
         </div>
         <!-- Profile -->
         <app-student-profile [account_type]="'instructor'"></app-student-profile>
      </div>
   </ng-container>
</app-drawer>

<ng-template #summaryConfirmationAnalytics>
   <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
      <div class="flex flex-row gap-4 w-full">
         <div class="flex flex-col gap-3 w-full">
            <div class="flex gap-x-2.5 items-center relative">
               <h3 class="text-sm font-semibold text-neutral-600">Instructor</h3>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                  tooltip="The name of the instructor associated with the course purchased." class="w-5 h-5">
               </svg-icon>
            </div>
            <h6 *ngIf="processingPayout" class="text-base font-bold text-neutral-900">
               {{processingPayout.instructor.fullname}} ( <span (click)="instructorDrawer.open()"
                  class="text-blue-600 cursor-pointer">{{processingPayout.instructor.id}}</span> )
            </h6>
         </div>
         <div class="flex flex-col gap-3 w-full">
            <div class="flex gap-x-2.5 items-center relative">
               <h3 class="text-sm font-semibold text-neutral-600">Period</h3>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                  tooltip="The day of the week, day of the month, and time (your local time) at which the transaction took place."
                  class="w-5 h-5">
               </svg-icon>
            </div>
            <h6 class="text-base font-bold text-neutral-900">
               {{processingPayout?.period}}
            </h6>
         </div>
         <div class="flex flex-col gap-3 w-full">
            <div class="flex gap-x-2.5 items-center relative">
               <h3 class="text-sm font-semibold text-neutral-600">Payment Gateway</h3>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                  tooltip="Filter if the transaction was made via credit card or PayPal." class="w-5 h-5">
               </svg-icon>
            </div>
            <h6 class="text-base font-bold text-neutral-900">
               {{processingPayout?.payment_method}}
            </h6>
         </div>
         <div class="flex flex-col gap-3 w-full">
            <div class="flex gap-x-2.5 items-center relative">
               <h3 class="text-sm font-semibold text-neutral-600">Payout Amount</h3>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Payout  Amount" class="w-5 h-5">
               </svg-icon>
            </div>
            <h6 class="text-base font-bold text-neutral-900">
               {{processingPayout?.requested_amount | currency:processingPayout?.currency_symbol}}
            </h6>
         </div>
      </div>
   </div>
</ng-template>
<app-mark-payout-as-paid #markAsPaidComp (response)="handleMarkAsPaid($event)"></app-mark-payout-as-paid>
