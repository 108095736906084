<div class="course-overview p-4 pt-0">
   <div class="page-heading mb-4 flex items-center justify-between">
      <h3>Dashboard</h3>
      <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
         <svg-icon src="@assets/images/icons/export-icon.svg" class="text-blue-600"></svg-icon>
         <span class="text-blue-600 font-semibold text-sm">Exprot CSV</span>
      </button>
   </div>

   <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">
         <div class="card-subheader flex items-center justify-between px-6 py-5">
            <div class="flex flex-col">
               <div class="flex gap-x-2.5 items-center">
                  <h3 class="text-sm font-normat text-neutral-800">Quick Panel</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Quick Panel" class="w-5 h-5">
                  </svg-icon>
               </div>
               <div class="flex items-center gap-2.5">
                  <h6 class="text-lg font-bold text-neutral-800">Transaction</h6>
                  <div class="tag tag-sm">10 New Transaction</div>
                  <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="w-5 h-5 text-blue-600">
                  </svg-icon>
               </div>
            </div>
            <div class="flex gap-2.5 flex-row">
               <ng-container *ngFor="let tab of dashboardTabLists">
                  <button [ngClass]="tab.displayName==='Transaction'?'active':''" class="tab-border">
                     {{tab.displayName}}
                  </button>
               </ng-container>
            </div>
         </div>
         <div class="card-body">
            <div class="courses-list">
               <div class="datagrid-table relative overflow-x-auto">
                  <table class="w-full text-left">
                     <thead class="heading">
                        <tr>
                           <th scope="col" class="">
                              <div class="col-name">Transaction ID</div>
                           </th>
                           <th scope="col" class="">
                              <div class="sortable">
                                 <div class="col-name">Product</div>
                              </div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Buyer</div>
                           </th>
                           <th scope="col" class="w-28">
                              <div class="col-name">Date</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Sale Price</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Channel</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Platform Earning</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Instructor Earning</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Affiliate</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Coupon</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Status</div>
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr class="" *ngFor="let transaction of dummyData">
                           <td>
                              <span class="course-id">
                                 <span class="text-blue-600 underline cursor-pointer">
                                    #{{ transaction.id }}
                                 </span>
                              </span>
                           </td>
                           <td>
                              <div class="flex min-h-[69px] max-w-[170px] items-center">
                                 <span (click)="showDrawer('product')"
                                    class="course-name line-clamp-3 text-blue-600 underline cursor-pointer">
                                    {{ transaction.product.title }}
                                 </span>
                              </div>
                           </td>
                           <td class="max-w-[170px]">
                              <span (click)="showDrawer('buyer')"
                                 class="whitespace-nowrap text-blue-600 underline cursor-pointer">
                                 {{ transaction.buyer.title }}
                              </span>
                           </td>
                           <td>
                              {{transaction.date | date}}
                           </td>
                           <td class="max-w-[170px]">
                              <div class="flex flex-col gap-1">
                                 {{transaction.sale_price | currency}}
                                 <div *ngIf="transaction.status==='Refunded'" class="flex flex-row gap-1 items-center">
                                    <span class="w-[7px] h-[7px] bg-rose-600 rounded-full"></span>
                                    <span class="text-neutral-500 font-normal text-sm">Refunded</span>
                                 </div>
                              </div>
                           </td>
                           <td>
                              <span class="course-price">{{ transaction.channel }}</span>
                           </td>
                           <td>
                              <span class="course-price">{{ transaction.platform_earning | currency }}</span>
                           </td>
                           <td>
                              <span class="course-price">{{ transaction.instructor_earning | currency }}</span>
                           </td>
                           <td>
                              <span class="course-price">{{ transaction.affiliate | currency }}</span>
                           </td>
                           <td>
                              <span class="course-price">Sale {{ transaction.coupon }}%</span>
                           </td>
                           <td>
                              <div [ngClass]="transaction.status==='Declined'?'bg-rose-50':'bg-teal-50'"
                                 class="px-2.5 py-[5px] rounded-md">
                                 <span [ngClass]="transaction.status==='Declined'?'text-rose-600':'text-teal-600'"
                                    class="text-sm">{{transaction.status}}</span>
                                 <span *ngIf="transaction.status==='Refunded'"
                                    class="text-blue-600 text-sm">#{{transaction.refunded_id }}</span>
                              </div>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- Product drawer -->
<div [ngClass]="isShowDrawer['product']?'w-full':'we-0'"
   class="absolute right-0 top-0 h-full overflow-hidden bg-[rgba(0,0,0,0.3)]">

   <div [ngClass]="isShowDrawer['product']?'slide-right-out':'slide-right-in'"
      class="absolute h-full w-[1044px] bg-white z-30 top-0 right-0 border-l border-neutral-100 overflow-y-auto">
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
         <h3 class="text-xl text-black font-semibold">#SAP MM Material Management</h3>
         <div class="flex justify-between items-center gap-3">
            <button
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to course detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="showDrawer('product')" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>

      <!-- Summery -->
      <div class="flex flex-row p-6 border-b border-b-neutral-100">
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Instructor</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Instructor"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-semibold underline text-base text-blue-600">Omar Sherif</h3>
            <h5 class="text-sm font-semibold text-neutral-600">Premium Instructor</h5>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Students Enrolled</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Students Enrolled"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">535</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Course Finished</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Course Finished"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">500</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Course Incomplete</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Course Incomplete"
                  class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">35</h3>
         </div>
      </div>

      <!-- Tab -->
      <div class="flex gap-2.5 flex-row p-6 border-b border-b-neutral-100">
         <ng-container *ngFor="let tab of productTabLists">
            <button [ngClass]="tab.displayName==='General'?'active':''" class="tab-border">
               {{tab.displayName}}
            </button>
         </ng-container>
      </div>

      <!-- Form -->
      <div class="py-6 flex flex-col gap-4">
         <div class="form">
            <form [formGroup]="productForm">
               <formly-form [form]="productForm" [fields]="productFormFields" [model]="productFormModel">
               </formly-form>
            </form>
         </div>
      </div>
   </div>
</div>


<!-- Buyer drawer -->
<div [ngClass]="isShowDrawer['buyer']?'w-full':'we-0'"
   class="absolute right-0 top-0 h-full overflow-hidden bg-[rgba(0,0,0,0.3)]">

   <div [ngClass]="isShowDrawer['buyer']?'slide-right-out':'slide-right-in'"
      class="absolute h-full w-[1044px] bg-white z-30 top-0 right-0 border-l border-neutral-100 overflow-y-auto">
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center border-b border-b-neutral-100">
         <h3 class="text-xl text-black font-semibold">Faris Friansyah</h3>
         <div class="flex justify-between items-center gap-3">
            <button
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to student detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="showDrawer('buyer')" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>

      <!-- Summery -->
      <div class="flex flex-row p-6 border-b border-b-neutral-100">
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">ID Student</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">#325</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Purchased Course</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">25</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Total Spending</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">$500</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Join Date</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">Nov 08, 2022, 2:03 PM</h3>
         </div>
      </div>

      <!-- Tab -->
      <div class="flex gap-2.5 flex-row p-6 border-b border-b-neutral-100 flex-wrap">
         <ng-container *ngFor="let tab of studentTabLists">
            <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border min-w-max">
               {{tab.displayName}}
            </button>
         </ng-container>
      </div>

      <!-- Details -->
      <div class="flex flex-col p-6 pb-0">
         <!-- Settings Tab -->
         <div class="section-label block my-3 w-full">
            <h5 class="text-2xl font-semibold text-black">Settings</h5>
         </div>
         <div class="flex gap-2.5 flex-row py-6 border-b border-b-neutral-100 flex-wrap">
            <ng-container *ngFor="let tab of studentSettingsTabLists">
               <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border border-none">
                  {{tab.displayName}}
               </button>
            </ng-container>
         </div>
         <!-- Profile -->
         <app-student-profile></app-student-profile>
      </div>
   </div>
</div>