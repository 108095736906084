import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { ToastService } from '../../services';
@Component({
  selector: 'thk-ui-snippets',
  templateUrl: './ui-snippets.component.html',
  styleUrls: ['./ui-snippets.component.css'],
})
export class UiSnippetsComponent implements OnInit {
  /**
   * Snippet UI Type common input.
   */
  @Input() type: 'courseCover' | 'userAvatar' | 'clipboardButton' = 'courseCover';
  @Input() customClass: string = 'w-20 h-16';
  @Input() imageUrl?: string;

  constructor(private readonly toastService: ToastService, private clipboard: Clipboard) {}

  ngOnInit(): void {}

  /**
   * @type Clipboard button.
   * @input Any type of input should be there.
   */
  @Input() clipboardInput: any;
  clipboardCopy() {
    if (typeof this.clipboardInput === 'string') {
      this.clipboard.copy(this.clipboardInput);
    } else {
      this.clipboard.copy(JSON.stringify(this.clipboardInput));
    }
    this.toastService.message({
      message: `Copy success!`,
    });
  }
}
