import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DASHBOARD_TAB, PRODUCT_DRAWER_TAB, STUDENT_DRAWER_TAB, STUDENT_SETTINGS_DRAWER_TAB, TabItem } from '@shared';
import * as _ from 'lodash';
import { FileProps, LANGUAGES, Logger } from 'thkee-common';
const log = new Logger('CourseDetailsComponent');

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  // Create product form
  productForm = new FormGroup({});
  productFormModel: any = {};
  productFormFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'card block py-9 px-6 mb-4',
      fieldGroup: [
        {
          className: 'section-label block border-b-[2px] border-neutral-200 pb-3 mb-3',
          template: '<h5>General</h5>',
        },
        {
          key: 'title',
          type: 'input',
          wrappers: ['feedback-field'],
          defaultValue: '#SAP MM Material Management',
          props: {
            inputAppendClass: ['border-0', 'bg-transparent', 'bg-none'],
            label: 'Title',
            minLength: 6,
            maxLength: 60,
            placeholder: 'e.g. Learn Blender in 60 minutes',
            disabled: true,
            tips: 'This is a tip message for course title',
          },
          validation: {
            messages: {
              minLength: "Title can't be lower than 6 characters.",
            },
          },
          expressions: {},
        },
        {
          key: 'subtitle',
          type: 'input',
          wrappers: ['feedback-field'],
          defaultValue: 'Introduction to SAP MM Material Management',
          props: {
            label: 'Subtitle',
            minLength: 6,
            maxLength: 60,
            placeholder: 'e.g. Introduction to SAP MM Material Management!',
            disabled: true,
            tips: 'This is a tip message for course sub title',
          },
          validation: {
            messages: {
              minLength: "This field can't be lower than 6 characters.",
            },
          },
          expressions: {},
        },
        {
          key: 'topics',
          type: 'select',
          defaultValue: ['uiux', 'frontend'],
          wrappers: ['feedback-field'],
          props: {
            label: 'Topics',
            placeholder: 'Choose topic',
            multiple: true,
            stayPlaceholder: true,
            disabled: true,
            tips: 'This is a tip message for course title',
            stylish: true,
            options: [
              { label: 'UI/UX', value: 'uiux' },
              { label: 'Frontend', value: 'frontend' },
              { label: 'Backend', value: 'backend' },
            ],
          },
          expressions: {},
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-6 border-b border-neutral-200 mb-3',
          fieldGroup: [
            {
              key: 'skill_level',
              type: 'select',
              defaultValue: 'Beginner',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Level',
                placeholder: 'Choose a level',
                stylish: true,
                options: [
                  { label: 'All', value: 'All' },
                  { label: 'Beginner', value: 'Beginner' },
                  { label: 'Intermediate', value: 'Intermediate' },
                  { label: 'Expert', value: 'Expert' },
                ],
                disabled: true,
                tips: 'This is a tip message for course title',
              },
              expressions: {},
            },
            {
              key: 'language',
              type: 'select',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Language',
                placeholder: 'Select language',
                stylish: true,
                options: _.sortBy(
                  _.entries(LANGUAGES).map(([key, value]) => ({ label: value, value: key })),
                  (lang) => lang.label
                ),
                disabled: true,
                tips: 'This is a tip message for course title',
              },
              expressions: {},
            },
          ],
        },
        {
          fieldGroupClassName: 'grid grid-cols-2 gap-6',
          fieldGroup: [
            {
              key: 'category',
              type: 'select',
              defaultValue: 'business',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Category',
                placeholder: 'Choose a category',
                stylish: true,
                disabled: true,
                tips: 'This is a tip message for course title',
                options: [
                  { label: 'Business', value: 'business' },
                  { label: 'Design', value: 'design' },
                  { label: 'Language', value: 'language' },
                ],
              },
              expressions: {},
            },
            {
              key: 'subcategory',
              type: 'select',
              defaultValue: 'graphic-design',
              wrappers: ['feedback-field'],
              props: {
                wrapAppendClass: ['border-none', '!mb-0'],
                label: 'Sub category',
                placeholder: 'Choose sub-category',
                stylish: true,
                disabled: true,
                tips: 'This is a tip message for course title',
                options: [
                  { label: 'Graphic Design', value: 'graphic-design' },
                  { label: 'Web Design', value: 'web-design' },
                  { label: 'UI/UX Design', value: 'uiux-design' },
                ],
              },
              expressions: {},
            },
          ],
        },
      ],
    },

    {
      className: 'block border-t border-neutral-100 card mb-4 pt-6 px-6',
      key: 'image',
      type: 'file',
      // wrappers: ['feedback-field'],
      props: {
        wrapAppendClass: ['border-none'],
        label: 'Image Cover',
        placeholder: 'Upload your course image here. Supported files are .jpg, .jpeg, or .png.',
        preview: true,
        previewType: 'image',
        allowedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
        uploadType: 'dashboard',
        disabled: false,
        tips: 'This is a tip message for course title',
      } as FileProps,
      expressions: {},
    },
    {
      className: 'block border-t border-neutral-100 card mb-4 pt-6 px-6',
      key: 'promo_video',
      type: 'file',
      // wrappers: ['feedback-field'],
      props: {
        wrapAppendClass: ['border-none'],
        label: 'Promotional Video',
        placeholder: "Upload your course's promotional video here. Maximum of 200MB.",
        preview: true,
        previewType: 'video',
        hideLabel: false,
        allowedTypes: ['video/mp4', 'video/mkv', 'video/mov'],
        uploadType: 'dashboard',
        disabled: false,
        tips: 'This is a tip message for course title',
        onUpload: (upload, field) => {
          // log.debug('upload: ', upload);
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadStart({
          //       referenceId: `${courseId}-${field.key}`,
          //       fileName: upload.name,
          //       fileType: upload.type,
          //     })
          //   );
          // });
        },
        onProgress: (progress, field) => {
          // log.debug('progress: ', progress);
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadProgress({
          //       referenceId: `${courseId}-${field.key}`,
          //       bytesTotal: progress.bytesTotal,
          //       bytesUploaded: progress.bytesUploaded,
          //       progressPercent: progress.progressPercent,
          //     })
          //   );
          // });
        },
        onComplete: (field) => {
          // log.debug('complete');
          // firstValueFrom(this.courseId$).then((courseId) => {
          //   this.store.dispatch(
          //     CourseActions.uploadComplete({
          //       referenceId: `${courseId}-${field.key}`,
          //     })
          //   );
          // });
        },
      } as FileProps,
      expressions: {
        // 'props.metadata': combineLatest([this.course$, this.userId$]).pipe(
        //   map(([course, userId]) => ({
        //     user: userId,
        //     course: course.id,
        //   })),
        //   distinctUntilChanged()
        // ),
        // 'props.previewUrl': this.course$.pipe(
        //   map((course) => {
        //     return course.promo_video_url;
        //   }),
        //   distinctUntilChanged()
        // ),
        // 'props.feedback': this.getFeedbackCount('promo_video'),
      },
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  isShowDrawer: { [key: string]: boolean } = {};

  showDrawer(type: any) {
    this.isShowDrawer[type] = !this.isShowDrawer[type];
  }

  //Dashboard TAB
  dashboardTabLists: TabItem[] = DASHBOARD_TAB;

  //Product TAB
  productTabLists: TabItem[] = PRODUCT_DRAWER_TAB;

  //Student TAB
  studentTabLists: TabItem[] = STUDENT_DRAWER_TAB;

  //Student Settings TAB
  studentSettingsTabLists: TabItem[] = STUDENT_SETTINGS_DRAWER_TAB;

  // Dummy data
  public dummyData: any = [
    {
      id: '123',
      product: {
        id: 'p001',
        title: 'Web 101 Fundamental...',
      },
      buyer: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      date: '2024-02-20T10:30:31.398Z',
      sale_price: 23,
      channel: 'Ads',
      platform_earning: 23,
      instructor_earning: 30,
      affiliate: 12,
      coupon: 20,
      status: 'Refunded',
      refunded_id: 546,
    },
    {
      id: '124',
      product: {
        id: 'p001',
        title: 'Web 101 Fundamental...',
      },
      buyer: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      date: '2024-02-20T10:30:31.398Z',
      sale_price: 23,
      channel: 'Ads',
      platform_earning: 23,
      instructor_earning: 30,
      affiliate: 12,
      coupon: 20,
      status: 'Refunded',
      refunded_id: 546,
    },
    {
      id: '125',
      product: {
        id: 'p001',
        title: 'Web 101 Fundamental...',
      },
      buyer: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      date: '2024-02-20T10:30:31.398Z',
      sale_price: 23,
      channel: 'Ads',
      platform_earning: 23,
      instructor_earning: 30,
      affiliate: 12,
      coupon: 20,
      status: 'Declined',
      refunded_id: null,
    },
    {
      id: '126',
      product: {
        id: 'p001',
        title: 'Web 101 Fundamental...',
      },
      buyer: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      date: '2024-02-20T10:30:31.398Z',
      sale_price: 23,
      channel: 'Ads',
      platform_earning: 23,
      instructor_earning: 30,
      affiliate: 12,
      coupon: 20,
      status: 'Completed',
      refunded_id: null,
    },
  ];
}
