import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import * as Course from './course'; // Single Course Components
import * as Courses from './courses'; // All Courses Components
import * as Commerce from './e-commerce'; // All E-commerce Components
import * as Price from './price-management'; // All Courses Components
import * as Settings from './settings'; // All Users Components
import * as Users from './users'; // All Users Components

// Standalone
import * as ManageUser from './users/manage-user';

// Root Pages to be move in index
// import { ECommerceComponent } from './e-commerce/e-commerce.component';
import { HomeComponent } from './home/home.component';
import { MarketingComponent } from './marketing/marketing.component';
import { OverviewComponent } from './overview/overview.component';
import { PageRoutingModule } from './page-routing.module';
import { ReportComponent } from './report/report.component';
import { SettingsComponent } from './settings/settings.component';
import { UsersComponent } from './users/users.component';
//
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CourseEffects, courseFeature } from 'thkee-common';
import { MarkPayoutAsPaidComponent } from './e-commerce/components/mark-payout-as-paid/mark-payout-as-paid.component';
import { FailedPayoutDetailsComponent } from './e-commerce/payouts/failed-payout/failed-payout-details/failed-payout-details.component';
import { FailedPayoutComponent } from './e-commerce/payouts/failed-payout/failed-payout.component';
import { InstructorBalanceRequestsComponent } from './e-commerce/payouts/instructor-balance/instructor-balance-requests/instructor-balance-requests.component';
import { InstructorBalanceTransactionsComponent } from './e-commerce/payouts/instructor-balance/instructor-balance-transactions/instructor-balance-transactions.component';
import { PayoutRequestHistoryComponent } from './e-commerce/payouts/paid-payout/paid-payout-all/components/payout-request-history/payout-request-history.component';
import { PayoutsConfirmationInformationComponent } from './e-commerce/payouts/payout-request/components/payouts-confirmation-information/payouts-confirmation-information.component';
import { ReadyRequestDetailComponent } from './e-commerce/ready-requests/components/ready-request-detail/ready-request-detail.component';
import { ReadyRequestsComponent } from './e-commerce/ready-requests/ready-requests.component';
import { TransactionsComponent } from './e-commerce/ready-requests/transactions/transactions.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CourseCategoriesComponent } from './settings/course-categories/course-categories.component';
import { CourseTopicsComponent } from './settings/course-topics/course-topics.component';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    PageRoutingModule,
    ...ManageUser.Components,
    StoreModule.forFeature(courseFeature),
    EffectsModule.forFeature([CourseEffects]),
  ],
  declarations: [
    HomeComponent,
    OverviewComponent,

    MarketingComponent,
    // PriceManagementComponent,
    ReportComponent,
    UsersComponent,
    SettingsComponent,
    PageNotFoundComponent,
    ...Course.Components,
    ...Courses.Components,
    ...Price.Components,
    ...Users.Components,
    ...Commerce.Components,
    ...Settings.Components,
    FailedPayoutComponent,
    FailedPayoutDetailsComponent,
    CourseCategoriesComponent,
    CourseTopicsComponent,
    ReadyRequestsComponent,
    TransactionsComponent,
    ReadyRequestDetailComponent,
    InstructorBalanceRequestsComponent,
    InstructorBalanceTransactionsComponent,
    PayoutRequestHistoryComponent,
    PayoutsConfirmationInformationComponent,
    MarkPayoutAsPaidComponent,
  ],
})
export class PageModule {}
