<div class="price-tiers p-4">
  <div class="page-heading mb-4 flex items-center justify-between">
    <div class="flex gap-4">
      <h3>{{ title }}</h3>
      <a routerLink="./new">
        <button class="btn-blue">
          <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon>
          <span>Add Pricing Tiers</span>
        </button>
      </a>
    </div>
    <div class="actions flex gap-4">
      <!-- TODO: Convert to formly -->
      <div class="relative">
        <button (click)="dropdown(1)" class="btn-neutral">
          <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
          <span>Filter</span>
        </button>

        <div
          [ngClass]="isDropdown[1] ? '' : 'hidden'"
          class="absolute left-0 z-10 max-h-80 w-[226] overflow-y-auto rounded border border-neutral-100 bg-white px-2.5 py-5 shadow-lg"
        >
          <div class="flex flex-col">
            <div class="flex flex-col">
              <div class="border-b border-neutral-100 pb-2.5 text-base font-semibold text-neutral-800">Channel</div>
              <app-filter-option
                #filterChannel
                key="price_tier_status"
                [options]="filterOptions.price_tier_status"
                [defaultValue]="filterValues.price_tier_status"
                (filterEvent)="filterHandler($event)"
              >
              </app-filter-option>
            </div>
            <div class="flex gap-2.5 border-t border-t-neutral-100 pt-4">
              <button
                (click)="clearFilter([filterChannel])"
                type="button"
                class="btn btn-outline-dark border-neutral-600"
              >
                <span>Reset</span>
              </button>
              <button (click)="submitFilter([filterChannel])" class="btn btn-dark bg-neutral-600 text-white">
                <span>Filter</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="input-combine flex">
        <div class="select2">
          <div class="relative">
            <a
              type="button"
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
              class="btn-neutral rounded-r-none"
            >
              <div class="selected-item truncate">Pricing Tier ID</div>
              <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
            </a>
            <ul
              tabindex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
              class="select-items hidden"
              ng-reflect-ng-class="hidden"
            >
              <li id="listbox-option-0" role="option" class="item">Item 1</li>
              <li id="listbox-option-0" role="option" class="item">Item 2</li>
              <li id="listbox-option-0" role="option" class="item">Item 3</li>
            </ul>
          </div>
        </div>
        <div class="input-group">
          <div class="group !rounded-l-none">
            <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
            <input placeholder="Enter your full name" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tier-list">
    <app-data-table title="Pricing Tiers List" badge="{{ this.pricingTierList.length }} Tiers">
      <ng-container table>
        <table class="w-full text-left">
          <thead class="heading">
            <tr>
              <th scope="col">
                <div class="col-name">ID</div>
              </th>
              <th scope="col" class="">
                <div class="col-name">Name</div>
              </th>
              <th scope="col" class="">
                <div class="sortable">
                  <div class="col-name">Currency</div>
                </div>
              </th>
              <th scope="col" class="w-24">
                <div class="col-name">Amount</div>
              </th>
              <th scope="col" class="w-24">
                <div class="col-name">Pricing Tier Status</div>
              </th>
              <th scope="col" class="w-20">
                <div class="col-name">Option</div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr class="" class="text-left" *ngFor="let pricingData of this.pricingTierList">
              <td class="">
                <span class="course-id">{{ pricingData.id }}</span>
              </td>
              <td>
                <div class="flex max-w-[170px] items-center">
                  <a routerLink="./{{ pricingData.id }}" class="course-name text-blue-600 underline">{{
                    pricingData.name
                  }}</a>
                </div>
              </td>
              <td>
                <span class="course-price">{{ pricingData.currency_code }}</span>
              </td>
              <td>
                <span class="course-sold">{{ pricingData.price }}</span>
              </td>
              <td>
                <div *ngIf="pricingData.price_tier_status" class="tag-green tag-sm">Active</div>
                <div *ngIf="!pricingData.price_tier_status" class="tag-red tag-sm">Inactive</div>
              </td>
              <td>
                <div class="flex justify-center gap-2">
                  <a routerLink="./{{ pricingData.id }}/edit">
                    <button class="btn-amber-soft btn-sm px-2">
                      <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon h-4 w-4"></svg-icon>
                    </button>
                  </a>
                  <button class="btn-outline-red btn-sm px-2" (click)="deleteConfirmation(pricingData)">
                    <svg-icon src="@assets/images/icons/trash.svg" class="btn-icon h-4 w-4"></svg-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </app-data-table>
    <!-- Pagination -->
    <div class="relative z-30">
      <app-common-pagination (paginatorData)="paginat($event)" [type]="'dynamic'"></app-common-pagination>
    </div>
  </div>
</div>
