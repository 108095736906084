import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Datepicker, DatepickerOptions } from 'flowbite';
import * as moment from 'moment';
import { MAX_Z_INDEX, registerElementAttributesChange } from '../../utils/dom';
@Component({
  selector: 'thk-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatepickerComponent)
  }]
})
export class DatepickerComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy {
  @Input() placeholder?: string;
  @Input() format?: string;

  @ViewChild('datepicker') private datepickerRef!: ElementRef;
  @Output() pickerShow = new EventEmitter<void>();
  @Output() pickerHide = new EventEmitter<void>();

  isDisabled = false;

  private datepicker!: Datepicker;
  private onChange = (date?: string) => {}
  model?: string;
  private isShowing = false;
  private get pickerElement() {
    const datePickerInstance = this.datepicker._datepickerInstance as any;
    return datePickerInstance.picker.element as HTMLElement;
  }

  constructor() { }

  writeValue(obj: string): void {
    this.model = this.formatDate(obj);
    if (this.datepicker) {
      obj && this.datepicker.setDate(moment(obj).format('L'));
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const options = {
      defaultDatepickerId: null,
      autohide: true,
      format: 'mm/dd/yyyy',
      maxDate: null,
      minDate: null,
      orientation: 'bottom',
      buttons: false,
      autoSelectToday: 0,
      title: null,
      rangePicker: false,
      onShow: (e: any) => {
        /**
         * currently, these onshow and onhide will not trigger due to issue from library,
         * we will need to workaround like this bellow solution
         * const a = this.datepicker._datepickerInstance as any;
         * const pickerEle = a.picker.element;
         * then observe this pickerEle by class attribute change to detect the visibility of the picker
         *
         */
        this.pickerShow.next();
      },
      onHide: (e: any) => {
        this.pickerHide.next();
      },
    } as DatepickerOptions;
    this.datepicker = new Datepicker(this.datepickerRef.nativeElement, options);
    this.pickerElement.style.zIndex = MAX_Z_INDEX + '';

    registerElementAttributesChange(this.pickerElement, ['class'], (_attr, newValue, _oldValue) => {
      this.isShowing = !newValue.includes('hidden');
    })
  }

  togglePicker() {
    this.isShowing ? this.close() : this.open();
  }

  open() {
    if (!this.isShowing) {
      this.datepicker.show();
      this.isShowing = true;
    }
  }

  close() {
    if (this.isShowing) {
      this.datepicker.hide();
      this.isShowing = false;
    }
  }

  changeDate(event: any) {
    this.model = this.formatDate(event.detail.date);
    this.onChange(this.model);
  }

  ngOnDestroy(): void {
    if (this.datepicker) {
      this.datepicker.destroy();
    }
  }

  private formatDate(data: string) {
    if (!data) {
      return;
    }
    return moment(data).format(this.format || 'MM/DD/YYYY');
  }
}
