import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@shared/store';

import { CredentialsService, Logger } from 'thkee-common';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private readonly store: Store<AppState>
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.credentialsService.isLogged()) {
      return true;
    }

    log.debug('Not authenticated, redirecting and adding redirect url...');
    this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
    return false;
  }
  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   return this.store.select(selectIsAuthenticated).pipe(
  //     skipUndefined(),
  //     take(1),
  //     map((authenticated) => {
  //       if (authenticated) {
  //         return true;
  //       }
  //       log.debug('Not authenticated, redirecting and adding redirect url...');
  //       this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
  //       return false;
  //     })
  //   );
  // }
}
