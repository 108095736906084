import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Editor } from '@milkdown/core';
import { NgMilkdown, NgMilkdownProvider } from 'ng-milkdown';
// import {prism} from "@milkdown/plugin-prism";

import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { slashFactory } from '@milkdown/plugin-slash';
import { tooltipFactory } from '@milkdown/plugin-tooltip';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoaderService } from 'thkee-common';
// import { BlockComponent } from '../block/block.component';
// import { ListItemComponent } from '../list-item/list-item.component';
// import { SlashComponent } from '../slash/slash.component';
// import { SpinnerComponent } from '../spinner.component';
// import { tableTooltip, TableTooltipComponent, tableTooltipCtx } from '../table-selector/table-tooltip.component';
// import { tableSelectorPlugin } from '../table-selector/tableSelectorPlugin';
// import { TooltipComponent } from '../tooltip/tooltip.component';
// import { TopBarComponent } from '../top-bar/top-bar.component';

export const slash = slashFactory('slashMenu');
export const tooltip = tooltipFactory('tooltipMenu');
export interface EditorTopBarItemsConfig {
  undo?: boolean;
  redo?: boolean;
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  bold?: boolean;
  italic?: boolean;
  strikethrough?: boolean;
  table?: boolean;
  divider?: boolean;
  bulletList?: boolean;
  orderedList?: boolean;
  codeBlock?: boolean;
  quoteBlock?: boolean;
  imageUpload?: boolean;
}
@Component({
  selector: 'app-main-editor',
  standalone: true,
  imports: [NgMilkdown, NgMilkdownProvider, FormsModule, AngularSvgIconModule, NgClass],
  templateUrl: './main-editor.component.html',
  styleUrl: './main-editor.component.scss',
})
export class MainEditorComponent {
  @Input() isTopBard: boolean = true;
  @Input() isActiveSlash: boolean = true;
  @Input() isActiveBlock: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() value = `Type here...`; // For dynamic data from BE
  @Input() staticValue = 'a'; // For image block test
  @Output() editorValue: EventEmitter<any> = new EventEmitter();

  @ViewChild(NgMilkdownProvider, { static: true }) provider!: NgMilkdownProvider;

  @Input() topBarItemsConfig!: EditorTopBarItemsConfig;
  @Input() isActiveTooltip: boolean = false;

  editor!: Editor;
  loading = true;
  isLoadingDetails$ = this.loaderService.response('file-upload-test');

  constructor(private loaderService: LoaderService) {}

  // plugins: NgMilkdownPlugin[] = [
  //   gfm,
  //   history,
  //   // prism,
  //   clipboard,
  //   cursor,
  //   // math,
  //   // emoji,
  //   // [
  //   //   diagram, // diagram plugin
  //   //   $view(diagramSchema.node, () =>
  //   //     this.provider.createNodeView({ // create node view for diagram node
  //   //       component: Diagram,
  //   //       stopEvent: () => true,
  //   //     })
  //   //   )
  //   // ],
  //   commonmark,
  //   // upload,
  //   $view(
  //     listItemSchema.node,
  //     () => this.provider.createNodeView({ component: ListItemComponent }) // create node view for list item node
  //   ),
  //   {
  //     plugin: imageBlockComponent,
  //     config: (ctx) => {
  //       // Inject imageBlockConfigCtx properly here
  //       ctx.set(imageBlockConfig.key, {
  //         imageIcon: () => '🖼️',
  //         uploadButton: () => html`<span>Upload Image</span>`,
  //         uploadPlaceholderText: 'or paste an image URL',
  //         onUpload: async (file: File) => {
  //           // const url = await YourUploadAPI(file);
  //           // return url;
  //           console.log('🚀 ~ MainEditorComponent ~ onUpload: ~ file:', file);
  //           return 'https://d2zyq1d2fqk65y.cloudfront.net/user=15/content_type=images/library=cccb0fa2-cf25-44a3-aefd-1803f57140cd/apihost=https://d144-2401-4900-1c1b-98b4-98f3-7fbf-9c7d-38e8.ngrok-free.app/course-img.png';
  //         },
  //         captionIcon: () => '📝',
  //         confirmButton: () => html`<span>Confirm</span>`,
  //         captionPlaceholderText: 'Add a caption',
  //       });
  //     },
  //   },
  //   {
  //     plugin: block,
  //     config: (ctx) => {
  //       if (this.isActiveBlock) {
  //         ctx.set(block.key, {
  //           view: this.provider.createPluginView({
  //             // create plugin view for block plugin
  //             component: BlockComponent,
  //             inputs: { ctx },
  //           }),
  //         });
  //       }
  //     },
  //   },
  //   // $provide(linkPlugin), // $provide is an alias of `provider => MilkdownPlugin`, allow you create your own plugin without waiting for `provider` initialization
  //   {
  //     plugin: indent,
  //     config: (ctx) => {
  //       ctx.set(indentConfig.key as any, {
  //         // set indent config
  //         type: 'space',
  //         size: 40,
  //       });
  //     },
  //   },
  //   {
  //     plugin: tooltip,
  //     config: (ctx) => {
  //       ctx.set(tooltip.key, {
  //         view: this.provider.createPluginView({ component: TooltipComponent }), // create plugin view for tooltip plugin
  //       });
  //     },
  //   },
  //   tableTooltip,
  //   {
  //     plugin: tableTooltipCtx,
  //     config: (ctx) => {
  //       ctx.set(tableTooltip.key, {
  //         view: this.provider.createPluginView({
  //           component: TableTooltipComponent,
  //         }),
  //       });
  //     },
  //   },
  //   $provide(tableSelectorPlugin),
  //   {
  //     plugin: slash,
  //     config: (ctx) => {
  //       if (this.isActiveSlash) {
  //         ctx.set(slash.key, {
  //           view: this.provider.createPluginView({ component: SlashComponent }), // create plugin view for slash plugin
  //         });
  //       }
  //     },
  //   },
  // ];

  // config = (ctx: any) => {
  //   ctx.set(editorViewOptionsCtx, {
  //     attributes: {
  //       class: 'prose outline-none mx-auto px-2 py-4 box-border milkdown-theme-nord editor',
  //       spellcheck: 'false',
  //     },
  //   });

  //   ctx.update(imageBlockConfig.key, (defaultConfig: any) => ({
  //     ...defaultConfig,
  //     imageIcon: () => '🖼️',
  //     uploadButton: () => html`<span>Upload Image</span>`,
  //     uploadPlaceholderText: 'or paste an image URL',
  //     onUpload: async (file: File) => {
  //       // const url = await YourUploadAPI(file);
  //       // return url;
  //       return 'https://d2zyq1d2fqk65y.cloudfront.net/user=15/content_type=images/library=cccb0fa2-cf25-44a3-aefd-1803f57140cd/apihost=https://d144-2401-4900-1c1b-98b4-98f3-7fbf-9c7d-38e8.ngrok-free.app/course-img.png';
  //     },
  //   }));

  //   ctx.update(editorViewOptionsCtx, (prev: any) => ({
  //     ...prev,
  //     editable: () => !this.isDisabled,
  //   }));

  //   ctx.set(blockquoteAttr.key, () => ({
  //     class: 'border-l-4 border-slate-600 pl-4',
  //   }));

  //   ctx.set(inlineCodeAttr.key, () => ({
  //     class: 'font-mono text-slate-600 tracking-tight',
  //   }));

  //   // ctx.set(emojiAttr.key, () => ({
  //   //   container: {},
  //   //   img: {
  //   //     class: "w-[1em] h-[1em] inline align-text-top",
  //   //   },
  //   // }));

  //   nord(ctx);
  // };

  // ngAfterViewInit() {
  //   setTimeout(() => {
  //     console.log(this.editor);
  //   }, 1000);
  //   // this.action((ctx) => {
  //   //   ctx.set(tableTooltipCtx.key, this.provider);
  //   // });
  // }

  // onChange(markdownText: any) {
  //   this.editorValue.emit(markdownText);
  // }

  // // Initialize the image block
  // // NOTE: After adding the "plugin: imageBlockComponent," I can not see initially any image block but after adding default value
  // //       like "@Input() value = `![]()`" then I can see the image block in my editor
  // // NOTE: Right now I can see once I am clicking the initialize image block, the image block is going last of the editor
  // // TODO 1: Need to initialize image block where is my mouse cursor pointer and multiple block should be created by clicking the button as much as I want
  // // TODO 2: Need to create custom block like: plugin: tableTooltipCtx, plugin: slash, etc.
  // // this.staticValue = `![]()`;

  // // ctx.update(imageBlockConfig.key, (defaultConfig) => ({
  // //   ...defaultConfig,
  // // }));

  // topBarAction(event: string) {
  //   if (event === 'imageBlock') {
  //     this.value = `![]()`;
  //     return;
  //     if (this.editor) {
  //       this.editor.action((ctx) => {
  //         const commandManager = ctx.get(commandsCtx);
  //         const editorView = ctx.get(editorViewCtx);
  //         // Focus the editor and insert the image block at the cursor position
  //         editorView.focus();
  //         commandManager.call(imageBlockConfig.key as any);
  //       });
  //     } else {
  //       console.error('Editor is not initialized.');
  //     }
  //   }
  // }
}
