import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import {
  AssignmentQuestionV2,
  AssignmentV2,
  CourseDataV2,
  CoursePrice,
  CourseV2,
  Currency,
  IntendedLearnerTypes,
  LectureResourceV2,
  LectureV2,
  Pagination,
  QuizAnswerV2,
  QuizQuestionV2,
  QuizV2,
  RelatedLecture,
  SectionPosition,
  SectionV2,
  SubsectionPosition,
  SubsectionV2,
  VideoLibrary,
  VideoLibraryCourse,
} from '../../models';
import {
  API_CONSTANTS_COURSE_PRICING,
  API_CONSTANTS_CURRENCIES,
  API_COURSES_ADMIN,
  API_COURSES_ADMIN_DATA_GET,
  API_COURSES_ASSIGNMENT_QUESTION_DELETE,
  API_COURSES_ASSIGNMENT_QUESTION_UPSERT,
  API_COURSES_ASSIGNMENT_UPSERT,
  API_COURSES_DATA_GET,
  API_COURSES_GET,
  API_COURSES_INSTRUCTOR,
  API_COURSES_LECTURE_RESOURCE_DELETE,
  API_COURSES_LECTURE_RESOURCE_UPSERT,
  API_COURSES_LECTURE_UPSERT,
  API_COURSES_PRICING,
  API_COURSES_QUIZ_ANSWER_DELETE,
  API_COURSES_QUIZ_ANSWER_UPSERT,
  API_COURSES_QUIZ_QUESTION_DELETE,
  API_COURSES_QUIZ_QUESTION_UPSERT,
  API_COURSES_QUIZ_UPSERT,
  API_COURSES_SECTION_DELETE,
  API_COURSES_SECTION_GET,
  API_COURSES_SECTION_UPSERT,
  API_COURSES_SUBSECTION_DELETE,
  API_COURSES_SUBSECTION_GET,
  API_COURSES_SUBSECTION_UPSERT,
  API_COURSES_UPDATE,
  API_GET_COURSE_REFERRAL_CODE,
  API_GET_IN_COURSE_TRANSCODED_INFO,
  API_GET_IN_LECTURE_ROLLBACK_INFO,
  API_GET_IN_LECTURE_TRANSCODED_INFO,
  API_GET_IN_LIBRARY_TRANSCODED_INFO,
  API_GET_INSTRUCTOR_COURSES_RELATED_SECTION_QUIZ,
  API_GET_VIDEO_LIBRARY,
  API_GET_VIDEO_LIBRARY_COURSE_AND_SECTION,
  API_POST_COURSES_SECTION_POSITION_UPDATE,
  API_POST_COURSES_SUBSECTION_POSITION_UPDATE,
  COURSE_INTENDED_CREATE,
  COURSE_INTENDED_GET,
  COURSE_INTENDED_UPDATE,
} from '../../models/constants';
import { generateUuid } from '../../store';
import { encodeURL } from '../../utils';
import { HttpService } from '../http';
import { Logger } from '../logger';
import { PartialEntity } from './../../models/utils.model';

const log = new Logger('InstructorService');

@Injectable({
  providedIn: 'root',
})
export class InstructorService {
  constructor(private readonly http: HttpService) {}

  getCourseData(courseId: string, params = {} as object): Observable<CourseDataV2> {
    const variable = environment.isAdmin ? encodeURL(API_COURSES_ADMIN_DATA_GET, params) : API_COURSES_DATA_GET;
    return this.http.get<CourseDataV2>(variable.replace('<COURSE_ID>', courseId));
  }

  getCourses(params = {} as object): Observable<Pagination<CourseV2>> {
    const variable = environment.isAdmin ? API_COURSES_ADMIN : API_COURSES_INSTRUCTOR;
    return this.http.get<Pagination<CourseV2>>(encodeURL(variable + '/', params));
  }

  getCourse(courseId: string): Observable<CourseV2> {
    return this.http.get<CourseV2>(API_COURSES_GET.replace('<COURSE_ID>', courseId));
  }

  createCourse(data: any): Observable<CourseV2> {
    return this.http.post(API_COURSES_INSTRUCTOR + '/', data);
  }

  updateCourse(courseId: string, data: CourseV2): Observable<CourseV2> {
    // Fixed data source from object to id string
    if (typeof data.source === 'object') {
      data.source = data.source?.id;
    }
    return this.http.put(API_COURSES_UPDATE.replace('<COURSE_ID>', courseId), data);
  }

  patchCourse(courseId: string, data: Partial<CourseV2>): Observable<CourseV2> {
    data.currency_preview = data.currency;
    return this.http.patch(API_COURSES_UPDATE.replace('<COURSE_ID>', courseId), data);
  }

  getCourseIntended(courseId: string, type: string): Observable<any> {
    // *TEMP replace any with proper interface
    const url = COURSE_INTENDED_GET.replace('<COURSE_ID>', courseId).replace('<TYPE>', String(type));
    return this.http.get<any>(url); // *TEMP replace any with proper interface
  }

  createCourseIntended(courseId: string, type: string, payload: any): Observable<any> {
    // *TEMP replace any with proper interface
    payload = { course: courseId, ...payload, id: payload.id ?? generateUuid() };
    const url = COURSE_INTENDED_CREATE.replace('<COURSE_ID>', courseId).replace('<TYPE>', String(type));
    return this.http.post(url + '/', payload);
  }

  updateCourseIntended(courseId: string, id: string, type: IntendedLearnerTypes, payload: any): Observable<any> {
    // *TEMP replace any with proper interface
    payload = { course: courseId, ...payload };
    const url = COURSE_INTENDED_UPDATE.replace('<COURSE_ID>', courseId).replace('<TYPE>', type).replace('<ID>', id);
    return this.http.put(url + '/', payload);
  }

  deleteCourseIntended(courseId: string, id: string, type: string): Observable<any> {
    // *TEMP replace any with proper interface
    const url = COURSE_INTENDED_UPDATE.replace('<COURSE_ID>', courseId).replace('<TYPE>', type).replace('<ID>', id);
    return this.http.delete(url + '/');
  }

  getSections(courseId: string): Observable<any> {
    return this.http.get(API_COURSES_SECTION_GET.replace('<COURSE_ID>', courseId));
  }
  upsertSection(section: PartialEntity<SectionV2>): Observable<SectionV2> {
    console.log('🚀 ~ InstructorService ~ upsertSection ~ section:', section);
    return this.http.post(API_COURSES_SECTION_UPSERT, section);
  }
  deleteSection(sectionId: string, params = {} as object): Observable<any> {
    return this.http.delete(encodeURL(API_COURSES_SECTION_DELETE, params).replace('<SECTION_ID>', sectionId));
  }

  getSubSections(courseId: string, sectionId: number): Observable<any> {
    return this.http.get(
      API_COURSES_SUBSECTION_GET.replace('<COURSE_ID>', courseId).replace('<SECTION_ID>', String(sectionId))
    );
  }
  upsertSubsection(subsection: PartialEntity<SubsectionV2>): Observable<SubsectionV2> {
    return this.http.post(API_COURSES_SUBSECTION_UPSERT, subsection);
  }
  deleteSubsection(subsectionId: string): Observable<void> {
    return this.http.delete(API_COURSES_SUBSECTION_DELETE.replace('<SUBSECTION_ID>', subsectionId));
  }

  upsertQuiz(quiz: PartialEntity<QuizV2>): Observable<QuizV2> {
    return this.http.post(API_COURSES_QUIZ_UPSERT, quiz);
  }
  upsertQuizQuestion(quizQuestion: PartialEntity<QuizQuestionV2>): Observable<QuizQuestionV2> {
    return this.http.post(API_COURSES_QUIZ_QUESTION_UPSERT, quizQuestion);
  }
  deleteQuizQuestion(quizQuestionId: string): Observable<void> {
    return this.http.delete(API_COURSES_QUIZ_QUESTION_DELETE.replace('<ID>', quizQuestionId));
  }
  upsertQuizAnswer(quizAnswer: PartialEntity<QuizAnswerV2>): Observable<QuizAnswerV2> {
    return this.http.post(API_COURSES_QUIZ_ANSWER_UPSERT, quizAnswer);
  }
  deleteQuizAnswer(quizAnswerId: string): Observable<void> {
    return this.http.delete(API_COURSES_QUIZ_ANSWER_DELETE.replace('<ID>', quizAnswerId));
  }

  upsertAssignment(assignment: PartialEntity<AssignmentV2>): Observable<AssignmentV2> {
    if (typeof assignment.source === 'object') {
      assignment.source = assignment.source?.id;
    }
    return this.http.post(API_COURSES_ASSIGNMENT_UPSERT, assignment);
  }
  upsertAssignmentQuestion(assignmentQuestion: PartialEntity<AssignmentQuestionV2>): Observable<AssignmentQuestionV2> {
    assignmentQuestion = JSON.parse(JSON.stringify(assignmentQuestion));
    if (typeof assignmentQuestion.source === 'object') {
      assignmentQuestion.source = assignmentQuestion.source?.id;
    }
    return this.http.post(API_COURSES_ASSIGNMENT_QUESTION_UPSERT, assignmentQuestion);
  }
  deleteAssignmentQuestion(assignmentQuestionId: string): Observable<void> {
    return this.http.delete(API_COURSES_ASSIGNMENT_QUESTION_DELETE.replace('<ID>', assignmentQuestionId));
  }

  upsertLecture(lecture: PartialEntity<LectureV2>): Observable<LectureV2> {
    // Fixed data source from object to id string
    if (typeof lecture.source === 'object') {
      lecture.source = lecture.source?.id;
    }

    delete lecture?.video_info;
    return this.http.post(API_COURSES_LECTURE_UPSERT, lecture);
  }
  upsertLectureResource(lectureResource: PartialEntity<LectureResourceV2>): Observable<LectureResourceV2> {
    const lecture = lectureResource.lecture || '';
    return this.http.post(API_COURSES_LECTURE_RESOURCE_UPSERT.replace('<LECTURE_PK>', lecture), lectureResource);
  }
  deleteLectureResource(lectureResourceId: string): Observable<void> {
    return this.http.delete(API_COURSES_LECTURE_RESOURCE_DELETE.replace('<ID>', lectureResourceId));
  }

  getCoursePrice(params = {} as object): Observable<Pagination<CoursePrice>> {
    return this.http.get<Pagination<CoursePrice>>(encodeURL(API_CONSTANTS_COURSE_PRICING, params));
  }

  getCurrencies(): Observable<Currency[]> {
    return this.http.get<Currency[]>(API_CONSTANTS_CURRENCIES);
  }

  getCoursePricing(courseId: string): Observable<any> {
    const url = `${API_COURSES_PRICING}?`;
    return this.http.get<any>(url); // *TEMP replace any with proper interface
  }

  getCourseReferralLink(courseId: string) {
    return this.http.get<{ unique_code: string; product: string }>(API_GET_COURSE_REFERRAL_CODE, { id: courseId });
  }

  upsertSectionPosition(sections: SectionPosition[], courseId: string): Observable<SectionPosition[]> {
    return this.http.post(API_POST_COURSES_SECTION_POSITION_UPDATE.replace('<COURSE_ID>', courseId), sections);
  }

  upsertSubsectionPosition(subsections: SubsectionPosition[], sectionId: string): Observable<SubsectionV2> {
    return this.http.post(API_POST_COURSES_SUBSECTION_POSITION_UPDATE.replace('<SECTION_ID>', sectionId), subsections);
  }

  // Video library
  getVideoLibraryContent(params = {} as object): Observable<Pagination<VideoLibrary>> {
    return this.http.get<Pagination<VideoLibrary>>(encodeURL(API_GET_VIDEO_LIBRARY, params));
  }

  getLibraryTranscodedInfo(libraryId: string): Observable<any> {
    return this.http.get<any>(API_GET_IN_LIBRARY_TRANSCODED_INFO.replace('<LIBRARY_ID>', libraryId));
  }

  getLectureTranscodedInfo(lectureId: string): Observable<any> {
    return this.http.get<any>(API_GET_IN_LECTURE_TRANSCODED_INFO.replace('<LECTURE_ID>', lectureId));
  }

  getCourseDetailsTranscodedInfo(courseId: string): Observable<any> {
    return this.http.get<any>(API_GET_IN_COURSE_TRANSCODED_INFO.replace('<COURSE_ID>', courseId));
  }

  getLectureRollbackInfo(lectureId: string): Observable<any> {
    return this.http.get<any>(API_GET_IN_LECTURE_ROLLBACK_INFO.replace('<LECTURE_ID>', lectureId));
  }

  // Quiz related section
  getRelatedSection(sectionId: string): Observable<RelatedLecture[]> {
    return this.http.get<RelatedLecture[]>(
      API_GET_INSTRUCTOR_COURSES_RELATED_SECTION_QUIZ.replace('<SECTION_ID>', sectionId)
    );
  }

  // Instructor video library
  getLibraryCourses(): Observable<VideoLibraryCourse[]> {
    return this.http.get<VideoLibraryCourse[]>(API_GET_VIDEO_LIBRARY_COURSE_AND_SECTION);
  }
}
