<div class="course-overview p-4 pt-0">
   <div class="flex items-center justify-between mb-4">
      <div class="page-heading">
         <h3 class="text-2xl font-semibold text-neutral-800">
            Failed Payouts
         </h3>
      </div>
   </div>
   <div class="flex flex-col gap-4 mb-4">
      <ng-container *ngIf="isLoadingInactivePayoutSummary; else loadingInactivePayoutSummary">
         <div class="flex justify-center items-center h-12" [thkLibProcessing]="true"></div>
      </ng-container>
      <ng-template #loadingInactivePayoutSummary>
      <div class="flex flex-row gap-4">
         <div class="relative flex flex-col gap-1 border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
            <span
               class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-blue-500"></span>
            <h5 class="text-xs font-normal text-[#666666]">Total Earnings</h5>
            <h5 class="text-lg font-bold text-[#3941FE]">{{paymentHistory.currency_symbol}} {{paymentHistory.total_failed_payout_amount}} </h5>
            <svg-icon [tooltip]="'Total Earnings'" src="@assets/images/icons/question-mark-circle.svg"
               class="btn-icon absolute right-4 text-amber-600"></svg-icon>
         </div>
         <div class="relative flex flex-col gap-1 border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
            <span
               class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-blue-500"></span>
            <h5 class="text-xs font-normal text-[#666666]">Failed Payouts</h5>
            <h5 class="text-lg font-bold text-[#3941FE]">{{paymentHistory.total_failed_payouts}} </h5>
            <svg-icon [tooltip]="'Failed Payouts'" src="@assets/images/icons/question-mark-circle.svg"
               class="btn-icon absolute right-4 text-amber-600"></svg-icon>
         </div>
         <div class="relative flex flex-col gap-1 border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
            <span
               class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-blue-500"></span>
            <h5 class="text-xs font-normal text-[#666666]">Total Transactions</h5>
            <h5 class="text-lg font-bold text-[#3941FE]">{{paymentHistory.total_transactions}} </h5>
            <svg-icon [tooltip]="'Total Transactions'" src="@assets/images/icons/question-mark-circle.svg"
               class="btn-icon absolute right-4 text-amber-600"></svg-icon>
         </div>
      </div>
      </ng-template>
   </div>
   <div class="page-heading mb-4 flex items-center justify-between">
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="dropdown('filter')" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="isDropdown['filter']?'hidden':''"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
               <div class="form">
                  <form [formGroup]="filterForm">
                     <formly-form [form]="filterForm" [fields]="filterFormFields">
                     </formly-form>
                  </form>
               </div>
               <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                  <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">
                     <span>Reset</span>
                  </button>
               </div>
            </div>
         </div>
         <!-- Search With Dropdown -->
         <app-combination-single-input (valueChanges)="search($event)" [model]="searchValue" [keys]="searchOptions"></app-combination-single-input>
      </div>
   </div>

   <div class="flex-3 w-full overflow-hidden card rounded-lg border border-neutral-100">
      <div class="card-subheader flex items-center justify-between px-6 py-5">
         <div class="flex items-center gap-1">
            <h6>May 2020</h6>
         </div>
         <div class="actions flex flex-row gap-4">
            <button class="btn bg-teal-50 text-teal-600 btn-sm border-none w-max">
               <span>Pay All</span>
            </button>
            <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
               <svg-icon src="@assets/images/icons/export-icon.svg" class="text-blue-600"></svg-icon>
               <span class="text-blue-600 font-semibold text-sm">Exprot CSV</span>
            </button>
            <button class="btn-neutral px-3">
               <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
            </button>
         </div>
      </div>
      <div class="card-body">
         <div class="courses-list">
            <!-- Table -->
            <div class="datagrid-table relative">
               <table class="w-full text-left">
                  <thead class="heading">
                     <tr>
                        <th scope="col" class="w-16">
                           <div class="col-name"></div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Period</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">No, Of Payees</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Transactions</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Reviewed</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Under Review</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Payout Amount</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">In Hold</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Status</div>
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr class="hover:cursor-pointer">
                        <td>
                           <button (click)="dropdown('t-1')" class="btn btn-tansparent btn-sm border-none w-max">
                              <ng-container *ngIf="isDropdown['t-1'] else plusCircle">
                                 <svg-icon src="@assets/images/icons/plus-circle.svg" class="text-black">
                                 </svg-icon>
                              </ng-container>
                              <ng-template #plusCircle>
                                 <svg-icon src="@assets/images/icons/minus-circle.svg" class="text-black">
                                 </svg-icon>
                              </ng-template>
                           </button>
                        </td>
                        <td> May </td>
                        <td> 10 </td>
                        <td> 55 </td>
                        <td> 8/10 </td>
                        <td> 2/10 </td>
                        <td> {{10 | currency}} </td>
                        <td> {{45 | currency}} </td>
                        <td>
                           <div class="flex flex-col px-2.5 py-[5px] rounded-2xl w-max bg-amber-100">
                              <span class="text-sm text-amber-600">Under Review</span>
                           </div>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>
            <ng-container *ngIf="!isDropdown['t-1']">
               <div class="datagrid-table relative overflow-x-auto">
                  <table class="w-full min-w-[1250px] text-left">
                     <thead class="heading">
                        <tr>
                           <th scope="col" class="">
                              <div class="col-name">Instructor</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Payout Id</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Period</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Payout Amount</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Transactions</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Payout Method</div>
                           </th>
                           <th scope="col" class="">
                              <div class="col-name">Status</div>
                           </th>
                           <th scope="col" class="text-end">
                              <div class="col-name">Action</div>
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr *ngIf="payoutsPagination && !payoutsPagination.count && !filtering">
                           <td colspan="8" class="text-center">No Payouts Found</td>
                        </tr>
                        <tr class="hover:cursor-pointer" *ngFor="let data of payoutsPagination?.results; let i = index">
                           <td>
                              <span (click)="instructorDrawer.open()" class="course-id">
                                 <span class="text-blue-600 cursor-pointer underline">
                                    {{ data.instructor.fullname }}
                                 </span>
                              </span>
                           </td>
                           <td>
                              <span [routerLink]="'/ecommerce/payout/'+data.id+'/failed-payouts'" class="course-id">
                                 <span class="text-blue-600 cursor-pointer">
                                    #{{ data.id }}
                                 </span>
                              </span>
                           </td>
                           <td>
                              <span class="course-id">
                                 <span class="">
                                    {{ data.period | date:'MMMM y' }}
                                 </span>
                              </span>
                           </td>
                           <td>
                              {{data.amount | currency:data.currency_symbol}}
                           </td>
                           <td>
                              <span class="course-price flex items-center">
                                 {{ data.no_of_transactions }}
                              </span>
                           </td>
                           <td class="max-w-[170px]">
                              <span class="">
                                 {{ data.payment_method }}
                              </span>
                           </td>
                           <td>
                              <div [ngClass]="getStatus(data.status).bg"
                                 class="flex flex-col px-2.5 py-[5px] rounded-2xl w-max">
                                 <span [ngClass]="getStatus(data.status).text" class="text-sm">{{data.status}}</span>
                              </div>
                           </td>
                           <td>
                              <div class="flex justify-end gap-3">
                                 <button (click)="openConfirmationModal(data, retryConfirmModal)"
                                    class="btn bg-teal-50 text-teal-600 btn-sm border-none w-max">
                                    <span>Retry Paid</span>
                                 </button>
                                 <button (click)="openConfirmationModal(data, markeAsPaidConfirmModal)"
                                    class="btn bg-teal-600 text-white btn-sm">
                                    <span>Mark as Paid</span>
                                 </button>
                              </div>
                           </td>
                        </tr>
                        <tr *ngIf="filtering">
                           <td colspan="8" class="text-center">
                              <div class="flex justify-center" [thkLibProcessing]="true"></div>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>

               <thk-pagination *ngIf="payoutsPagination && payoutsPagination.count" (paginationChange)="paginate($event)" [sizeChangable]="true" [total]="payoutsPagination.count" [size]="query?.page_size || 10" [page]="query?.page"></thk-pagination>
            </ng-container>
         </div>
      </div>
   </div>
</div>

<!-- Marke as Paid confirm modal -->
<common-modal #markeAsPaidConfirmModal customClass="sm:w-full sm:max-w-[788px] rounded-none" title="Mark as Paid">
   <ng-container content>
      <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-4 w-full">
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Instructor</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The name of the instructor associated with the course purchased." class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Omar Sherif ( <span (click)="instructorDrawer.open()" class="text-blue-600">#12568</span> )
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Period</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The day of the week, day of the month, and time (your local time) at which the transaction took place."
                     class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  May 2020
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Payment Gateway</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="Filter if the transaction was made via credit card or PayPal." class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Paypal
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Payout Amount</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Payout  Amount" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  $320
               </h6>
            </div>
         </div>
      </div>

      <div class="card-subheader flex flex-col items-center justify-center px-6 py-5 border-b border-b-neutral-100">
         <h3 class="text-neutral-800 text-base font-normal">Are you sure to mark this payout <b>Paid?</b></h3>
      </div>

      <div class="card-subheader flex flex-col items-center justify-center px-6 py-5 border-b border-b-neutral-100">
         <div class="form w-full">
            <formly-form [form]="markedAsPaidForm" [fields]="markedAsPaidFormFields">
            </formly-form>
         </div>
      </div>
   </ng-container>
   <ng-container footer>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
         <div class="flex gap-4">
            <button (click)="markeAsPaidConfirmModal.close()" class="btn bg-neutral-100 border-none">
               <span class="text-neutral-900 text-sm font-semibold">Cancel</span>
            </button>
            <button (click)="markAsPaid(markeAsPaidConfirmModal)" class="btn bg-teal-600 border-none">
               <span class="text-white text-sm font-semibold">Confirm</span>
            </button>
         </div>
      </div>
   </ng-container>
</common-modal>

<!-- Active confirm modal -->
<common-modal #retryConfirmModal customClass="sm:w-full sm:max-w-[788px] rounded-none" title="Retry Payout Confirmation">
   <ng-container content>
      <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-4 w-full">
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Instructor</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The name of the instructor associated with the course purchased." class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Omar Sherif ( <span (click)="instructorDrawer.open()"
                     class="text-blue-600 cursor-pointer">#12568</span> )
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Period</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The day of the week, day of the month, and time (your local time) at which the transaction took place."
                     class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Oktober
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Payment Gateway</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="Filter if the transaction was made via credit card or PayPal." class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Paypal
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Payout Amount</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Payout  Amount" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  $320
               </h6>
            </div>
         </div>
      </div>

      <div class="card-subheader flex flex-col items-center justify-center px-6 py-5 border-b border-b-neutral-100">
         <h3 class="text-neutral-800 text-base font-normal text-center">
            Are you sure to retry payment again and <br>
            will proceed to Upcoming Payments in <b>July 2022?</b>
         </h3>
      </div>

      <div class="card-subheader flex flex-col items-center justify-center px-6 py-5 border-b border-b-neutral-100">
         <div class="form w-full">
            <formly-form [form]="markedAsPaidForm" [fields]="markedAsPaidFormFields">
            </formly-form>
         </div>
      </div>
   </ng-container>
   <ng-container footer>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
         <div class="flex gap-4">
            <button (click)="retryConfirmModal.close()" class="btn bg-neutral-100 border-none">
               <span class="text-neutral-900 text-sm font-semibold">Cancel</span>
            </button>
            <button (click)="retryPayout(retryConfirmModal)" class="btn bg-teal-600 border-none">
               <span class="text-white text-sm font-semibold">Confirm</span>
            </button>
         </div>
      </div>
   </ng-container>
</common-modal>

<!-- Buyer drawer -->
<app-drawer #instructorDrawer>
   <!-- Header -->
   <ng-container header>
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center border-b border-b-neutral-100">
         <h3 class="text-xl text-black font-semibold">Faris Friansyah</h3>
         <div class="flex justify-between items-center gap-3">
            <button
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to Instructor detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="instructorDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>

   <ng-container content>
      <!-- Summery -->
      <div class="flex flex-row p-6 border-b border-b-neutral-100">
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">ID Instructor</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">#325</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Courses</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">25</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Total Income</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">$500</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Join Date</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">Nov 08, 2022, 2:03 PM</h3>
         </div>
      </div>

      <!-- Tab -->
      <div class="flex gap-2.5 flex-row p-6 border-b border-b-neutral-100 flex-wrap">
         <ng-container *ngFor="let tab of instructorTabLists">
            <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border">
               {{tab.displayName}}
            </button>
         </ng-container>
      </div>

      <!-- Details -->
      <div class="flex flex-col p-6 pb-0">
         <!-- Settings Tab -->
         <div class="section-label block my-3 w-full">
            <h5 class="text-2xl font-semibold text-black">Settings</h5>
         </div>
         <div class="flex gap-2.5 flex-row py-6 border-b border-b-neutral-100 flex-wrap">
            <ng-container *ngFor="let tab of instructorSettingsTabLists">
               <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border border-none">
                  {{tab.displayName}}
               </button>
            </ng-container>
         </div>
         <!-- Profile -->
         <app-student-profile [account_type]="'instructor'"></app-student-profile>
      </div>
   </ng-container>
</app-drawer>
