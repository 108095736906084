<div class="course-overview p-4 pt-0">
   <div class="flex items-center justify-between mb-4">
      <div class="page-heading">
         <h3 class="text-2xl font-semibold text-neutral-800">
            Inactive Payout for Fatima Henny (#123)
         </h3>
      </div>
   </div>
   <div class="flex flex-col gap-4 mb-4">
      <div class="flex flex-row gap-4">
         <div
            class="relative flex flex-col gap-1 justify-center items-center border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
            <span class="flex gap-3">
               <h5 class="text-xs font-normal text-[#666666]">Payout Period</h5>
               <svg-icon [tooltip]="" src="@assets/images/icons/question-mark-circle.svg" class="btn-icon text-black">
               </svg-icon>
            </span>
            <h5 class="text-3xl font-semibold text-neutral-900">
               July 2020
            </h5>
         </div>
      </div>
   </div>

   <div class="flex flex-row gap-4 mb-4">
      <div class="flex flex-1 w-full border border-[#E5E5E5] rounded-lg p-4 gap-8">
         <div class="w-full">
            <div class="page-heading border-b-2 border-b-neutral-100 pb-4 mb-4">
               <h3 class="text-base font-semibold text-neutral-800">
                  Total Earning Share
               </h3>
            </div>
            <div>
               <app-common-chart [chartStyle]="'pieChart'"></app-common-chart>
            </div>
         </div>
         <div class="w-full">
            <div class="page-heading border-b-2 border-b-neutral-100 pb-4 mb-4">
               <h3 class="text-base font-semibold text-neutral-800">
                  Share Details
               </h3>
            </div>
            <div class="gap-4 grid grid-cols-2">
               <div *ngFor="let payment of paymentHistory"
                  class="relative flex flex-col gap-1 border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
                  <span
                     class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-teal-600"></span>
                  <h5 class="text-xs font-normal text-[#666666]">{{payment.title}}</h5>
                  <h5 *ngIf="payment.type=== 'currency'" class="text-lg font-bold text-teal-600">
                     {{payment.value | currency}}
                  </h5>
                  <svg-icon [tooltip]="payment.tooltip" src="@assets/images/icons/question-mark-circle.svg"
                     class="btn-icon absolute right-4 text-amber-600"></svg-icon>
               </div>
            </div>
         </div>
      </div>
      <div class="w-96">
         <h3 class="text-2xl text-neutral-900 font-semibold p-4">Summary</h3>
         <div *ngIf="payout" class="gap-4 grid grid-cols-2">
            <div class="relative flex flex-col gap-1 p-4 bg-white w-full">
               <span
                  class="absolute left-0 top-0 w-[5px] h-full rounded-tr-[50px] rounded-br-[50px] bg-teal-400"></span>
               <h5 class="text-xs font-normal text-neutral-400">Total Transactions</h5>
               <h5 class="text-lg font-bold text-neutral-900">{{payout.no_of_transactions}}</h5>
            </div>
            <div class="relative flex flex-col gap-1 p-4 bg-white w-full">
               <span
                  class="absolute left-0 top-0 w-[5px] h-full rounded-tr-[50px] rounded-br-[50px] bg-amber-600"></span>
               <h5 class="text-xs font-normal text-neutral-400">Total Bank Transfer</h5>
               <h5 class="text-lg font-bold text-neutral-900">{{payout.requested_amount | currency:payout.currency_symbol}}</h5>
            </div>
            <div class="relative flex flex-col gap-1 p-4 bg-white w-full">
               <span
                  class="absolute left-0 top-0 w-[5px] h-full rounded-tr-[50px] rounded-br-[50px] bg-blue-600"></span>
               <h5 class="text-xs font-normal text-neutral-400">Total Paypal Payment</h5>
               <h5 class="text-lg font-bold text-neutral-900">$31,024</h5>
            </div>
            <div class="relative flex flex-col gap-1 p-4 bg-white w-full">
               <span
                  class="absolute left-0 top-0 w-[5px] h-full rounded-tr-[50px] rounded-br-[50px] bg-teal-600"></span>
               <h5 class="text-xs font-normal text-neutral-400">Total Payoneer</h5>
               <h5 class="text-lg font-bold text-neutral-900">$32,024</h5>
            </div>
         </div>
      </div>
   </div>

   <div class="page-heading mb-4 flex items-center justify-between">
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="dropdown('filter')" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="isDropdown['filter']?'hidden':''"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
               <div class="form">
                  <formly-form [form]="filterForm" [fields]="filterFormFields">
                  </formly-form>
               </div>
               <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                  <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">
                     <span>Reset</span>
                  </button>
               </div>
            </div>
         </div>
         <thk-date-range-picker ngModel (ngModelChange)="filterTransByDate($event)" placeholder="Select range date" format="YYYY-MM-DD"></thk-date-range-picker>
         <!-- Search With Dropdown -->
         <app-combination-single-input [keys]="searchOptions" (valueChanges)="search($event)" [model]="searchValue"></app-combination-single-input>
      </div>
   </div>

   <div class="flex-3 w-full overflow-hidden card rounded-lg border border-neutral-100">
      <div class="card-subheader flex items-center justify-between px-6 py-5">
         <div class="flex items-center gap-1">
            <h6>Transactions</h6>
            <div class="tag tag-sm">100 Transactions</div>
         </div>
         <div class="actions flex flex-row gap-4">
            <button class="btn-neutral px-3">
               <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
            </button>
         </div>
      </div>
      <div class="card-body">
         <div class="courses-list">
            <!-- Table -->
            <div class=" datagrid-table relative overflow-x-auto">
               <table class="w-full min-w-[850px] text-left">
                  <thead class="heading">
                     <tr>
                        <th scope="col" class="">
                           <div class="col-name">Transaction ID</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Order ID</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Date</div>
                        </th>
                        <th scope="col" class="w-16">
                           <div class="col-name">Channel</div>
                        </th>
                        <th scope="col" class="w-24">
                           <div class="col-name">Sale Price</div>
                        </th>
                        <th scope="col" class="w-24">
                           <div class="col-name">Net Amount</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Instructor Earning</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Thkee Earning</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Payment Method</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Payment Status</div>
                        </th>
                     </tr>
                  </thead>
                  <tbody *ngIf="transactionsPagination">
                     <tr class="hover:cursor-pointer" *ngFor="let data of transactionsPagination.results">
                        <td>
                           <span (click)="openTransactionDrawer(data)" class="course-id">
                              <span class="text-blue-600 cursor-pointer">
                                 #{{ data.id }}
                              </span>
                           </span>
                        </td>
                        <td>
                           <span (click)="openOrderDrawer(data)" class="course-id">
                              <span class="text-blue-600 cursor-pointer">
                                 #{{ data.order_id }}
                              </span>
                           </span>
                        </td>
                        <td>
                           <span class="course-id">
                              <span class="">
                                 {{ data.date_created | date:'d MMMM y' }}
                              </span>
                           </span>
                        </td>
                        <td class="max-w-[170px]">
                           <span class="">
                              {{ data.channel }}
                           </span>
                        </td>
                        <td>
                           {{data.sale_price | currency:data.currency_symbol}}
                        </td>
                        <td>
                           {{data.net_amount | currency:data.currency_symbol}}
                        </td>
                        <td>
                           <span class="course-price flex items-center">
                              <button class="btn btn-transparent p-1">
                                 <svg-icon src="@assets/images/icons/down-circle.svg" tooltip="Co-Instructor Earning"
                                    class="w-5 h-5">
                                 </svg-icon>
                              </button>
                              {{ data.instructor_share | currency:data.currency_symbol }}
                           </span>
                        </td>
                        <td>
                           <span class="course-price flex items-center">
                              {{ data.platform_share | currency:data.currency_symbol }}
                           </span>
                        </td>
                        <td>
                           <span class="course-price flex items-center">
                              {{ data.payment_method }}
                           </span>
                        </td>
                        <td>
                           <span class="rounded-full px-2 py-[2px] bg-rose-50 text-rose-600 text-xs">
                              {{ data.payout_status }}
                           </span>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>

            <div class="p-4">
               <thk-pagination [total]="transactionsPagination?.count || 0" [page]="query.page" [size]="query.page_size || 10" [sizeChangable]="true" (paginationChange)="paginate($event)"></thk-pagination>
            </div>
         </div>
      </div>
   </div>
</div>


<!-- Unhold confirm modal -->
<common-modal #unHoldConfirmed customClass="sm:w-full sm:max-w-[788px] rounded-none">
   <ng-container header>
      <div class="p-6 flex flex-row gap-4 justify-between border-b border-neutral-100">
         <div class="form max-w-4xl">
            <h1 class="text-black font-semibold text-xl">Unhold Transaction</h1>
         </div>
         <button (click)="unHoldConfirmed.close()" class="text-slate-500 hover:text-slate-800 w-4">
            <svg-icon src="@assets/images/icons/x-mark.svg" class="w-4"></svg-icon>
         </button>
      </div>
   </ng-container>
   <ng-container content>
      <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-14">
            <div class="flex flex-col gap-3 w-52">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Transaction ID</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction ID" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 routerLink="/ecommerce/sales/transaction/0123521"
                  class="text-xl font-semibold text-blue-600 cursor-pointer">#0123521</h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Sale Price</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="Sale Price which is actual amount paid by the customer" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">
                  $30
               </h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Net Amount</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="Net Price which is actual amount paid by the customer " class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">
                  $23
               </h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Instructor Share</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The total amount (in USD) earned by all instructors once transaction fees and platform/affiliate commissions have been deducted from the net revenue of the sale."
                     class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">
                  $730
               </h6>
            </div>
         </div>
      </div>
      <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-4 w-full">
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Instructor</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The name of the instructor associated with the course purchased." class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Omar Sherif ( <span class="text-blue-600">#12568</span> )
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Date</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The day of the week, day of the month, and time (your local time) at which the transaction took place."
                     class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Oktober 28, 2022
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Payment Gateway</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="Filter if the transaction was made via credit card or PayPal." class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Paypal
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Payment Method</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Payment Method" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Credit Card
               </h6>
            </div>
         </div>
      </div>

      <div class="card-subheader flex flex-col items-center justify-center px-6 py-5 border-b border-b-neutral-100">
         <h3 class="text-neutral-800 text-base font-normal">Are you sure to Unhold this transaction and </h3>
         <h3 class="text-neutral-800 text-base font-normal">will procees to Upcoming Payout at <b>July 2022?</b></h3>
      </div>
   </ng-container>
   <ng-container footer>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
         <div class="flex gap-4">
            <button (click)="unHoldConfirmed.close()" class="btn bg-neutral-100 border-none">
               <span class="text-neutral-900 text-sm font-semibold">Cancel</span>
            </button>
            <button (click)="unHoldConfirmed.close()" class="btn bg-teal-600 border-none">
               <span class="text-white text-sm font-semibold">Confirm</span>
            </button>
         </div>
      </div>
   </ng-container>
</common-modal>

<!-- Transaction details drawer -->
<app-drawer #transactionDrawer>
   <ng-container header>
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
         <h1 class="text-black font-semibold text-xl">Transaction ID #{{transactionData?.id}}</h1>
         <div class="flex justify-between items-center gap-3">
            <button [routerLink]="'/ecommerce/sales/transaction/'+transactionData?.id"
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to Transaction detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="transactionDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>

   <ng-container content>
      <app-transaction-details-overview [id]="transactionData?.id" [isSidebarComponent]="true">
      </app-transaction-details-overview>
   </ng-container>
</app-drawer>

<!-- Order details drawer -->
<app-drawer #orderDrawer>
   <ng-container header>
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
         <h1 class="text-black font-semibold text-xl">Order ID #{{orderData?.order_id}}</h1>
         <div class="flex justify-between items-center gap-3">
            <button [routerLink]="'/ecommerce/order/'+orderData?.order_id"
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to Order detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="orderDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>

   <ng-container content>
      <app-order-details-overview [id]="orderData?.order_id" [isSidebarComponent]="true"></app-order-details-overview>
   </ng-container>
</app-drawer>
