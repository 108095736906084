<div class="course-overview p-4 pt-0">
  <div class="flex items-center justify-between mb-4">
    <div class="page-heading">
      <h3 *ngIf="requestSummary" class="text-2xl font-semibold text-neutral-800">
        {{requestSummary.name}}'s Payout Request
      </h3>
    </div>
    <div class="actions flex flex-row gap-4">
      <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
        <svg-icon src="@assets/images/icons/export-icon.svg" class="text-blue-600"></svg-icon>
        <span class="text-blue-600 font-semibold text-sm">Export CSV</span>
      </button>
    </div>
  </div>
  <div class="flex flex-col gap-4 mb-4">
    <div *ngIf="!requestSummary" [thkLibProcessing]="true" [variant]="'primary'" class="flex justify-center py-6"></div>
    <div *ngIf="requestSummary" class="flex flex-row gap-4">
      <app-metric-card
        class="w-full"
        [data]="{ title: 'Total Amount Requested', value: ((requestSummary.total_requested_amount || 0) | currency:requestSummary.currency_symbol) || '' }">
      </app-metric-card>
      <app-metric-card
        class="w-full"
        [data]="{ title: 'Payout Method', value: requestSummary.payout_method || '-' }">
      </app-metric-card>
      <app-metric-card
        class="w-full"
        [data]="{ title: 'Total Transaction', value: requestSummary.total_transactions + '' }">
      </app-metric-card>
      <app-metric-card
        class="w-full"
        [data]="{ title: 'Created On', value: (requestSummary.created_date | date:'dd MMMM yyyy hh:mm:ss') || '' }">
      </app-metric-card>
    </div>
  </div>
  <thk-tabs class="my-4" [items]="payoutStatusTabs"></thk-tabs>
  <!-- Filter -->
  <div class="flex gap-4 mb-4">
    <div *ngIf="supportPayoutStatus" class="page-heading flex items-center justify-between">
      <div class="actions flex gap-4">
        <div class="relative">
          <button (click)="dropdown('filter')" class="btn-neutral">
            <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
            <span>Filter</span>
          </button>
          <div [ngClass]="isDropdown === 'filter' ? '' : 'hidden'"
            class="bg-white shadow-lg rounded px-2.5 py-5 w-[200px] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
            <div class="form">
              <formly-form [form]="filterForm" [fields]="filterFormFields">
              </formly-form>
            </div>
            <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
              <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">
                <span>Reset</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <thk-date-range-picker ngModel (ngModelChange)="filterByDate($event)" format="YYYY-MM-DD" placeholder="Select range date"></thk-date-range-picker>
  </div>

  <div class="flex-3 w-full overflow-hidden card rounded-lg border border-neutral-100">
    <div class="card-subheader flex items-center justify-between px-6 py-5">
      <div class="flex items-center gap-1">
        <h6>Transactions</h6>
        <div *ngIf="transactionsPagination" class="tag tag-sm">{{transactionsPagination.count}} Transactions</div>
      </div>
      <div class="actions flex flex-row gap-4">
        <button *ngIf="!disableAllButton && selectedTransactions.length" class="btn btn-teal" (click)="openApproveMany()">Approve ({{selectedTransactions.length}})</button>
        <button *ngIf="!disableAllButton && selectedTransactions.length" class="btn btn-danger" (click)="openHoldMany()">Hold ({{selectedTransactions.length}})</button>
        <div class="select2">
          <div class="relative">
            <button class="btn btn-white !pr-2" (click)="dropdown('batchOption')">
              Batch Option
              <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
            </button>
            <ul [ngClass]="isDropdown === 'batchOption' ? '' : 'hidden'" tabindex="-1" role="listbox" class="select-items"
              ng-reflect-ng-class="hidden">
              <li (click)="openApproveMany(true)" id="listbox-option-0" role="option" class="item">Approve All</li>
              <li (click)="openHoldMany(true)" id="listbox-option-0" role="option" class="item">Hold All</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="courses-list">
        <!-- Table -->
        <div class=" datagrid-table relative overflow-x-auto">
          <div *ngIf="!transactionsPagination" class="flex justify-center py-6" [thkLibProcessing]="true"></div>
          <table *ngIf="transactionsPagination"
            class="w-full min-w-[850px] text-left"
            thkNestedCheckbox (selectedChange)="handleSelectedTransactionsChange($event)" #transactionCheckboxToggle>
            <thead class="heading">
              <tr>
                <th scope="col" class="w-28 pl-4">
                   <div class="col-name flex items-center gap-2">
                      <input type="checkbox" thkNestedCheckboxChild [leader]="true">
                      {{selectedTransactions.length}} selected
                   </div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Transaction ID</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Date</div>
                </th>
                <th scope="col" class="w-28">
                  <div class="col-name">Channel</div>
                </th>
                <th scope="col" class="w-28">
                  <div class="col-name">Sale Price</div>
                </th>
                <th scope="col" class="w-28">
                  <div class="col-name">Net Amount</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Instructor Share</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Status</div>
                </th>
                <th scope="col" class="">
                  <div class="col-name">Action</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of transactionsPagination?.results; trackBy: transTrackBy" class="hover:cursor-pointer">
                <td>
                  <input type="checkbox" thkNestedCheckboxChild [data]="data">
               </td>
                <td>
                  <span class="course-id">
                    <span class="text-blue-600 cursor-pointer">
                      {{ data.transaction_id }}
                    </span>
                  </span>
                </td>
                <td>
                  <span class="course-id">
                    <span class="">
                      {{ data.date_created | date:'d MMMM y' }}
                    </span>
                  </span>
                </td>
                <td class="max-w-[170px]">
                  <span class="">
                    {{ data.channel_type }}
                  </span>
                </td>
                <td>
                  {{ data.sale_price | currency:data.currency_symbol }}
                </td>
                <td>
                  {{ data.net_amount | currency:data.currency_symbol }}
                </td>
                <td>
                  <span class="course-price flex items-center">
                    <button class="btn btn-transparent p-1">
                      <svg-icon src="@assets/images/icons/down-circle.svg" tooltip="Co-Instructor Earning"
                        class="w-5 h-5">
                      </svg-icon>
                    </button>
                    {{ data.instructor_share | currency:data.currency_symbol }}
                  </span>
                </td>
                <td>
                  {{data.payout_status}}
                </td>
                <td>
                  <ng-container *ngIf="data.payout_status === 'requested'">
                    <button (click)="openApproveMany(false, [data.id])"  class="btn btn-outline-teal btn-sm">Approve</button>
                    <button (click)="confirmHoldingTransaction(data)" class="btn btn-outline-danger btn-sm">Hold</button>
                  </ng-container>
                  <ng-container *ngIf="data.payout_status === 'ready' || data.payout_status === 'on_hold'">
                    <button (click)="undoTransaction(data)" [thkLibProcessing]="revertingTransactionId === data.id" class="btn btn-outline-teal btn-sm">Undo</button>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="transactionsPagination" class="p-4">
          <thk-pagination [sizeChangable]="true" (paginationChange)="paginate($event)" [size]="query?.page_size || 10" [page]="query?.page" [total]="transactionsPagination.count"></thk-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Rejection confirm modal -->
<common-modal #holdConfirmed (closeEvent)="closeHoldingConfirmationModal()" customClass="sm:w-full sm:max-w-[788px] rounded-none">
  <ng-container header>
    <div class="p-6 flex flex-row gap-4 justify-between border-b border-neutral-100">
      <div class="form max-w-4xl">
        <h1 class="text-black font-semibold text-xl">Reason</h1>
      </div>
      <button (click)="closeHoldingConfirmationModal()" class="text-slate-500 hover:text-slate-800 w-4">
        <svg-icon src="@assets/images/icons/x-mark.svg" class="w-4"></svg-icon>
      </button>
    </div>
  </ng-container>
  <ng-container content>
    <div *ngIf="!holdingTran" class="flex justify-center items-center py-10 w-full"><span [thkLibProcessing]="true" [variant]="'primary'"></span></div>
    <ng-container *ngIf="holdingTran">
      <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
        <div class="flex flex-row gap-14">
          <div class="flex flex-col gap-3">
            <div class="flex gap-x-2.5 items-center relative">
              <h3 class="text-sm font-semibold text-black">Transaction ID</h3>
              <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction ID" class="w-5 h-5">
              </svg-icon>
            </div>
            <h6 class="text-xl font-semibold text-neutral-900">{{holdingTran.transaction_id}}</h6>
          </div>
          <div class="flex flex-col gap-3">
            <div class="flex gap-x-2.5 items-center relative">
              <h3 class="text-sm font-semibold text-black">Period</h3>
              <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Product" class="w-5 h-5">
              </svg-icon>
            </div>
            <h6 class="text-xl font-semibold text-neutral-900">
              {{holdingTran.period}}
            </h6>
          </div>
        </div>
      </div>
      <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
        <div class="flex flex-row gap-4 w-full">
          <div class="flex flex-col gap-3 w-full">
            <div class="flex gap-x-2.5 items-center relative">
              <h3 class="text-sm font-semibold text-neutral-600">Instructor</h3>
              <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Instructor" class="w-5 h-5">
              </svg-icon>
            </div>
            <h6 class="text-base font-bold text-neutral-900">
              {{holdingTran.instructor.fullname}} ( <span class="text-blue-600">{{holdingTran.instructor.id}}</span> )
            </h6>
          </div>
          <div class="flex flex-col gap-3 w-full">
            <div class="flex gap-x-2.5 items-center relative">
              <h3 class="text-sm font-semibold text-neutral-600">Date</h3>
              <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Date" class="w-5 h-5">
              </svg-icon>
            </div>
            <h6 class="text-base font-bold text-neutral-900">
              {{holdingTran.date_created | date:'MMMM dd, y'}}
            </h6>
          </div>
          <div class="flex flex-col gap-3 w-full">
            <div class="flex gap-x-2.5 items-center relative">
              <h3 class="text-sm font-semibold text-neutral-600">Payment Gateway</h3>
              <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Payment Gateway"
                class="w-5 h-5">
              </svg-icon>
            </div>
            <h6 class="text-base font-bold text-neutral-900">
              Paypal**
            </h6>
          </div>
          <div class="flex flex-col gap-3 w-full">
            <div class="flex gap-x-2.5 items-center relative">
              <h3 class="text-sm font-semibold text-neutral-600">Payment Method</h3>
              <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Payment Method" class="w-5 h-5">
              </svg-icon>
            </div>
            <h6 class="text-base font-bold text-neutral-900">
              {{holdingTran.payment_method}}
            </h6>
          </div>
        </div>
      </div>
      <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
        <div class="flex flex-row gap-4 w-full">
          <div class="flex flex-col gap-3 w-full">
            <div class="flex gap-x-2.5 items-center relative">
              <h3 class="text-sm font-semibold text-neutral-600">Sale Price</h3>
              <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Sale Price" class="w-5 h-5">
              </svg-icon>
            </div>
            <h6 class="text-base font-bold text-neutral-900">
              {{holdingTran.sale_price | currency:holdingTran.currency_symbol}}
            </h6>
          </div>
          <div class="flex flex-col gap-3 w-full">
            <div class="flex gap-x-2.5 items-center relative">
              <h3 class="text-sm font-semibold text-neutral-600">Net Amount</h3>
              <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Net Amount" class="w-5 h-5">
              </svg-icon>
            </div>
            <h6 class="text-base font-bold text-neutral-900">
              {{holdingTran.net_amount | currency:holdingTran.currency_symbol}}
            </h6>
          </div>
          <div class="flex flex-col gap-3 w-full">
            <div class="flex gap-x-2.5 items-center relative">
              <h3 class="text-sm font-semibold text-neutral-600">Instructor Share</h3>
              <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Instructor Share"
                class="w-5 h-5">
              </svg-icon>
            </div>
            <h6 class="text-base font-bold text-neutral-900">
              {{holdingTran.instructor_share | currency:holdingTran.currency_symbol}}**
            </h6>
          </div>
          <div class="flex flex-col gap-3 w-full">
            <div class="flex gap-x-2.5 items-center relative">
              <h3 class="text-sm font-semibold text-neutral-600">Thkee Earning</h3>
              <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                tooltip="The name of the student who purchased the course." class="w-5 h-5">
              </svg-icon>
            </div>
            <h6 class="text-base font-bold text-neutral-900">
              {{(holdingTran.sale_price - holdingTran.instructor_share) | currency:holdingTran.currency_symbol}}
            </h6>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="px-6 py-5 pb-0 border-b border-b-neutral-100">
      <div class="form">
        <form [formGroup]="holdForm" (ngSubmit)="holdTransaction()">
          <formly-form [form]="holdForm" [fields]="holdFormFields">
          </formly-form>
          <button class="hidden" type="submit" #holdTransactionBtn></button>
        </form>
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
      <div *ngIf="holdingTran" class="flex gap-4">
        <button (click)="closeHoldingConfirmationModal()" class="btn bg-neutral-100 border-none">
          <span class="text-neutral-900 text-sm font-semibold">Cancel</span>
        </button>
        <button (click)="holdTransactionBtn.click()" class="btn btn-danger" [thkLibProcessing]="processingHolding">
          Yes, Confirm
        </button>
      </div>
    </div>
  </ng-container>
</common-modal>

<common-modal #holdAllModal title="Hold Transaction">
  <div content class="py-4 px-6 gap-2 border-b border-b-neutral-100">
    <div class="flex gap-2 p-2 items-center justify-between">
      <h3 class="text-2xl font-bold">Do you really want to hold this transaction?</h3>
      <div class="h-25 w-25">
        <svg-icon src="@assets/images/icons/hold-transaction.svg" class="h-25 w-25"></svg-icon>
      </div>
    </div>
    <app-messages *ngIf="!requestAnalytics" [messages]="[{ message: 'You have selected ' + selectedTransactions.length + ' transactions.', type: 'warning' }]"></app-messages>
    <app-messages *ngIf="requestAnalytics" [messages]="[{ message: requestAnalytics + ' transactions will be holded', type: 'warning' }]"></app-messages>
    <div class="mt-4">
      <div class="form">
        <form [formGroup]="holdForm" (ngSubmit)="holdMany()">
          <formly-form [form]="holdForm" [fields]="holdFormFields">
          </formly-form>
          <button #holdFormSubmitBtn type="submit" class="hidden"></button>
        </form>
      </div>
    </div>
  </div>
  <div footer class="flex justify-end gap-2 py-4 px-6">
    <button class="btn btn-white" (click)="holdAllModal.close()">Cancel</button>
    <button class="btn btn-danger" [thkLibProcessing]="holdingAll" (click)="holdFormSubmitBtn.click()">Confirm & Hold</button>
  </div>
</common-modal>
<common-modal #approveAllModal title="Approve Transaction">
  <div content class="flex flex-col p-6 gap-4 border-b border-b-neutral-100">
    <div class="flex flex-col gap-2">
      <div class="font-semibold">Are you sure?</div>
      <span class="text-slate-500 text-sm">This will approve {{requestAnalytics ? requestAnalytics : planToProcessTransactionIds?.length || 0 }} transactions</span>
    </div>
    <div class="flex flex-col gap-2">
      <div class="text-sm">Please type "<span class="text-bold">APPROVE</span>" to confirm this process</div>
      <div><input type="text" class="input-field w-full" ngModel #approveInput></div>
    </div>
  </div>
  <div footer class="flex justify-end gap-2 p-6">
     <button class="btn btn-white" (click)="approveAllModal.close()">Cancel</button>
     <button [disabled]="approveInput.value !== 'APPROVE'" class="btn btn-teal" [thkLibProcessing]="approvingMany" (click)="approveMany()">Approve All</button>
  </div>
</common-modal>
