import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { RouterStoreService } from 'thkee-common';

@Component({
  selector: 'app-payout-request-transaction-hold',
  templateUrl: './payout-request-transaction-hold.component.html',
  styleUrls: ['./payout-request-transaction-hold.component.scss'],
})
export class PayoutRequestTransactionHoldComponent implements OnInit {
  params: any = {};
  // Pageination items
  paginationForm = new FormGroup({});
  paginationFormModel: any = {};
  paginationFormFields: FormlyFieldConfig[] = [
    {
      key: 'page',
      type: 'select',
      defaultValue: '5',
      props: {
        wrapAppendClass: ['!mb-3'],
        label: '',
        placeholder: '',
        multiple: false,
        stayPlaceholder: true,
        disabled: false,
        tips: 'Select the number of items displayed into the table',
        stylish: true,
        options: [
          { label: '5', value: '5' },
          { label: '10', value: '10' },
          { label: '15', value: '15' },
        ],
      },
      expressions: {},
    },
  ];

  // Filter form
  filterForm = new FormGroup({});
  filterFormModel: any = {};
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Type</div>`,
    },
    {
      key: 'on_demand',
      type: 'checkbox',
      className: '',
      props: {
        label: 'On-demand (16)',
        required: true,
      },
    },
    {
      key: 'monthly',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Monthly (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Payout Status</div>`,
    },
    {
      key: 'paid',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Paid (16)',
        required: true,
      },
    },
    {
      key: 'inactive',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Inactive (16)',
        required: true,
      },
    },
    {
      key: 'failed',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Failed (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Payout Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Transactions</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '20',
          },
        },
      ],
    },
  ];
  resetFilter() {
    this.filterForm.reset();
  }
  constructor(private routerStore: RouterStoreService, private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.routerStore.getParams().subscribe((params) => {
      this.params = params;
      let breadcrumb: any = [
        {
          label: 'E-Commerce',
          url: '/ecommerce/dashboard',
        },
        {
          label: 'Payouts',
          url: '/ecommerce/payout/paid-payout/all',
        },
        {
          label: 'Payout Request',
          url: '/ecommerce/payout/payouts-requests/all',
        },
        {
          label: `Ahmed Hany #${params['instructorId']}`,
          url: '',
        },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumb);
    });
  }
  // Dropdown methode
  isDropdown: { [key: string]: boolean } = {};
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  // Payment history
  public paymentHistory: any = [
    {
      id: 3,
      title: 'Statement Period',
      value: '2024-02-20T10:30:31.398Z',
      type: 'date',
    },
    {
      id: 2,
      title: 'Total Earnings',
      value: 19,
      type: 'currency',
    },
    {
      id: 4,
      title: 'Total Orders',
      value: 28,
      type: 'number',
    },
    {
      id: 4,
      title: 'Total Transactions',
      value: 28,
      type: 'number',
    },
  ];

  // Dummy data
  public dummyData: any = [
    {
      id: '123',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      request_date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      boldby: 'Frank',
    },
    {
      id: '123',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      request_date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      boldby: 'Ibrahim',
    },
    {
      id: '123',
      instructor: {
        id: 'b001',
        title: 'Faris Friansyah',
      },
      request_date: '2024-02-20T10:30:31.398Z',
      channel: 'Ads',
      sale_price: 344,
      net_amount: 344,
      instructor_earning: 344,
      status: 'Forced Refund',
      boldby: 'Emran',
    },
  ];
}
