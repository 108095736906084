<ng-template #fieldTypeTemplate>
</ng-template>
<div class="relative w-full">
  <thk-datepicker
    class="w-full"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [format]="props.format"
    [placeholder]="props.placeholder"
  ></thk-datepicker>
</div>
