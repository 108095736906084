import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BarChartData, Column, COURSE_STATISTIC_COLUMN, ModalConfirmComponent } from '@shared';
import * as _ from 'lodash';
import { map, Observable, of } from 'rxjs';
import { CategoryV2, CourseActions, CourseV2, Params, selectCategories, selectCourses } from 'thkee-common';
const pageSize: number = 5;

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
  @Input() status: string = '';
  @Input() title: string = 'Overview';

  courses$!: Observable<CourseV2[]>;
  categories$!: Observable<CategoryV2[]>;

  columns: Column[] = COURSE_STATISTIC_COLUMN;
  queryParams: Params = { page: 1, page_size: pageSize };

  // Filter form
  filterForm = new FormGroup({});
  filterFormModel: any = {};
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Sale price</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min-sold',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max-sold',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Minute watch time</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min-sale-price',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max-sale-price',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '120',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Sales</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min-sec',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max-sec',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Enrollments</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min-lac',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max-lac',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Withdrawn</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min-lac',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max-lac',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Student completed course</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min-lac',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max-lac',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '20',
          },
        },
      ],
    },
  ];

  // Enrollments filter form
  enrollmentsFilterForm = new FormGroup({});
  enrollmentsFormModel: any = {};
  enrollmentsFormFields: FormlyFieldConfig[] = [
    {
      key: 'page',
      type: 'select',
      defaultValue: '30',
      props: {
        wrapAppendClass: ['!mb-3'],
        label: '',
        placeholder: '',
        multiple: false,
        stayPlaceholder: true,
        disabled: false,
        tips: 'Select the number of items displayed into the table',
        stylish: true,
        options: [
          { label: 'Last 30 Days', value: '30' },
          { label: 'Last 6 Month', value: '180' },
          { label: 'Last 1 Year', value: '365' },
        ],
      },
      expressions: {},
    },
  ];

  // Top course filter form
  topCourseFilterForm = new FormGroup({});
  topCourseFilterFormModel: any = {};
  topCourseFilterFormFields: FormlyFieldConfig[] = [
    {
      key: 'page',
      type: 'select',
      defaultValue: '30',
      props: {
        wrapAppendClass: ['!mb-3'],
        label: '',
        placeholder: '',
        multiple: false,
        stayPlaceholder: true,
        disabled: false,
        tips: 'Select the number of items displayed into the table',
        stylish: true,
        options: [
          { label: 'Last 30 Days', value: '30' },
          { label: 'Last 6 Month', value: '180' },
          { label: 'Last 1 Year', value: '365' },
        ],
      },
      expressions: {},
    },
  ];

  constructor(private readonly store: Store, private router: Router) {}

  ngOnInit(): void {
    this.getData();
    this.courses$ = this.store.select(selectCourses);
    this.categories$ = this.store.select(selectCategories);
  }

  // Get data
  getData(params: Params = {}) {
    this.store.dispatch(CourseActions.loadCourses({ params: this.queryParams }));
  }

  // Pageination
  paginat(event: any) {
    this.queryParams = Object.assign({}, this.queryParams);
    this.queryParams.page = event;
    this.getData(this.queryParams);
  }

  getCategoryName(categoryId: string | undefined): Observable<CategoryV2 | undefined> {
    if (categoryId === undefined) {
      return of(undefined); // Handle the case when categoryId is undefined
    }
    return this.categories$.pipe(map((categories) => _.find(categories, { id: categoryId })));
  }

  // Common dropdown
  isDropdown: { [key: string]: boolean } = {};
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  // Filter
  submitFilter() {
    console.log('Filter value:', this.filterForm.value);
  }
  resetFilter() {
    this.filterForm.reset();
  }

  // Search
  search(event: any) {
    console.log('🚀 ~ CoursesComponent ~ search ~ event:', event.target.value);
  }

  // Table
  toggleColumn(col: Column): void {
    col.visible = !col.visible;
  }

  // ModalComponent
  @ViewChild('chartModal') private chartModal!: ModalConfirmComponent;
  selectedCourse!: CourseV2;
  seeChart(course: CourseV2) {
    this.selectedCourse = course;
    this.chartModal.open();
  }

  // Chart options
  // Line chart
  chartFilter(event: string) {}
  lineCharttickYValues: string[] = ['$0.00', '$250.00', '$500.00', '$750.00'];
  lineChartLabels: string[] = ['22/8', '23/8', '24/8', '25/8', '26/8'];
  lineChartData = [
    {
      data: [0, 450, 276, 520, 560, 590, 620, 600, 650, 700, 720, 700],
      label: 'Revenue',
      borderColor: '#000000',
      borderWidth: 2,
      pointStyle: 'circle',
      pointRadius: 4,
      pointHoverRadius: 5,
      pointBorderWidth: 2,
      pointBackgroundColor: '#000000',
      pointBorderColor: '#FFFFFF',
      pointHoverBackgroundColor: '#000000',
      pointHoverBorderColor: '#FFFFFF',
      fill: false,
      // tension: 0.4,
      lineJoin: 'round',
    },
    {
      data: [0, 350, 176, 420, 460, 490, 520, 500, 550, 600, 620, 650],
      label: 'Product Sales',
      borderColor: '#E5E5E5',
      borderWidth: 2,
      pointStyle: 'circle',
      pointRadius: 4,
      pointHoverRadius: 5,
      pointBorderWidth: 2,
      pointBackgroundColor: '#E5E5E5',
      pointBorderColor: '#FFFFFF',
      pointHoverBackgroundColor: '#E5E5E5',
      pointHoverBorderColor: '#FFFFFF',
      fill: false,
      // tension: 0.1,
      lineJoin: 'round',
    },
  ];

  // Bar chart
  barCharttickYValues: string[] = ['0', '1', '2', '3', '4', '5'];
  barChartLabels: string[] = ['Course 1', 'Course 2', 'Course 3', 'Course 4', 'Course 5'];
  barChartData: BarChartData[] = [
    {
      data: [100, 450, 476, 520, 560],
      backgroundColor: 'rgba(0,0,0,1)',
      fill: 'origin',
      barPercentage: 0.5,
      height: 300,
      barThickness: 10,
      maxBarThickness: 50,
      minBarLength: 2,
      borderRadius: 0,
    },
  ];
}
