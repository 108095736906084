import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Datepicker, DatepickerOptions } from 'flowbite';
import { DateRangePicker } from 'flowbite-datepicker';
import * as moment from 'moment';
import { debounceTime, ReplaySubject, Subscription } from 'rxjs';
import { MAX_Z_INDEX } from '../../utils/dom';

export interface RangeDate<T = string> {
  start?: T;
  end?: T;
}

@Component({
  selector: 'thk-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.css'],
  providers: [{
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DateRangePickerComponent)
  }]
})
export class DateRangePickerComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  @Input() placeholder?: string;
  @Input() format?: string;
  @Output() pickerShow = new EventEmitter<void>();
  @Output() pickerHide = new EventEmitter<void>();

  @ViewChild('dateRangePicker') private dateRangePickerRef!: ElementRef;
  @ViewChild('start') private start!: ElementRef<{ datepicker: Datepicker }>;
  @ViewChild('end') private end!: ElementRef<{ datepicker: Datepicker }>;

  isDisabled?: boolean;
  model?: RangeDate;

  private dateRangePicker!: DateRangePicker;
  private onChange = (data: RangeDate) => {};
  private model$ = new ReplaySubject<RangeDate>(1);
  private subscription = new Subscription();

  private get startPickerElement() {
    const datePickerInstance = this.start.nativeElement.datepicker as any;
    return datePickerInstance.picker.element as HTMLElement;
  }

  private get endPickerElement() {
    const datePickerInstance = this.end.nativeElement.datepicker as any;
    return datePickerInstance.picker.element as HTMLElement;
  }

  constructor() { }

  writeValue(obj: RangeDate): void {
    this.model = this.formatDate(obj);

    if (this.dateRangePicker) {
      // KNOWN ISSUES:: it show the same values for both start and end, even we pass it differently
      // we will fix it later
      this.dateRangePicker.setDates([
        obj.start ? moment(obj.start).format('L') : '',
        obj.end ? moment(obj.end).format('L') : '',
      ]);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnInit(): void {
    this.subscription = this.model$.pipe(debounceTime(1)).subscribe(value => {
      this.onChange(value);
    })
  }

  ngAfterViewInit(): void {
    this.initialDateElement();
  }

  ngOnDestroy(): void {
    if (this.dateRangePicker) {
      this.dateRangePicker.destroy();
    }
    this.subscription.unsubscribe();
  }

  changeEnd($event: any) {
    const end = $event.detail.date;
    this.update({ end });
  }

  changeStart($event: any) {
    const start = $event.detail.date;
    this.update({ start });
  }

  showEnd() {
    this.end.nativeElement.datepicker.show();
  }
  showStart() {
    this.start.nativeElement.datepicker.show();
  }

  togglePicker() {
  }

  private update(data: Partial<RangeDate>) {
    this.model = { ...this.model, ...this.formatDate(data) };
    this.model$.next(this.model);
  }

  private initialDateElement() {
    const options: DatepickerOptions = {
      defaultDatepickerId: null,
      autohide: false,
      format: 'mm/dd/yyyy',
      maxDate: null,
      minDate: null,
      orientation: 'bottom',
      buttons: false,
      autoSelectToday: 0,
      title: null,
      rangePicker: true,
      onShow: (e: any) => {
        this.pickerShow.next();
      },
      onHide: (e: any) => {
        this.pickerHide.next();
      },
    };
    this.dateRangePicker = new DateRangePicker(this.dateRangePickerRef.nativeElement, options);
    this.endPickerElement.style.zIndex = MAX_Z_INDEX + '';
    this.startPickerElement.style.zIndex = MAX_Z_INDEX + '';
  }

  private formatDate(date: RangeDate<any>): RangeDate {
    if (!date) {
      return {};
    }

    return {
      ...date.start ? { start: moment(date.start).format(this.format || 'MM/DD/YYYY') } : {},
      ...date.end ? { end: moment(date.end).format(this.format || 'MM/DD/YYYY') } : {},
    }
  }
}
