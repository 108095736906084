import { Injectable } from '@angular/core';
import {
  OrderDetails,
  OrderDetailsResponse,
  OrderResponse,
  RefundDetails,
  RefundRequestResponse,
  RefundRequestStatus,
  RefundResponse,
  RejectionPayload,
} from '@shared/models';
import { TransactionItems, TransactionResponse } from '@shared/models/transactions.models';
import { Observable } from 'rxjs';
import {
  API_GET_COMMERCE_ORDER,
  API_GET_COMMERCE_ORDER_CSV_EXPORT,
  API_GET_COMMERCE_ORDER_DETAILS,
  API_GET_COMMERCE_ORDER_DETAILS_ITEMS,
  API_GET_COMMERCE_REFUND,
  API_GET_COMMERCE_REFUND_DETAILS,
  API_GET_COMMERCE_TRANSACTION,
  API_GET_COMMERCE_TRANSACTION_CSV_EXPORT,
  API_GET_COMMERCE_TRANSACTION_DETAILS,
  API_POST_COMMERCE_FORCE_REFUND_REQUEST_APPROVED,
  API_POST_COMMERCE_REFUND_REQUEST_APPROVED,
  API_POST_COMMERCE_REJECT_REQUEST,
  encodeURL,
  HttpService,
  UtilsService,
} from 'thkee-common';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  constructor(private readonly http: HttpService, private utilsService: UtilsService) {}

  // Transaction
  getTransactionLists(params = {} as object, reload: boolean): Observable<TransactionResponse> {
    if (reload) {
      return this.http.get<TransactionResponse>(encodeURL(API_GET_COMMERCE_TRANSACTION, params));
    } else {
      return this.utilsService.get<TransactionResponse>(encodeURL(API_GET_COMMERCE_TRANSACTION, params));
    }
  }

  getTransactionDetails(transactionId: string) {
    return this.http.get<TransactionItems>(
      API_GET_COMMERCE_TRANSACTION_DETAILS.replace('<TRANSACTION_ID>', transactionId)
    );
  }

  // Order
  getOrderLists(params = {} as object, reload: boolean): Observable<OrderResponse> {
    if (reload) {
      return this.http.get<OrderResponse>(encodeURL(API_GET_COMMERCE_ORDER, params));
    } else {
      return this.utilsService.get<OrderResponse>(encodeURL(API_GET_COMMERCE_ORDER, params));
    }
  }

  getOrderDetails(orderId: any): Observable<OrderDetails> {
    return this.http.get<OrderDetails>(API_GET_COMMERCE_ORDER_DETAILS.replace('<ORDER_ID>', orderId));
  }

  getOrderDetailsItems(params = {} as object, orderId: any): Observable<OrderDetailsResponse> {
    return this.http.get<OrderDetailsResponse>(encodeURL(API_GET_COMMERCE_ORDER_DETAILS_ITEMS, params));
  }

  // Refund Request
  getRefundLists(params = {} as object, reload: boolean): Observable<RefundResponse> {
    if (reload) {
      return this.http.get<RefundResponse>(encodeURL(API_GET_COMMERCE_REFUND, params));
    } else {
      return this.utilsService.get<RefundResponse>(encodeURL(API_GET_COMMERCE_REFUND, params));
    }
  }

  getRefundDetails(orderId: string): Observable<RefundDetails> {
    return this.http.get<RefundDetails>(API_GET_COMMERCE_REFUND_DETAILS.replace('<ORDER_ID>', orderId));
  }

  refundRequestApproved(orderId: string): Observable<RefundRequestStatus> {
    return this.http.post<RefundRequestStatus>(
      API_POST_COMMERCE_REFUND_REQUEST_APPROVED.replace('<ORDER_ID>', orderId),
      {}
    );
  }

  refundRequestForceApproved(orderId: string): Observable<RefundRequestStatus> {
    return this.http.post<RefundRequestStatus>(
      API_POST_COMMERCE_FORCE_REFUND_REQUEST_APPROVED.replace('<ORDER_ID>', orderId),
      {}
    );
  }

  refundRequest(payload: RejectionPayload): Observable<RefundRequestResponse> {
    return this.http.post<RefundRequestResponse>(API_GET_COMMERCE_REFUND, payload);
  }

  rejectRefundRequest(orderId: string, payload: RejectionPayload): Observable<RefundRequestStatus> {
    return this.http.post<RefundRequestStatus>(
      API_POST_COMMERCE_REJECT_REQUEST.replace('<ORDER_ID>', orderId),
      payload
    );
  }

  exportOrderAsCSV(): Observable<Blob> {
    const options = { responseType: 'text' };
    return this.http.get<Blob>(API_GET_COMMERCE_ORDER_CSV_EXPORT, undefined, options);
  }

  exportTransactionAsCSV(): Observable<string> {
    const options = { responseType: 'text' };
    return this.http.get<any>(API_GET_COMMERCE_TRANSACTION_CSV_EXPORT, undefined, options);
  }
}
