import { Component, Input, OnInit } from '@angular/core';
import { PayoutService } from '@shared/services/payout/payout.service';
import { AdminManyPayoutRequestSummary } from 'thkee-common';

@Component({
  selector: 'app-payouts-confirmation-information',
  templateUrl: './payouts-confirmation-information.component.html',
  styleUrls: ['./payouts-confirmation-information.component.scss'],
})
export class PayoutsConfirmationInformationComponent implements OnInit {
  @Input() ids?: number[];
  @Input() summary?: AdminManyPayoutRequestSummary;
  private selfSummary?: AdminManyPayoutRequestSummary;
  constructor(private payoutService: PayoutService) {}

  ngOnInit(): void {
    if (!this.summary) {
      this.payoutService.getSelectedPayoutRequestsSummary(this.ids).subscribe((data) => (this.selfSummary = data));
    }
  }

  get summaryData() {
    return this.summary || this.selfSummary;
  }
}
