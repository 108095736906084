import { AfterViewInit, Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';

interface EditorProps extends FormlyFieldProps {
  cols?: number;
  rows?: number;
  maxHeight?: string;
  minHeight?: string;
  disabled?: boolean;
  topBard?: boolean;
  activeSlash?: boolean;
  activeBlock?: boolean;
  isActiveTooltip?: boolean;
  topBarItemsConfig?: {};
}

@Component({
  selector: 'app-formly-field-plain-editor',
  templateUrl: './plain-editor.html',
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
})
export class FormlyFieldPlainEditorComponent extends FieldType<FieldTypeConfig<EditorProps>> implements AfterViewInit {
  // Editor UI controller
  isTopBard = false;
  isActiveSlash = false;
  isActiveBlock = false;
  value = '';
  isDisabled = false;
  isActiveTooltip = false;
  topBarItemsConfig: any = {};

  ngAfterViewInit() {
    this.isDisabled = this.props.disabled ?? false;
    this.isTopBard = this.props.topBard ?? false;
    this.isActiveSlash = this.props.activeSlash ?? false;
    this.isActiveBlock = this.props.activeBlock ?? false;
    this.isActiveTooltip = this.props.isActiveTooltip ?? false;
    this.topBarItemsConfig = this.props.topBarItemsConfig ?? {};
    if (this.formControl.defaultValue) {
      this.value = this.formControl.defaultValue;
    } else {
      this.value = ' ';
      if (this.formControl.value) {
        this.value = this.formControl.value;
      }
    }
  }

  editorValue(value: any) {
    this.value = value;
    this.field.formControl.setValue(value);
  }
}
