<ng-container *ngIf="{ courses: courses$ | async, isLoading: isLoading$ | async } as vm">
  <div class="course-overview p-4">
    <div class="page-heading mb-4 flex items-center justify-between">
      <div>
        <h3>{{ title }}</h3>
      </div>
      <div class="actions flex gap-4">
        <!-- Filter -->
        <div class="relative">
          <button (click)="dropdown(1)" class="btn-neutral">
            <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
            <span>Filter</span>
          </button>
          <div [ngClass]="isDropdown[1] ? '':'hidden'"
            class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto right-0">
            <div class="flex flex-col">
              <div class="flex flex-col">
                <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">
                  Course category
                </div>
                <div [ngClass]="seeMore?'h-auto':'max-h-20 overflow-y-hidden transition-all'">
                  <app-filter-option #filterCategory key="category" [options]="filterOptions.category"
                    [defaultValue]="filterValues.category" (filterEvent)="filterHander($event)">
                  </app-filter-option>
                </div>
                <button class="btn border-0 pt-0 justify-start" type="button" (click)="seeMore = !seeMore">
                  <svg-icon class="btn-icon h-6 w-6 transition-all" src="@assets/images/icons/chevron-down-solid.svg"
                    [class.rotate-180]="seeMore"></svg-icon>
                </button>
              </div>
              <div class="flex flex-col">
                <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5">
                  Review
                </div>
                <app-filter-option #filterReview key="rating" [options]="filterOptions.rating"
                  [defaultValue]="filterValues.rating" (filterEvent)="filterHander($event)">
                </app-filter-option>
              </div>
              <div class="flex flex-col">
                <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5">
                  Sale price
                </div>
                <app-filter-option #filterSalePrice [formType]="'input'" [keyObj]="salePriceKey"
                  (filterEvent)="filterHander($event)">
                </app-filter-option>
              </div>
              <div class="flex flex-col">
                <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5">
                  Course Sold
                </div>
                <app-filter-option #filterCourseSold [formType]="'input'" [keyObj]="courseSoldKey"
                  (filterEvent)="filterHander($event)">
                </app-filter-option>
              </div>
              <div class="flex flex-col">
                <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5">
                  Sections
                </div>
                <app-filter-option #filterCourseSold [formType]="'input'" [keyObj]="courseSectionKey"
                  (filterEvent)="filterHander($event)">
                </app-filter-option>
              </div>
              <div class="flex flex-col">
                <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5">
                  Lectures
                </div>
                <app-filter-option #filterCourseSold [formType]="'input'" [keyObj]="courseLectureKey"
                  (filterEvent)="filterHander($event)">
                </app-filter-option>
              </div>
              <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                <button (click)="clearFilter([filterCategory,filterReview,filterSalePrice,filterCourseSold])"
                  type="button" class="btn btn-outline-dark border-neutral-600">
                  <span>Reset</span>
                </button>
                <button (click)="submitFilter([filterCategory,filterReview,filterSalePrice,filterCourseSold])"
                  class="btn btn-dark bg-neutral-600 text-white">
                  <span>Filter</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="input-combine flex">
          <div class="select2">
            <div class="relative">
              <a (click)="dropdown(2)" type="button" aria-haspopup="listbox" aria-expanded="true"
                aria-labelledby="listbox-label" class="btn-neutral rounded-r-none">
                <div class="selected-item truncate">{{selectedItems.title}}</div>
                <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
              </a>
              <ul [ngClass]="isDropdown[2]?'':'hidden'" tabindex="-1" role="listbox" aria-labelledby="listbox-label"
                aria-activedescendant="listbox-option-3" class="select-items absolute right-0 min-w-fit"
                ng-reflect-ng-class="hidden">
                <li *ngFor="let type of searchType" (click)="dropdown(2); selectItems(type)" id="listbox-option-0"
                  role="option" class="item">{{type.title}}</li>
              </ul>
            </div>
          </div>
          <div class="input-group">
            <div class="group rounded-l-none">
              <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
              <input (keyup.enter)="search($event)" placeholder="Enter your full name" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">
        <div class="card-header border-b border-neutral-100 px-6 py-5">
          <div class="filter-buttons flex gap-2">
            <button class="btn-transparent">
              <span>All Courses</span>
            </button>
            <button class="btn-soft">
              <span>Video Based</span>
            </button>
            <button class="btn-transparent">
              <span>Live Session</span>
            </button>
            <button class="btn-transparent">
              <span>Hybrid</span>
            </button>
          </div>
        </div>
        <div class="card-subheader flex items-center justify-between px-6 py-5">
          <div class="flex items-center gap-1">
            <h6>Courses List</h6>
            <div class="tag tag-sm">{{vm.courses?.length}} Courses</div>
          </div>
          <div class="actions relative">
            <button (click)="dropdown(3)" class="btn-neutral px-2">
              <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
            </button>
            <div [ngClass]="isDropdown[3] ? '':'hidden'"
              class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto right-0">
              <div *ngFor="let col of columns; let i = index" class="input-check w-48 my-2">
                <input [checked]="col.visible" (change)="toggleColumn(col)" type="checkbox" id="default-checkbox-{{i}}">
                <label for="default-checkbox-{{i}}">{{col.name}}</label>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="vm.isLoading === 'loading'; else loadingProgress">
          <ng-container *ngTemplateOutlet="loader"></ng-container>
        </ng-container>
        <ng-template #loadingProgress>
          <div class="card-body">
            <div class="courses-list">
              <div class="datagrid-table relative overflow-x-auto">
                <table class="w-full text-left">
                  <thead class="heading">
                    <tr>
                      <ng-container *ngFor="let column of columns">
                        <th *ngIf="column.visible" scope="col" class="">
                          <div class="col-name">{{column.name}}</div>
                        </th>
                      </ng-container>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="" *ngFor="let course of vm.courses; let i = index">
                      <td *ngIf="columns[0].visible" class="">
                        <span class="course-id flex items-center gap-2">
                          <thk-ui-snippets [type]="'clipboardButton'" [clipboardInput]="course.id"
                            customClass="w-11 h-11 !rounded-full">
                          </thk-ui-snippets>
                          <a class="hover:text-blue-600 hover:underline">
                            #{{ course.id.substring(0, 8) }}
                          </a>
                        </span>
                      </td>
                      <td *ngIf="columns[1].visible">
                        <div class="flex min-h-[69px] max-w-[170px] items-center">
                          <a (click)="viewDetails(course)"
                            class="cursor-pointer course-name line-clamp-3 text-blue-600 underline">
                            {{ course.title }}
                          </a>
                        </div>
                      </td>
                      <td *ngIf="columns[2].visible" class="max-w-[170px]">
                        <span class="whitespace-nowrap text-blue-600 underline" *ngIf="course.user">
                          {{ course?.user.fullname }}
                        </span>
                      </td>
                      <td *ngIf="columns[3].visible">
                        <span class="course-category line-clamp-3 capitalize" *ngIf="course.category">
                          {{ (getCategoryName(course.category) | async)?.name }}
                        </span>
                      </td>
                      <td *ngIf="columns[4].visible" class="max-w-[170px]">
                        <ul class="course-curriculum list-inside list-disc">
                          <li class="whitespace-nowrap">{{course.course_curriculum?.total_sections}} Sections</li>
                          <li class="whitespace-nowrap">{{course.course_curriculum?.total_lectures}} Lectures</li>
                        </ul>
                      </td>
                      <td *ngIf="columns[5].visible">
                        <span class="course-price">{{course.currency_snapshot}}{{course.price_snapshot }}</span>
                      </td>
                      <td *ngIf="columns[6].visible">
                        <span class="course-sold">{{ course.course_sold }}</span>
                      </td>
                      <td *ngIf="columns[7].visible">
                        <div>
                          <div class="overall whitespace-nowrap">{{ course.average_rating }} Stars</div>
                          <div class="review-count whitespace-nowrap text-neutral-500">
                            ({{ course.total_reviews }} Reviews)
                          </div>
                        </div>
                      </td>
                      <td *ngIf="columns[8].visible">
                        <div class="course-types">
                          <div>{{ course.course_type }}</div>
                        </div>
                      </td>
                      <td *ngIf="columns[9].visible">
                        <div *ngIf="course.status === 'published'" class="tag tag-{{ getStatusTagColor(course.status) }} tag-sm" i18n>
                          Published
                        </div>
                        <div *ngIf="course.status === 'in_review'" class="tag tag-{{ getStatusTagColor(course.status) }} tag-sm" i18n>
                          In Review
                        </div>
                        <div *ngIf="course.status === 'draft'" class="tag tag-{{ getStatusTagColor(course.status) }} tag-sm" i18n>
                            Draft
                        </div>
                        <div *ngIf="course.status === 'rejected'" class="tag tag-{{ getStatusTagColor(course.status) }} tag-sm" i18n>
                            Rejected
                        </div>
                        <!-- <div *ngIf="course.status" class="tag tag-{{ getStatusTagColor(course.status) }} tag-sm">{{
                          course.status }}
                        </div> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <app-common-pagination (paginatorData)="paginat($event)" [type]="'dynamic'"></app-common-pagination>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>

<!-- Course loader -->
<ng-template #loader>
  <div class="flex items-center justify-center w-full min-h-[300px]">
     <div class="loader">
        <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
           viewBox="0 0 24 24">
           <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
           <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
           </path>
        </svg>
     </div>
  </div>
</ng-template>