import { ChangeDetectionStrategy, Component, Type } from '@angular/core';
import { FieldType, FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';

interface InputProps extends FormlyFieldProps {
  addonLeft: string;
  addonRight: string;
  allowedTypes?: string[];
  inputAppendClass?: string[];
}

export interface FormlyInputDateFieldConfig extends FormlyFieldConfig<InputProps> {
  type: 'input' | Type<FormlyFieldInputDateComponent>;
}

@Component({
  selector: 'app-formly-field-input-date',
  template: `
    <ng-template #fieldTypeTemplate>
      <input
        [type]="type"
        [formControl]="formControl"
        [class.input-field]="!props.addonLeft && !props.addonRight"
        [formlyAttributes]="field"
        [class.is-invalid]="showError"
        [accept]="props.allowedTypes"
        [ngClass]="props.inputAppendClass ? props.inputAppendClass.join(' ') : ''"
        [placeholder]="props.placeholder"
      />
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldInputDateComponent extends FieldType<FieldTypeConfig<InputProps>> {
  get type() {
    return this.props.type || 'date';
  }
}
