import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CountryData, PricingCountryPayload } from '@shared';
import { Subject } from 'rxjs';
import { ConstantCountryOption } from 'thkee-common';

interface FormTierControls {
  two_letter_iso_code: FormControl;
  three_letter_iso_code: FormControl;
  numeric_iso_code: FormControl; // Add this
  currency_code: FormControl;
}

@Component({
  selector: 'app-form-price-country',
  templateUrl: './price-country.component.html',
  styleUrls: ['./price-country.component.scss'],
})
export class FormPriceCountryComponent implements OnInit {
  @Input() model: any = {}; //TODO: Update model here
  @Input() countries: ConstantCountryOption[] = [];
  @Output() init: EventEmitter<FormlyFieldConfig[]> = new EventEmitter<FormlyFieldConfig[]>();
  @Output() submitEvent: EventEmitter<Partial<any>> = new EventEmitter<Partial<any>>(); //TODO: Update model here

  pricingCountryPayload!: PricingCountryPayload;
  formTier = new FormGroup({});
  formTierModel: any = {}; //TODO: Update model here
  countryModel!: CountryData;

  formTierFields$: Subject<FormlyFieldConfig[]> = new Subject();
  formTierFields: FormlyFieldConfig[] = [];

  showSubmit: boolean = true;

  constructor() {}

  getFormlyFields(): FormlyFieldConfig[] {
    return [
      {
        key: 'name',
        type: 'select',
        props: {
          label: 'Country',
          placeholder: 'Select a country',
          required: true,
          options: this.countries.map((item) => ({
            label: item.name,
            value: item.name,
          })),
          labelProp: 'label',
          stylish: false,
          hideRequiredMarker: true,
          defaultValue: this.model.name,
        },
        hooks: {
          onInit: (field) => {
            // On change event
            if (field.formControl) {
              field.formControl.valueChanges.subscribe((value) => {
                if (value) {
                  if (field.parent?.fieldGroup) {
                    // field?.parent?.fieldGroup[1].formControl?.setValue(value.name);
                    const selectedOption = this.countries.find((country) => country.name === value);

                    // console.log("HERE", this.formTier.controls);
                    // this.formTier.controls['country'].setValue(selectedOption?.name);

                    // this.formTier.controls['two_letter_iso_code'].setValue(selectedOption?.code);
                    // this.formTier.controls['three_letter_iso_code'].setValue(selectedOption?.alpha3);
                    // this.formTier.controls['numeric_iso_code'].setValue(selectedOption?.numeric);
                    // this.formTier.controls['currency_code'].setValue(selectedOption?.currency_code);

                    const controls = this.formTier.controls as FormTierControls;
                    controls['two_letter_iso_code'].setValue(selectedOption?.code);
                    controls['three_letter_iso_code'].setValue(selectedOption?.alpha3);
                    controls['numeric_iso_code'].setValue(selectedOption?.numeric);
                    controls['currency_code'].setValue(selectedOption?.currency_code);
                  }
                }
              });
            }
          },
        },
      },

      {
        fieldGroupClassName: 'grid grid-cols-4 gap-6',
        fieldGroup: [
          {
            key: 'two_letter_iso_code',
            type: 'input',
            props: {
              label: 'Two letter ISO code',
              tips: 'This is a tip message for code',
              placeholder: 'ISO code',
              required: true,
              readonly: true,
            },
          },
          {
            key: 'three_letter_iso_code',
            type: 'input',
            props: {
              label: 'Three letter ISO code',
              tips: 'This is a tip message for three_letter_iso_code',
              placeholder: 'Enter Three letter ISO code...',
              readonly: false,
            },
          },
          {
            key: 'numeric_iso_code',
            type: 'input',
            props: {
              label: 'Numeric ISO code',
              tips: 'This is a tip message for course title',
              placeholder: 'Enter numeric ISO code...',
              readonly: true,
            },
          },
          {
            key: 'currency_code',
            type: 'input',
            props: {
              label: 'Currency Code',
              tips: 'This is a tip message for course title',
              placeholder: 'Enter Currency code...',
              readonly: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'grid grid-cols-3 gap-1',
        fieldGroup: [
          {
            template: '<label class="block mb-2 font-bold">Subject to VAT</label>',
          },

          {
            template: '<label class="block mb-2 font-bold">Allow Billing</label>',
          },
        ],
      },
      {
        fieldGroupClassName: 'grid grid-cols-3 gap-6',
        fieldGroup: [
          {
            key: 'subject_to_vat',
            type: 'toggle',
            props: {
              // label: 'Yes',
            },
          },

          {
            key: 'allow_billing',
            type: 'toggle',
            props: {
              // label: 'Allow',
            },
          },
        ],
      },
    ];
  }

  ngOnInit(): void {
    if (this.model && this.countries.length > 0) {
      // this.formTierModel = this.model;
      this.formTierModel = { ...this.model };
      this.formTierFields$.next(this.getFormlyFields());
      this.formTierFields = this.getFormlyFields();

      console.log('this.model', this.model);
      this.init.emit(this.formTierFields);
    }

    if (!this.isAllDisabled(this.formTierFields).includes(false)) {
      console.log('isAllDisabled');
      this.showSubmit = false;
    }
  }

  submit() {
    this.submitEvent.emit({
      valid: this.formTier.valid,
      values: {
        ...this.model,
        ...this.formTier.value,
      },
    });
  }

  isAllDisabled(objects: FormlyFieldConfig[]): boolean[] {
    const disabledValues: boolean[] = [];
    for (const obj of objects) {
      if (obj.key && obj.type) {
        if (obj.props) {
          disabledValues.push(!!obj.props.disabled);
        }
      }
      if (obj.fieldGroup && obj.fieldGroup.length > 0) {
        disabledValues.push(...this.isAllDisabled(obj.fieldGroup));
      }
    }
    return disabledValues;
  }
}
