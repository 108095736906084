import { createAction, props } from '@ngrx/store';
import {
  Annotation,
  AnnotationDetail,
  AssignmentQuestionV2,
  AssignmentV2,
  ContentTypeModels,
  CourseDataV2,
  CourseV2,
  LectureResourceV2,
  LectureV2,
  Params,
  PartialEntity,
  QuizAnswerV2,
  QuizQuestionV2,
  QuizV2,
  SectionV2,
  StreamVideoInfoV2,
  SubsectionV2,
} from '../../models';
import { ActivityState } from './course-state.model';

export const CourseActions = {
  // course
  loadCourse: createAction('[Course] Load Course', props<{ courseId: string; params?: object }>()),
  loadCourseSuccess: createAction('[Course] Load Course Success', props<{ courseData: CourseDataV2 }>()),
  loadCourses: createAction('[Course] Load Courses', props<{ params: Params }>()),
  loadCoursesSuccess: createAction('[Course] Load Courses Success', props<{ courses: CourseV2[] }>()),
  loadCoursesFailed: createAction('[Course] Load Courses Failed', props<{ error: unknown }>()),

  createCourse: createAction('[Course] Create Course', props<{ userId: number; title: string; categoryId: number }>()),
  createCourseSuccess: createAction('[Course] Create Course Success', props<{ course: CourseV2 }>()),
  createCourseError: createAction('[Course] Create Course Error'),

  upsertCourse: createAction('[Course] Upsert Course', props<{ course: CourseV2 }>()),
  upsertCourseSuccess: createAction('[Course] Upsert Course Success', props<{ course: CourseV2 }>()),
  upsertCourseError: createAction('[Course] Upsert Course Error'),

  // activity
  createActivity: createAction('[Course] Create Activity', props<{ sectionId: string }>()),
  editActivity: createAction(
    '[Course] Edit Activity',
    props<{ sectionId: string; subsectionId: string; activityId: string; activityState: ActivityState }>()
  ),
  closeActivity: createAction('[Course] Close Activity'),
  modalNext: createAction('[Course] Modal Next', props<{ activityState: ActivityState }>()),
  reloadModal: createAction('[Course] Modal Reload', props<{ activityState: ActivityState }>()),

  // section
  upsertSection: createAction('[Course] Upsert Section', props<{ section: PartialEntity<SectionV2> }>()),
  upsertSectionSuccess: createAction('[Course] Upsert Section Success'),
  upsertSectionError: createAction('[Course] Upsert Section Error'),

  deleteSection: createAction('[Course] Delete Section', props<{ sectionId: string; params: object }>()),
  deleteSectionSuccess: createAction('[Course] Delete Section Success'),
  deleteSectionError: createAction('[Course] Delete Section Error'),

  deleteSubsection: createAction('[Course] Delete Subsection', props<{ subsectionId: string }>()),
  deleteSubsectionSuccess: createAction('[Course] Delete Subsection Success'),
  deleteSubsectionError: createAction('[Course] Delete Subsection Error'),

  // quiz
  createQuiz: createAction('[Course] Create Quiz', props<{ subsection: SubsectionV2; quiz: QuizV2 }>()),
  createQuizSuccess: createAction('[Course] Create Quiz Success'),
  createQuizError: createAction('[Course] Create Quiz Error'),

  upsertQuiz: createAction('[Course] Upsert Quiz', props<{ quiz: PartialEntity<QuizV2> }>()),
  upsertQuizSuccess: createAction('[Course] Upsert Quiz Success'),
  upsertQuizError: createAction('[Course] Upsert Quiz Error'),

  upsertQuizQuestion: createAction(
    '[Course] Upsert Quiz Question',
    props<{ quizQuestion: PartialEntity<QuizQuestionV2>; quizAnswers: PartialEntity<QuizAnswerV2>[] }>()
  ),
  upsertQuizQuestionSuccess: createAction('[Course] Upsert Quiz Question Success'),
  upsertQuizQuestionError: createAction('[Course] Upsert Quiz Question Error'),

  deleteQuizQuestion: createAction('[Course] Delete Quiz Question', props<{ quizQuestionId: string }>()),
  deleteQuizQuestionSuccess: createAction('[Course] Delete Quiz Question Success'),
  deleteQuizQuestionError: createAction('[Course] Delete Quiz Question Error'),

  deleteQuizAnswer: createAction('[Course] Delete Quiz Answer', props<{ quizAnswerId: string }>()),
  deleteQuizAnswerSuccess: createAction('[Course] Delete Quiz Answer Success'),
  deleteQuizAnswerError: createAction('[Course] Delete Quiz Answer Error'),

  // assignment
  createAssignment: createAction(
    '[Course] Create Assignment',
    props<{ subsection: SubsectionV2; assignment: AssignmentV2 }>()
  ),
  createAssignmentSuccess: createAction('[Course] Create Assignment Success'),
  createAssignmentError: createAction('[Course] Create Assignment Error'),

  upsertAssignment: createAction(
    '[Course] Upsert Assignment',
    props<{ assignment: PartialEntity<AssignmentV2>; assignmentQuestions: PartialEntity<AssignmentQuestionV2>[] }>()
  ),
  upsertAssignmentSuccess: createAction('[Course] Upsert Assignment Success'),
  upsertAssignmentError: createAction('[Course] Upsert Assignment Error'),

  deleteAssignmentQuestion: createAction(
    '[Course] Delete Assignment Question',
    props<{ assignmentQuestionId: string }>()
  ),
  deleteAssignmentQuestionSuccess: createAction('[Course] Delete Assignment Question Success'),
  deleteAssignmentQuestionError: createAction('[Course] Delete Assignment Question Error'),

  // lecture
  createLecture: createAction('[Course] Create Lecture', props<{ subsection: SubsectionV2; lecture: LectureV2 }>()),
  createLectureSuccess: createAction('[Course] Create Lecture Success', props<{ subsection: any; lecture: any }>()),
  createLectureError: createAction('[Course] Create Lecture Error'),

  upsertLecture: createAction(
    '[Course] Upsert Lecture',
    props<{ lecture: PartialEntity<LectureV2>; lectureResources: PartialEntity<LectureResourceV2>[] }>()
  ),
  upsertLectureSuccess: createAction('[Course] Upsert Lecture Success', props<{ lecture: LectureV2 }>()),
  upsertLectureError: createAction('[Course] Upsert Lecture Error'),

  upsertLectureResourceSuccess: createAction('[Course] Upsert Lecture Resource Success'),
  upsertLectureResourceError: createAction('[Course] Upsert Lecture Resource Error'),

  deleteLectureResource: createAction('[Course] Delete Lecture Resource', props<{ lectureResourceId: string }>()),
  deleteLectureResourceSuccess: createAction('[Course] Delete Lecture Resource Success'),
  deleteLectureResourceError: createAction('[Course] Delete Lecture Resource Error'),

  upsertLectureVideoInfo: createAction(
    '[Course] Upsert Lecture Video Info',
    props<{ video_info: StreamVideoInfoV2 }>()
  ),

  // upload
  uploadStart: createAction(
    '[Course] Upload',
    props<{ referenceId: string; fileName: string; fileType: string; status?: string }>()
  ),
  uploadProgress: createAction(
    '[Course] Upload Progress',
    props<{
      referenceId: string;
      progressPercent: number;
      bytesTotal: number;
      bytesUploaded: number;
      status?: string;
    }>()
  ),
  uploadStatusUpdate: createAction(
    '[Course] Upload Processing',
    props<{ referenceId: string; status?: string; error?: string }>()
  ),
  // uploadReset: createAction('[Course] Upload Reset', props<{ referenceId: string; status?: string }>()),
  uploadComplete: createAction('[Course] Upload Complete', props<{ referenceId: string; status?: string }>()),
  uploadAbort: createAction('[Course] Upload Abort', props<{ referenceId: string }>()),

  // annotation/feedback
  upsertAnnotation: createAction(
    '[Course] Upsert Annotation',
    props<{ annotation: PartialEntity<AnnotationDetail> }>()
  ),
  upsertAnnotationSuccess: createAction('[Course] Upsert Annotation Success'),
  upsertAnnotationError: createAction('[Course] Upsert Annotation Error'),
  openFeedbackModal: createAction(
    '[Course] Open Feedback Modal',
    props<{
      fieldId: string;
      fieldKey: string;
      fieldValue: string;
      fieldModel: ContentTypeModels;
    }>()
  ),
  closeFeedbackModal: createAction('[Course] Close Feedback Modal'),
  resolveFeedback: createAction('[Course] Resolve Feedback', props<{ annotation: AnnotationDetail }>()),
  unresolveFeedback: createAction('[Course] Unresolve Feedback', props<{ annotation: AnnotationDetail }>()),

  // course versions
  loadHistory: createAction('[Course] Load History', props<{ projectId: string }>()),
  loadHistorySuccess: createAction(
    '[Course] Load History Success',
    props<{ courses: CourseV2[]; annotations: Annotation[] }>()
  ),
  loadHistoryError: createAction('[Course] Load History Error'),

  // Update state with drag and drop
  upsertSectionState: createAction('[Course] Upsert Section State', props<{ section: PartialEntity<SectionV2> }>()),
  upsertSubSectionState: createAction(
    '[Course] Upsert Subsection State',
    props<{ subsection: PartialEntity<SubsectionV2> }>()
  ),
};
