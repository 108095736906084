import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  Column,
  FilterKey,
  FilterOptionComponent,
  FilterOptionsOrder,
  ORDER_TABLE_COLUMN,
  ORDER_TABLE_SEARCH,
  OrderItems,
  OrderQueryParams,
  OrderResponse,
  QueryParams,
  TableSearch,
} from '@shared';
import { TransactionsService } from '@shared/services';
import { Subscription } from 'rxjs';
import { Logger, PaginationService, UtilsService } from 'thkee-common';
const log = new Logger('CourseDetailsComponent');
const pageSize: number = 10;

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit, OnDestroy {
  // Common variables
  queryParams: OrderQueryParams = { page: 1, page_size: pageSize };
  salePriceKey: FilterKey = { key1: 'sale_price_min', key2: 'sale_price_max' };
  platformEarningKey: FilterKey = { key1: 'order_items_min', key2: 'order_items_max' };
  filterValues: FilterOptionsOrder = {
    status: [],
  };
  filterOptions: FilterOptionsOrder = {
    status: [],
  };
  searchType: TableSearch[] = ORDER_TABLE_SEARCH;
  selectedItems: TableSearch = ORDER_TABLE_SEARCH[0];
  columns: Column[] = ORDER_TABLE_COLUMN;
  isDropdown: boolean[] = [];

  orderLists: OrderItems[] = [];
  isLoading: boolean = false;
  totalOrder: number = 0;

  // Pageination items
  paginationForm = new FormGroup({});
  paginationFormModel: any = {};
  paginationFormFields: FormlyFieldConfig[] = [
    {
      key: 'page',
      type: 'select',
      defaultValue: '5',
      props: {
        wrapAppendClass: ['!mb-3'],
        label: '',
        placeholder: '',
        multiple: false,
        stayPlaceholder: true,
        disabled: false,
        tips: 'Select the number of items displayed into the table',
        stylish: true,
        options: [
          { label: '5', value: '5' },
          { label: '10', value: '10' },
          { label: '15', value: '15' },
        ],
      },
      expressions: {},
    },
  ];

  private subscriptions = new Subscription();

  constructor(
    private transactionsService: TransactionsService,
    private utilsService: UtilsService,
    private paginationService: PaginationService
  ) {}

  ngOnInit(): void {
    this.getOrders();
    this.setFilterOptions();
  }

  // Get data
  private getOrders(params: OrderQueryParams = {}) {
    this.isLoading = true;
    this.subscriptions.add(
      this.transactionsService.getOrderLists(this.queryParams, false).subscribe({
        next: (res: OrderResponse) => {
          this.orderLists = res.results;
          this.totalOrder = res.count;
          this.paginationService.getPager(res.count, this.queryParams.page, this.queryParams.page_size);
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
        },
      })
    );
  }

  // Common UI actions
  // Filter
  setFilterOptions() {
    this.filterOptions.status = [
      { value: 'created', label: $localize`Created` },
      { value: 'saved', label: $localize`Saved` },
      { value: 'approved', label: $localize`Approved` },
      { value: 'voided', label: $localize`Voided` },
      { value: 'failed', label: $localize`Failed` },
      { value: 'completed', label: $localize`Completed` },
      { value: 'payer_action_required', label: $localize`Payer Action Required` },
    ];
  }

  filterHander(event: { action: string; data: {} }) {
    if (event.action === 'change') {
      const queryParams = this.utilsService.convertQueryParams(event.data);
      this.queryParams = { ...this.queryParams, ...queryParams };
    }
    if (event.action === 'change-input') {
      this.queryParams = { ...this.queryParams, ...event.data };
    }
    if (event.action === 'reset') {
      this.clearFilter([]);
      this.queryParams = {};
      this.getOrders(this.queryParams);
      this.isDropdown[1] = false;
    }
  }

  clearFilter(filters: FilterOptionComponent[]) {
    if (filters) {
      filters.forEach((filter: FilterOptionComponent) => {
        filter.reset();
      });
    }
  }

  submitFilter(filters: FilterOptionComponent[]) {
    this.isDropdown[1] = false;
    this.getOrders(this.queryParams);
  }

  // Search
  selectItems(item: TableSearch) {
    this.queryParams = { page: 1, page_size: pageSize };
    this.selectedItems = item;
  }
  search(event: any) {
    this.queryParams = Object.assign({}, this.queryParams);
    this.queryParams.search = `${this.selectedItems.value + ':' + event.target.value}`;
    this.getOrders(this.queryParams);
  }

  // Dropdown
  dropdown(type: number) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  // Show/Hide column
  toggleColumn(col: Column): void {
    col.visible = !col.visible;
  }

  // Color tag
  getStatusTagColor(status: string): string {
    if (this.utilsService.isStatus(status)) {
      return this.utilsService.getStatusColor(status);
    }
    return 'green';
  }

  // Pageination
  paginat(page_number: number) {
    this.queryParams = Object.assign({}, this.queryParams);
    this.queryParams.page = page_number;
    this.getOrders(this.queryParams);
  }

  isExporting: boolean = false;
  ordersCSVExport(params: QueryParams = {}) {
    this.isExporting = true;
    this.subscriptions.add(
      this.transactionsService.exportOrderAsCSV().subscribe({
        next: (res) => {
          this.isExporting = false;
          const a = document.createElement('a');
          a.href = 'data:text/csv,' + res;
          let filename = 'orders';
          a.setAttribute('download', filename + '.csv');
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        },
        error: (error) => {
          this.isExporting = false;
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
