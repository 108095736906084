import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'formly-fileDashboardInfo',
  template: `
    <div class="flex flex-col gap-4" *ngIf="!field.props?.disabled">
      <div class="flex flex-col items-start justify-start gap-4">
        <label class="font-semibold">{{ field.props?.label }}</label>
        <div class="text-sm text-neutral-500">
          {{ field.props?.placeholder }}
          <b *ngIf="field.props!['subheading']" i18n>Suggested Size:</b>{{ field.props!['subheading'] }}
        </div>
      </div>
      <div class="inline-flex gap-4">
        <input type="button" class="btn btn-amber-soft" value="Browse File" (click)="emitClickEvent('open')" />
        <input type="button" class="btn btn-outline-red" value="Reset" (click)="emitClickEvent('reset')" />
      </div>
    </div>
    <div class="file-metadata flex flex-col gap-1" *ngIf="field.props?.disabled">
      <div>File name here</div>
      <div class="text-sm text-neutral-600">
        <div>file metadata here</div>
      </div>
    </div>
  `,
})
export class FormlyFileDashboardInfoComponent implements OnInit {
  @Input() field!: FormlyFieldConfig;
  @Output() clickEvent = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  emitClickEvent(action: string) {
    this.clickEvent.emit(action);
  }
}
