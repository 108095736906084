import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  FilterOptionComponent,
  FilterOptionsPricingTire,
  PricingTierActions,
  PricingTierPayload,
  PricingTierStatus,
  selectPricingTier,
  selectPricingTierCRUDStatus,
} from '@shared';
import { Observable } from 'rxjs';
import { ModalService, PaginationService, PricingTier, ToastService, UtilsService } from 'thkee-common';
const pageSize: number = 10;
@Component({
  selector: 'app-price-tiers',
  templateUrl: './price-tiers.component.html',
  styleUrls: ['./price-tiers.component.scss'],
})
export class PriceTiersComponent implements OnInit {
  title: string = 'Pricing Tiers';
  pricingTierList$: Observable<any> = this.store.select(selectPricingTier);
  priceTierData: Partial<PricingTier>[] = [];

  pricingTierList: any = [];

  status$: Observable<PricingTierStatus> = this.store.select(selectPricingTierCRUDStatus);

  queryParams: PricingTierPayload = { page: 1, page_size: pageSize };
  filterValues: FilterOptionsPricingTire = {
    price_tier_status: [],
  };
  filterOptions: FilterOptionsPricingTire = {
    price_tier_status: [],
  };
  isDropdown: boolean[] = [];

  constructor(
    private store: Store,
    private readonly modalService: ModalService,
    private readonly toastService: ToastService,
    private router: Router,
    private paginationService: PaginationService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.setFilterOptions();
    this.store.dispatch(PricingTierActions.loadPricingTierList(this.queryParams));
    this.store.dispatch(PricingTierActions.clearPricingTierStatus());

    this.pricingTierList$.subscribe((item) => {
      this.paginationService.getPager(item?.pricingTierList?.count, this.queryParams.page, this.queryParams.page_size);
      if (typeof item.pricingTierList.results !== 'undefined') {
        this.pricingTierList = item?.pricingTierList?.results || [];
      }
    });
  }

  remove(data: PricingTier) {
    this.store.dispatch(PricingTierActions.removePricingTier({ id: data?.id }));

    this.status$.subscribe((status) => {
      if (status === PricingTierStatus.Success) {
        this.toastService.message({
          message: `Pricing Tier removed successfully`,
        });
        this.router.navigate(['/price-management/tiers']);
      }
    });
  }

  deleteConfirmation(data: PricingTier) {
    this.modalService.confirm({
      title: 'Delete Confirmation',
      message: `Are you sure?`,
      onConfirm: () => {
        // Con confirm action here
        // console.log('Delete this: ', { id: data?.id });
        this.remove(data);
      },
    });
  }

  // Dropdown
  dropdown(type: number) {
    this.isDropdown[type] = !this.isDropdown[type];
  }

  paginat(page_number: number) {
    this.queryParams = Object.assign({}, this.queryParams);
    this.queryParams.page = page_number;
    this.store.dispatch(PricingTierActions.loadPricingTierList(this.queryParams));
  }

  setFilterOptions() {
    this.filterOptions.price_tier_status = [
      { value: 'true', label: $localize`Status True` },
      { value: 'false', label: $localize`Status False` },
    ];
  }

  filterHandler(event: { action: string; data: {} }) {
    if (event.action === 'change') {
      const queryParams = this.utilsService.convertQueryParams(event.data);
      this.queryParams = { ...this.queryParams, ...queryParams };
    }
    if (event.action === 'change-input') {
      this.queryParams = { ...this.queryParams, ...event.data };
    }
    if (event.action === 'reset') {
      this.isDropdown[1] = false;
      this.clearFilter([]);
      this.queryParams = { page: 1, page_size: pageSize };
      this.store.dispatch(PricingTierActions.loadPricingTierList(this.queryParams));
    }
  }

  clearFilter(filters: FilterOptionComponent[]) {
    if (filters) {
      filters.forEach((filter: FilterOptionComponent) => {
        filter.reset();
      });
    }
  }

  submitFilter(filters: FilterOptionComponent[]) {
    this.isDropdown[1] = false;
    this.store.dispatch(PricingTierActions.loadPricingTierList(this.queryParams));
  }
}
