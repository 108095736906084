<ng-container *ngIf="{
    priceTierData: (priceTier$ | async) ?? priceTierDataDefault,
    status: (status$ | async) ?? '',
    currencyList: (currencyList$ | async) ?? [],
    hasData: hasData
  } as vm">
  <div class="price-tiers-new p-4">
    <div class="page-heading mb-4 flex items-center justify-between">
      <div class="flex gap-4">
        <h3>View Pricing Tiers</h3>
      </div>
      <div class="actions flex gap-4">
        <!-- TODO: Convert to formly -->
        <div>
          <a routerLink="./edit">
            <button class="btn-amber-soft">
              <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon"></svg-icon>
              <span>Edit</span>
            </button>
          </a>
        </div>
      </div>
    </div>

    <div class="tier-form">
      <div class="card rounded-lg border border-neutral-100 px-6 py-9">
        <app-form-price-tier *ngIf="vm.priceTierData && vm.currencyList.length > 0" (init)="onInit($event)"
          [model]="vm.priceTierData" [currencies]="vm.currencyList"></app-form-price-tier>
        <ng-container *ngIf="isLoadingCurrencyRate; else loadedRate">
          <ng-container *ngTemplateOutlet="loader"></ng-container>
        </ng-container>
        <ng-template #loadedRate>
          <div class="table-record border-t border-neutral-100 pt-4">
            <div class="datagrid-table relative overflow-x-auto">
              <table class="w-full text-left">
                <thead class="heading">
                  <tr>
                    <th scope="col" class="">
                      <div class="col-name">Tier Amount</div>
                    </th>
                    <th scope="col" class="">
                      <div class="col-name">Other Currency</div>
                    </th>
                    <th scope="col" class="">
                      <div class="col-name">Current Exchange Rate Value</div>
                    </th>
                    <th scope="col" class="">
                      <div class="col-name">Amount Conversion</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let tire of tireRate" class="">
                    <td class="">
                      <div class="course-id my-1">{{tire.tier_amount}}</div>
                    </td>
                    <td class="">
                      <span class="course-id">{{tire.name}}</span>
                    </td>
                    <td class="">
                      <span class="course-id">{{tire.rate}}</span>
                    </td>
                    <td class="">
                      <span class="course-id">{{tire.converted_amount}} {{tire.currency_code}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-container>

<!-- Price loader -->
<ng-template #loader>
  <div class="flex items-center justify-center w-full min-h-[200px]">
     <div class="loader">
        <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
           viewBox="0 0 24 24">
           <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
           <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
           </path>
        </svg>
     </div>
  </div>
</ng-template>