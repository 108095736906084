import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  PricingGeoActions,
  PricingGeoService,
  PricingTierActions,
  PricingTierState,
  UntilDestroy,
  selectPricingCurrencyList,
  selectPricingGeoList,
  selectPricingTier,
} from '@shared';
import * as _ from 'lodash';
import { Observable, of, switchMap, take } from 'rxjs';
import { PricingCurrencyData, PricingTier, ToastService } from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss'],
})
export class PriceGeolocationNewComponent implements OnInit {
  countr_id: any = '';
  currency_id: any = '';

  // Auto generate
  autoGenerate: boolean = true;
  priceTiers$: Observable<PricingTierState> = this.store.select(selectPricingTier);
  priceTiers!: PricingTier[];

  pricingCurrencyList$: Observable<PricingCurrencyData[]> = this.store.select(selectPricingCurrencyList);
  pricingCurrencyList: PricingCurrencyData[] = [];

  constructor(
    private store: Store,
    private readonly toastService: ToastService,
    private geoPricingService: PricingGeoService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // TODO: Load list of geo to validate if exist replace once backend support exisiting http code
    this.store.dispatch(
      PricingGeoActions.loadGeoLocation({
        page: 1,
        page_size: 10,
      })
    );
    this.pricingCurrencyList$.subscribe((pricingCurrencyList) => {
      this.pricingCurrencyList = pricingCurrencyList;
    });
  }

  onSubmit(event: any) {
    let data = event?.values;
    if (event.valid) {
      // TODO: Fix interface type
      const payload: any = {
        status: data.status,
        is_deleted: false,
        country: data.country,
        currency: data.currency,
      };

      this.store
        .select(selectPricingGeoList)
        .pipe(take(1))
        .subscribe((geolist) => {
          // TODO: Load list of geo to validate if exist replace once backend support exisiting http code
          const existData = _.find(geolist, { country: data.country, currency: data.currency });
          if (existData) {
            this.toastService.error({
              message: `County and Currency already exist.`,
            });
          } else {
            // OLD
            // this.geoPricingService.addGeoLoc(payload).subscribe((geoLoc) => {
            //   if (geoLoc && geoLoc.id) {
            //     const geoPricingPayload = {
            //       currency_convert: true,
            //       status: true,
            //       is_deleted: false,
            //       geo_location: geoLoc.id,
            //     };
            //     this.geoPricingService.addGeoPricing(geoPricingPayload).subscribe((geoPricing) => {
            //       if (geoPricing && geoPricing.id) {
            //         this.toastService.message({
            //           message: `Geolocation successfully created.`,
            //         });
            //         this.router.navigate(['/price-management/geolocation']);
            //       }
            //     });
            //   }
            // });

            console.log('payload11--', payload, this.pricingCurrencyList);
            const selectedCurrency = _.find(this.pricingCurrencyList, { id: payload.currency });
            if (selectedCurrency) {
              this.store.dispatch(
                PricingTierActions.loadPricingTierList({
                  page: 1,
                  page_size: 10,
                  // currency_code: selectedCurrency.currency_code,
                })
              );

              this.geoPricingService
                .addGeoLoc(payload)
                .pipe(
                  switchMap((geoLoc) => {
                    if (geoLoc && geoLoc.id) {
                      // TODO: Fix interface type
                      // const geoPricingPayload: any = {
                      //   currency_convert: true,
                      //   status: true,
                      //   is_deleted: false,
                      //   geo_location: geoLoc.id,
                      // };
                      // return this.geoPricingService.addGeoPricing(geoPricingPayload).pipe(
                      //   map(() => geoLoc) // Pass along geoLoc
                      // );
                      this.router.navigate([`/price-management/geolocation/${geoLoc.id}`]);
                    }
                    return of(null);
                  })
                  // tap((geoLoc) => {
                  //   if (geoLoc && geoLoc.id) {
                  //     if (!this.autoGenerate) {
                  //       this.toastService.message({
                  //         message: `Geolocation successfully created.`,
                  //       });
                  //       this.router.navigate([`/price-management/geolocation/${geoLoc.id}/edit`]);
                  //     } else {
                  //       this.toastService.message({
                  //         message: `Geo Pricing Tier is creating please wait...`,
                  //       });

                  //       this.priceTiers$
                  //         .pipe(
                  //           untilDestroyed(this),
                  //           tap((priceTiers) => {
                  //             this.priceTiers = priceTiers?.pricingTierList?.results || [];
                  //             return this.priceTiers;
                  //           }),
                  //           map(() => this.priceTiers)
                  //         )
                  //         .subscribe((priceTiers) => {
                  //           const createRecordRequests: Observable<any>[] = priceTiers.map((tier) => {
                  //             const payload: GeoPricingTierPayload = {
                  //               percentage: '0',
                  //               is_deleted: false,
                  //               pricing_tier: tier.id,
                  //               geo_pricing: geoLoc.id,
                  //             };
                  //             return this.geoPricingService.addGeoPricingTier(payload);
                  //           });
                  //           from(createRecordRequests)
                  //             .pipe(concatMap((request) => request))
                  //             .pipe(reduce((acc, response) => acc.concat(response), []))
                  //             .subscribe(
                  //               (responses) => {
                  //                 this.toastService.message({
                  //                   message: `Geolocation successfully created.`,
                  //                 });
                  //                 this.router.navigate([`/price-management/geolocation/${geoLoc.id}/edit`]);
                  //               },
                  //               (error) => {
                  //                 console.error('Error creating records:', error);
                  //               }
                  //             );
                  //         });
                  //     }
                  //   }
                  // })
                )
                .subscribe();
            } else {
              this.toastService.error({
                message: `Selected currency not found.`,
              });
            }
          }
        });
    }
  }
}
