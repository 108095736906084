import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { combineLatest, map, Observable, tap } from 'rxjs';
import {
  AnnotationDetail,
  annotationKey,
  ContentTypeModels,
  contentTypeModelToSelectorMap,
  CopiedEntity,
  CourseV2,
  linkedListToArray,
  Logger,
  PartialEntity,
  selectAnnotationHistory,
  selectCourse,
} from 'thkee-common';

const log = new Logger('HistoryFieldComponent');

type History = {
  value: string;
  date: string;
  feedback?: string;
  isResolved: boolean;
};

@Component({
  selector: 'app-common-history-field',
  templateUrl: './history-field.component.html',
  styleUrls: ['./history-field.component.scss'],
})
export class HistoryFieldComponent implements OnInit {
  @Input() maxHeight: string = '310px';
  @Input() fieldModel?: ContentTypeModels;
  @Input() fieldKey: string = '';
  @Input() fieldId: string = '';

  course$!: Observable<CourseV2>;
  entity$!: Observable<CopiedEntity<unknown>>;
  histories$!: Observable<History[]>;
  annotationHistoryMap$!: Observable<Record<string, PartialEntity<AnnotationDetail>>>;

  constructor(private store: Store) {}

  async ngOnInit() {
    log.debug('fieldModel', this.fieldModel);
    log.debug('fieldKey', this.fieldKey);
    log.debug('fieldId', this.fieldId);

    this.course$ = this.store.select(selectCourse);

    this.entity$ = this.store
      .select(contentTypeModelToSelectorMap[(this.fieldModel as ContentTypeModels) ?? ''](this.fieldId))
      .pipe(
        map((entity) => entity as CopiedEntity<unknown>),
        tap((entity) => log.debug(this.fieldModel + ' entity', entity))
      );

    this.annotationHistoryMap$ = this.store
      .select(selectAnnotationHistory)
      .pipe(
        map((annotationHistories) =>
          _.keyBy(annotationHistories, (annotationHistories) => annotationHistories.key ?? '')
        )
      );

    this.histories$ = combineLatest([this.entity$, this.annotationHistoryMap$]).pipe(
      map(([entity, annotationHistoryMap]) => {
        return linkedListToArray(entity).map((entity): History => {
          const annotation =
            annotationHistoryMap[annotationKey({ model: this.fieldModel, field: this.fieldKey, object_id: entity.id })];
          return {
            value: entity[this.fieldKey] as string,
            date: (entity['date_updated'] as string) ?? '',
            feedback: annotation?.feedback ?? '',
            isResolved: annotation?.is_resolved ?? false,
          };
        });
      })
    );
  }
}
