import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-paid-payout-monthly',
  templateUrl: './paid-payout-monthly.component.html',
  styleUrls: ['./paid-payout-monthly.component.scss'],
})
export class PaidPayoutMonthlyComponent implements OnInit {
  // Pageination items
  paginationForm = new FormGroup({});
  paginationFormModel: any = {};
  paginationFormFields: FormlyFieldConfig[] = [
    {
      key: 'page',
      type: 'select',
      defaultValue: '5',
      props: {
        wrapAppendClass: ['!mb-3'],
        label: '',
        placeholder: '',
        multiple: false,
        stayPlaceholder: true,
        disabled: false,
        tips: 'Select the number of items displayed into the table',
        stylish: true,
        options: [
          { label: '5', value: '5' },
          { label: '10', value: '10' },
          { label: '15', value: '15' },
        ],
      },
      expressions: {},
    },
  ];

  // Filter form
  filterForm = new FormGroup({});
  filterFormModel: any = {};
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5">Select Period</div>`,
    },
    {
      key: 'period',
      type: 'select',
      defaultValue: '2024-02-20T10:30:31.398Z',
      className: 'col-span-6',
      props: {
        wrapClass: 'mt-3 mb-4 flex flex-col',
        hideLabel: true,
        options: [
          { label: 'June 2020', value: '2024-02-20T10:30:31.398Z' },
          { label: 'July 2020', value: '2024-02-20T10:30:31.398Z' },
          { label: 'August 2020', value: '2024-02-20T10:30:31.398Z' },
        ],
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Refund Type</div>`,
    },
    {
      key: 'on_demand',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Regular Refund (16)',
        required: true,
      },
    },
    {
      key: 'monthly',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Thkee Refund (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Status</div>`,
    },
    {
      key: 'paid',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Partially Paid (16)',
        required: true,
      },
    },
    {
      key: 'inactive',
      type: 'checkbox',
      className: '',
      props: {
        label: 'Fully Paid (16)',
        required: true,
      },
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Payout Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Total Refund</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Payees</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Transactions</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '20',
          },
        },
      ],
    },
  ];
  resetFilter() {
    this.filterForm.reset();
  }

  constructor(private breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    let breadcrumb: any = [
      {
        label: 'E-Commerce',
        url: '/ecommerce/dashboard',
      },
      {
        label: 'Payouts',
        url: '/ecommerce/payout/paid-payout/all',
      },
      {
        label: 'Paid Payouts',
        url: '',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumb);
  }

  // Dropdown methode
  isDropdown: { [key: string]: boolean } = {};
  dropdown(type: any) {
    this.isDropdown[type] = !this.isDropdown[type];
  }
  // Status methode
  getStatus(status: string = 'Fully Paid') {
    let style = { bg: '', text: '' };
    if (status === 'Fully Paid') {
      style.bg = 'bg-teal-50';
      style.text = 'text-teal-600';
    } else if (status === 'Partially Paid') {
      style.bg = 'bg-amber-50';
      style.text = 'text-amber-600';
    } else {
      style.bg = 'bg-neutral-50';
      style.text = 'text-neutral-600';
    }
    return style;
  }

  // Type chacker
  typeOf(value: any, type: string) {
    if (typeof value === type) {
      return true;
    } else {
      return false;
    }
  }

  // Payment history
  public paymentHistory: any = [
    {
      id: 1,
      title: 'Success & Failed Payout',
      value: '9 of 10 Payout Succeed',
    },
    {
      id: 2,
      title: 'Bank Paid Amount',
      value: 1500,
    },
    {
      id: 3,
      title: 'Paypal Paid Amount',
      value: 1500,
    },
    {
      id: 4,
      title: 'Payoneer Paid Amount',
      value: 1500,
    },
    {
      id: 5,
      title: 'Total Paid',
      value: 1500,
    },
  ];

  // Dummy data
  public dummyData: any = [
    {
      id: '123',
      period_date: '2024-02-20T10:30:31.398Z',
      payees: 10,
      success_payouts: '9/10',
      faild_payouts: '1/10',
      payout_amount: 344,
      total_refund: 34,
      status: 'Fully Paid',
    },
    {
      id: '124',
      period_date: '2024-02-20T10:30:31.398Z',
      payees: 10,
      success_payouts: '9/10',
      faild_payouts: '1/10',
      payout_amount: 344,
      total_refund: 34,
      status: 'Partially Paid',
    },
    {
      id: '125',
      period_date: '2024-02-20T10:30:31.398Z',
      payees: 10,
      success_payouts: '9/10',
      faild_payouts: '1/10',
      payout_amount: 344,
      total_refund: 34,
      status: 'Fully Paid',
    },
  ];
}
