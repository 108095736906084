import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { TranslateModule } from '@ngx-translate/core';
import {
  UppyAngularDashboardModalModule,
  UppyAngularDashboardModule,
  UppyAngularDragDropModule,
  UppyAngularProgressBarModule,
  UppyAngularStatusBarModule,
} from '@uppy/angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgChartsModule } from 'ng2-charts';
import {
  ClickOutsideDirective,
  DefaultImageDirective,
  ErrorEffects,
  FormlyComponents,
  FormlySetup,
  MessagesComponent,
  PricePipe,
  ThkeeCommonModule,
  TooltipDirective,
  TopicEffects,
  UiSnippetsComponent,
  categoryReducer,
  errorFeature,
  topicReducer,
} from 'thkee-common';
import { CategoryEffects } from '../../../../thkee-common/src/lib/store/category/category.effects';
import {
  BreadcrumbsComponent,
  ChartComponent,
  CircleProgressbarComponent,
  DataTableComponent,
  DrawerComponent,
  DropdownComponent,
  FeedbackComponent,
  FormPriceAddGeoComponent,
  FormPriceCountryComponent,
  FormPriceCurrencyComponent,
  FormPriceGeoComponent,
  FormPriceSharesComponent,
  FormPriceTierComponent,
  FormUserInstructorGroupComponent,
  FormlyDeleteConfirmationComponent,
  FormlyFileDashboardInfoComponent,
  FormlyFileDefaultInfoComponent,
  FormlyFileDragDropInfoComponent,
  FormlyFileVideoPlayerComponent,
  FormlyImgPlaceholderComponent,
  FormlyRepeatButtonComponent,
  HeaderComponent,
  HistoryCourseComponent,
  LiveRatesTableComponent,
  MainEditorComponent,
  ModalComponent,
  ModalConfirmComponent,
  StudentProfileComponent,
  TabMenuComponent,
  ThkVideoPlayerComponent,
  ToastComponent,
} from './components';
import { CardComponent } from './components/card/card.component';
import { HistoryFieldComponent } from './components/feedback/history-field/history-field.component';
import {
  PricingCurrencyEffects,
  PricingGeoEffects,
  authFeature,
  exchangeRateFeature,
  pricingCountryFeature,
  pricingCurrencyFeature,
  pricingGeoFeature,
  pricingTierFeature,
} from './store';
import { PricingCountryEffects } from './store/country/country.effects';
import { ExchangeRateEffects } from './store/exchange-rate/exchange-rate.effects';
import { PricingTierEffects } from './store/pricing/pricing.effects';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { CombinationSingleInputComponent } from './components/combination-single-input/combination-single-input.component';
import { CommonPaginationComponent } from './components/common-pagination/common-pagination.component';
import { FilterOptionComponent } from './components/filter-option/filter-option.component';
import { MetricCardComponent } from './components/metric-card/metric-card.component';

const UppyModules = [
  UppyAngularDashboardModule,
  UppyAngularStatusBarModule,
  UppyAngularDragDropModule,
  UppyAngularProgressBarModule,
  UppyAngularDashboardModalModule,
];

const sharedModules = [ThkeeCommonModule];
const standaloneComponents = [MainEditorComponent];

const sharedComponents = [
  HeaderComponent,
  TabMenuComponent,
  BreadcrumbsComponent,
  DropdownComponent,
  ModalComponent,
  ModalConfirmComponent,
  ToastComponent,
  FormlyImgPlaceholderComponent,
  FormlyFileDashboardInfoComponent,
  FormlyFileDefaultInfoComponent,
  FormlyFileDragDropInfoComponent,
  FormlyRepeatButtonComponent,
  FormlyDeleteConfirmationComponent,
  FeedbackComponent,
  HistoryCourseComponent,
  HistoryFieldComponent,
  DataTableComponent,
  LiveRatesTableComponent,
  FormPriceTierComponent,
  FormPriceSharesComponent,
  FormPriceCurrencyComponent,
  FormPriceCountryComponent,
  FormPriceGeoComponent,
  FormPriceAddGeoComponent,
  FormUserInstructorGroupComponent,
  StudentProfileComponent,
  CircleProgressbarComponent,
  DrawerComponent,
  MessagesComponent,
  CommonPaginationComponent,
  FilterOptionComponent,
  UiSnippetsComponent,
  FormlyFileVideoPlayerComponent,
  ThkVideoPlayerComponent,
];
const ThkeeDirectives = [TooltipDirective, ClickOutsideDirective, DefaultImageDirective];

@NgModule({
  imports: [
    TranslateModule,
    RouterModule,
    ...sharedModules,
    ...standaloneComponents,
    FormsModule,
    ReactiveFormsModule,
    FormlySelectModule,
    FormlySetup,
    ...UppyModules,
    CommonModule,
    StoreModule.forFeature('router', routerReducer),
    StoreModule.forFeature(authFeature),
    StoreModule.forFeature('category', categoryReducer),
    StoreModule.forFeature('topic', topicReducer),
    StoreModule.forFeature(pricingTierFeature),
    StoreModule.forFeature(pricingCurrencyFeature),
    StoreModule.forFeature(exchangeRateFeature),
    StoreModule.forFeature(errorFeature),
    // EffectsModule.forFeature([
    //   CategoryEffects,
    //   TopicEffects,
    //   PricingTierEffects,
    //   PricingCurrencyEffects,
    //   PricingGeoEffects,
    // ]),
    StoreModule.forFeature(pricingCountryFeature),
    StoreModule.forFeature(pricingCurrencyFeature),
    StoreModule.forFeature(exchangeRateFeature),
    StoreModule.forFeature(pricingGeoFeature),

    EffectsModule.forFeature([
      CategoryEffects,
      TopicEffects,
      PricingTierEffects,
      PricingCountryEffects,
      ExchangeRateEffects,
      PricingGeoEffects,
      CategoryEffects,
      TopicEffects,
      PricingTierEffects,
      PricingCurrencyEffects,
      PricingGeoEffects,
      ErrorEffects,
    ]),
    AngularSvgIconModule,
    NgChartsModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
  ],
  declarations: [
    ...sharedComponents,
    ...FormlyComponents,
    ChartComponent,
    CardComponent,
    ...ThkeeDirectives,
    LiveRatesTableComponent,
    MetricCardComponent,
    CombinationSingleInputComponent,
  ],
  exports: [
    ...sharedComponents,
    ...sharedModules,
    ...standaloneComponents,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    ChartComponent,
    AngularSvgIconModule,
    MetricCardComponent,
    CombinationSingleInputComponent,
    ...ThkeeDirectives,
  ],
  providers: [PricePipe],
})
export class SharedModule {}
