<div class="course-overview p-4 pt-0">
   <div class="flex items-center justify-between mb-4">
      <div class="page-heading">
         <h3 class="text-2xl font-semibold text-neutral-800">
            Previous Hold Payout For Ahmed Hany (#453)
         </h3>
      </div>
      <div class="actions flex flex-row gap-4">
         <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
            <svg-icon src="@assets/images/icons/export-icon.svg" class="text-blue-600"></svg-icon>
            <span class="text-blue-600 font-semibold text-sm">Exprot CSV</span>
         </button>
      </div>
   </div>
   <div class="flex flex-col gap-4 mb-4">
      <div class="flex flex-row gap-4">
         <div *ngFor="let payment of paymentHistory"
            class="relative flex flex-col gap-1 border border-[#E5E5E5] rounded-lg p-4 bg-white w-full">
            <span
               class="absolute left-0 top-auto bottom-auto w-[5px] h-12 rounded-tr-[50px] rounded-br-[50px] bg-blue-500"></span>
            <h5 class="text-xs font-normal text-[#666666]">{{payment.title}}</h5>
            <h5 *ngIf="payment.type=== 'date'" class="text-lg font-bold text-[#3941FE]">
               {{payment.value | date:'MMMM y'}}</h5>
            <h5 *ngIf="payment.type=== 'currency'" class="text-lg font-bold text-[#3941FE]">{{payment.value | currency}}
            </h5>
            <h5 *ngIf="payment.type=== 'number' || payment.type=== 'string'" class="text-lg font-bold text-[#3941FE]">
               {{payment.value}}</h5>
            <svg-icon src="@assets/images/icons/question-mark-circle.svg"
               class="btn-icon absolute right-4 text-amber-600"></svg-icon>
         </div>
      </div>
   </div>
   <div class="page-heading mb-4 flex items-center justify-between">
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="dropdown('filter')" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="isDropdown['filter']?'hidden':''"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto">
               <div class="form">
                  <form [formGroup]="filterForm">
                     <formly-form [form]="filterForm" [fields]="filterFormFields" [model]="filterFormModel">
                     </formly-form>
                  </form>
               </div>
               <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                  <button (click)="resetFilter()" class="btn btn-outline-dark border-neutral-600">
                     <span>Reset</span>
                  </button>
                  <button class="btn btn-dark bg-neutral-600 text-white">
                     <span>Filter</span>
                  </button>
               </div>
            </div>
         </div>
         <!-- Search With Dropdown -->
         <div class="input-combine flex">
            <div class="select2">
               <div class="relative">
                  <a (click)="dropdown('course')" type="button" aria-haspopup="listbox" aria-expanded="true"
                     aria-labelledby="listbox-label" class="btn-neutral rounded-r-none">
                     <div class="selected-item truncate">Course ID</div>
                     <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
                  </a>
                  <ul [ngClass]="isDropdown['course']?'':'hidden'" tabindex="-1" role="listbox"
                     aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3" class="select-items "
                     ng-reflect-ng-class="hidden">
                     <li (click)="dropdown('course')" id="listbox-option-0" role="option" class="item">Item 1</li>
                     <li (click)="dropdown('course')" id="listbox-option-0" role="option" class="item">Item 2</li>
                     <li (click)="dropdown('course')" id="listbox-option-0" role="option" class="item">Item 3</li>
                  </ul>
               </div>
            </div>
            <div class="input-group">
               <div class="group !rounded-l-none">
                  <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
                  <input placeholder="Enter your full name" />
               </div>
            </div>
         </div>
      </div>
      <div class="flex gap-2.5">
         <button [routerLink]="'/ecommerce/payout/upcoming-payouts/transactions/'+params['instructorId']" class="btn">
            <span>Transaction</span>
         </button>
         <button class="btn px-6 py-2.5 bg-blue-50 rounded-[4px] gap-2 border-0">
            <span class="text-blue-600 font-normal text-sm">Hold Transactions</span>
         </button>
      </div>
   </div>

   <div class="flex-3 w-full overflow-hidden card rounded-lg border border-neutral-100">
      <div class="card-subheader flex items-center justify-between px-6 py-5">
         <div class="flex items-center gap-1">
            <h6>Transactions</h6>
            <div class="tag tag-sm">100 Transactions</div>
         </div>
         <div class="actions flex flex-row gap-4">
            <button class="btn-neutral px-3">
               <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
            </button>
         </div>
      </div>
      <div class="card-body">
         <div class="courses-list">
            <!-- Table -->
            <div class=" datagrid-table relative overflow-x-auto">
               <table class="w-full min-w-[850px] text-left">
                  <thead class="heading">
                     <tr>
                        <th scope="col" class="">
                           <div class="col-name">Transaction ID</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Instructor</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Date</div>
                        </th>
                        <th scope="col" class="w-28">
                           <div class="col-name">Channel</div>
                        </th>
                        <th scope="col" class="w-28">
                           <div class="col-name">Sale Price</div>
                        </th>
                        <th scope="col" class="w-28">
                           <div class="col-name">Net Amount</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Instructor Earning</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Hold by</div>
                        </th>
                        <th scope="col" class="">
                           <div class="col-name">Action</div>
                        </th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr class="hover:cursor-pointer" *ngFor="let data of dummyData">
                        <td>
                           <span (click)="openTransactionDrawer(data)" class="course-id">
                              <span class="text-blue-600 cursor-pointer">
                                 #{{ data.id }}
                              </span>
                           </span>
                        </td>
                        <td>
                           <span (click)="buyerDrawer.open()" class="course-id">
                              <span class="text-blue-600 cursor-pointer">
                                 {{ data.instructor.title }}
                              </span>
                           </span>
                        </td>
                        <td>
                           <span class="course-id">
                              <span class="">
                                 {{ data.request_date | date:'d MMMM y' }}
                              </span>
                           </span>
                        </td>
                        <td class="max-w-[170px]">
                           <span class="">
                              {{ data.channel }}
                           </span>
                        </td>
                        <td>
                           {{data.sale_price | currency}}
                        </td>
                        <td>
                           {{data.net_amount | currency}}
                        </td>
                        <td>
                           <span class="course-price flex items-center">
                              <button class="btn btn-transparent p-1">
                                 <svg-icon src="@assets/images/icons/down-circle.svg" tooltip="Co-Instructor Earning"
                                    class="w-5 h-5">
                                 </svg-icon>
                              </button>
                              {{ data.instructor_earning | currency }}
                           </span>
                        </td>
                        <td>
                           <span class="rounded-full px-2 py-[2px] bg-amber-100 text-amber-600 text-xs">
                              &#64;{{ data.boldby }}
                           </span>
                        </td>
                        <td>
                           <button (click)="unHoldConfirmed.open()" class="btn bg-teal-600 text-white btn-sm">
                              <span>Unhold</span>
                           </button>
                        </td>
                     </tr>
                  </tbody>
               </table>
            </div>

            <!-- Pagination -->
            <div class="pagination flex items-center justify-between px-5">
               <div class="flex flex-row gap-4 items-center">
                  <span class="text text-neutral-700 font-normal text-sm">Show Items:</span>
                  <div class="form">
                     <form [formGroup]="paginationForm">
                        <formly-form [form]="paginationForm" [fields]="paginationFormFields"
                           [model]="paginationFormModel">
                        </formly-form>
                     </form>
                  </div>
                  <span class="text text-neutral-700 font-normal text-sm">entries</span>
               </div>
               <div class="navigation">
                  <nav aria-label="Page navigation example">
                     <ul class="inline-flex">
                        <li>
                           <a href="#" class="btn btn-outline">
                              <svg-icon src="@assets/images/icons/arrow-left.svg" class="btn-icon h-5 w-5">
                              </svg-icon>
                              <span>Previous</span>
                           </a>
                        </li>
                        <li>
                           <a href="#" class="btn btn-outline">1</a>
                        </li>
                        <li>
                           <a href="#" class="btn btn-outline">2</a>
                        </li>
                        <li>
                           <a href="#" aria-current="page" class="btn btn-outline active">3</a>
                        </li>
                        <li>
                           <a href="#" class="btn btn-outline">...</a>
                        </li>
                        <li>
                           <a href="#" class="btn btn-outline">4</a>
                        </li>
                        <li>
                           <a href="#" class="btn btn-outline">5</a>
                        </li>
                        <li>
                           <a href="#" class="btn btn-outline">
                              <span>Next</span>
                              <svg-icon src="@assets/images/icons/arrow-right.svg" class="btn-icon h-5 w-5">
                              </svg-icon>
                           </a>
                        </li>
                     </ul>
                  </nav>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>


<!-- Unhold confirm modal -->
<common-modal #unHoldConfirmed customClass="sm:w-full sm:max-w-[788px] rounded-none">
   <ng-container header>
      <div class="p-6 flex flex-row gap-4 justify-between border-b border-neutral-100">
         <div class="form max-w-4xl">
            <h1 class="text-black font-semibold text-xl">Unhold Transaction</h1>
         </div>
         <button (click)="unHoldConfirmed.close()" class="text-slate-500 hover:text-slate-800 w-4">
            <svg-icon src="@assets/images/icons/x-mark.svg" class="w-4"></svg-icon>
         </button>
      </div>
   </ng-container>
   <ng-container content>
      <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-14">
            <div class="flex flex-col gap-3 w-52">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Transaction ID</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Transaction ID" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">#00123521</h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Sale Price</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="Sale Price which is actual amount paid by the customer" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">
                  $30
               </h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Net Amount</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="Net Price which is actual amount paid by the customer " class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">
                  $23
               </h6>
            </div>
            <div class="flex flex-col gap-3">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-black">Instructor Share</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The total amount (in USD) earned by all instructors once transaction fees and platform/affiliate commissions have been deducted from the net revenue of the sale."
                     class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-xl font-semibold text-neutral-900">
                  $730
               </h6>
            </div>
         </div>
      </div>
      <div class="card-subheader flex items-center justify-between px-6 py-5 border-b border-b-neutral-100">
         <div class="flex flex-row gap-4 w-full">
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Instructor</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The name of the instructor associated with the course purchased." class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Omar Sherif ( <span class="text-blue-600">#12568</span> )
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Date</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="The day of the week, day of the month, and time (your local time) at which the transaction took place."
                     class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Oktober 28, 2022
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Payment Gateway</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg"
                     tooltip="Filter if the transaction was made via credit card or PayPal." class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Paypal
               </h6>
            </div>
            <div class="flex flex-col gap-3 w-full">
               <div class="flex gap-x-2.5 items-center relative">
                  <h3 class="text-sm font-semibold text-neutral-600">Payment Method</h3>
                  <svg-icon src="@assets/images/icons/question-mark-solid.svg" tooltip="Payment Method" class="w-5 h-5">
                  </svg-icon>
               </div>
               <h6 class="text-base font-bold text-neutral-900">
                  Credit Card
               </h6>
            </div>
         </div>
      </div>

      <div class="card-subheader flex flex-col items-center justify-center px-6 py-5 border-b border-b-neutral-100">
         <h3 class="text-neutral-800 text-base font-normal">Are you sure to Unhold this transaction and </h3>
         <h3 class="text-neutral-800 text-base font-normal">will procees to Upcoming Payout at <b>July 2022?</b></h3>
      </div>
   </ng-container>
   <ng-container footer>
      <div class="px-[30px] py-5 flex flex-col items-end border-t border-neutral-100">
         <div class="flex gap-4">
            <button (click)="unHoldConfirmed.close()" class="btn bg-neutral-100 border-none">
               <span class="text-neutral-900 text-sm font-semibold">Cancel</span>
            </button>
            <button (click)="unHoldConfirmed.close()" class="btn bg-teal-600 border-none">
               <span class="text-white text-sm font-semibold">Confirm</span>
            </button>
         </div>
      </div>
   </ng-container>
</common-modal>

<!-- Transaction details drawer -->
<app-drawer #transactionDrawer>
   <ng-container header>
      <!-- Header -->
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center">
         <h1 class="text-black font-semibold text-xl">Transaction ID #{{transactionData?.id}}</h1>
         <div class="flex justify-between items-center gap-3">
            <button [routerLink]="'/ecommerce/sales/transaction/'+transactionData?.id"
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to Transaction detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="transactionDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>

   <ng-container content>
      <app-transaction-details-overview [id]="transactionData?.id" [isSidebarComponent]="true">
      </app-transaction-details-overview>
   </ng-container>
</app-drawer>


<!-- Buyer drawer -->
<app-drawer #buyerDrawer>
   <!-- Header -->
   <ng-container header>
      <div class="px px-6 py-5 bg-neutral-50 flex justify-between items-center border-b border-b-neutral-100">
         <h3 class="text-xl text-black font-semibold">Faris Friansyah</h3>
         <div class="flex justify-between items-center gap-3">
            <button
               class="flex gap-2 flex-row px-6 py-3 rounded-[4px] font-semibold text-sm text-neutral-900 border border-neutral-600 bg-transparent">
               Go to Instructor detail
               <svg-icon src="@assets/images/icons/arrow-top-right-on-square.svg" class="text-blue-600 w-4 h-4">
               </svg-icon>
            </button>
            <button (click)="buyerDrawer.close()" class="bg-neutral-100 rounded-[3px] w-6 h-6">
               <svg-icon src="@assets/images/icons/x-mark.svg" class="text-black w-3 h-3">
               </svg-icon>
            </button>
         </div>
      </div>
   </ng-container>

   <ng-container content>
      <!-- Summery -->
      <div class="flex flex-row p-6 border-b border-b-neutral-100">
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">ID Instructor</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">#325</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Courses</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">25</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Total Income</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">$500</h3>
         </div>
         <div class="flex flex-col gap-1 w-full">
            <div class="flex flex-row items-center gap-2">
               <h5 class="text-sm font-semibold text-neutral-600">Join Date</h5>
               <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="text-black w-5 h-5">
               </svg-icon>
            </div>
            <h3 class="font-bold text-base text-black">Nov 08, 2022, 2:03 PM</h3>
         </div>
      </div>

      <!-- Tab -->
      <div class="flex gap-2.5 flex-row p-6 border-b border-b-neutral-100 flex-wrap">
         <ng-container *ngFor="let tab of instructorTabLists">
            <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border">
               {{tab.displayName}}
            </button>
         </ng-container>
      </div>

      <!-- Details -->
      <div class="flex flex-col p-6 pb-0">
         <!-- Settings Tab -->
         <div class="section-label block my-3 w-full">
            <h5 class="text-2xl font-semibold text-black">Settings</h5>
         </div>
         <div class="flex gap-2.5 flex-row py-6 border-b border-b-neutral-100 flex-wrap">
            <ng-container *ngFor="let tab of instructorSettingsTabLists">
               <button [ngClass]="tab.displayName==='Profile'?'active':''" class="tab-border border-none">
                  {{tab.displayName}}
               </button>
            </ng-container>
         </div>

         <!-- Profile -->
         <app-student-profile [account_type]="'instructor'"></app-student-profile>

      </div>
   </ng-container>
</app-drawer>