import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thkActorFullname'
})
export class ActorFullnamePipe implements PipeTransform {

  transform(value: Record<string, any>, options?: { fullname?: string, fields?: string[] }): unknown {
    const fullname = value[options?.fullname || 'fullname'];
    if (fullname) {
      return fullname;
    }

    const fields = options?.fields || ['first_name', 'last_name'];
    return fields.map(f => value[f]).filter(Boolean).join(' ');
  }

}
