export enum PayoutType {
  monthly = 'monthly_payout',
  onDemand = 'ondemand_payout',
}

export type TransactionType = 'sale' | 'refund';

export type PayoutTransactionStatus =
  | 'pending'
  | 'available'
  | 'requested'
  | 'ready'
  | 'paid'
  | 'failed'
  | 'on_hold'
  | 'refunded';

export type PayoutRequestStatus = 'requested' | 'processing' | 'paid' | 'failed' | 'ready' | 'deactivated';

export interface PayoutOverview {
  payout_period_id: number;
  status: string;
  payout_type: PayoutType;
  start_date: string;
  end_date: string;
  due_date: string;
  available_balance: number;
  pending_earnings: number;
  expected_total_earnings: number;
  instructor_id: number;
  currency_symbol: string;
  last_payout_type_updated: string;

  payout_pay_active?: false;
  profile_verification_status?: 'approved' | 'pending' | 'under_review' | 'rejected'; // ['pending', 'under_review', 'approved', 'rejected']
  reason?: string;
  instructor_status?: 'unverified' | 'verified';
  is_first_verification?: boolean;
}

export interface PayoutTransactionsQuery {
  page?: number;
  page_size?: number;
  event?: string[];
}

export interface PayoutTransaction {
  id: number;
  transaction_id: string;
  date_created: string;
  instructor_share: string;
  payout_status: string;
  is_refunded: false;
  currency_code: string;
}

export interface PayoutTransactionDetail {
  transaction_id: string;
  date_created: string;
  course: string;
  customer: string;
  channel_type: string;
  coupon: string;
  platform: string;
  sale_price: number;
  gateway_tax: number;
  net_amount: number;
  instructor_share: string;
  source_currency: string;
  exchange_rate: string;
  currency_code: string;
  currency_symbol: string;
}

export interface PayoutActivity {
  id: number;
  event: string;
  event_message: string;
  currency_symbol: string;
  amount: number;
  date_created: string;
  payout_method?: string;
}

export type PayoutMethodName = 'payoneer' | 'paypal' | 'bank_account';
export type bankVerificationStatus = 'under_review' | 'approved' | 'rejected';

export interface PayoutMethod {
  id: number;
  payout_method: {
    id: number;
    name: PayoutMethodName;
  };
  is_active: boolean;
  is_connected: boolean;
  date_created: string;
  date_updated: string;
  bank_verification_status: bankVerificationStatus;
  account_detail: {
    address?: string;
    bank_name?: string;
    routing_number?: string;
    account_number?: string;
    beneficiary_name?: string;
    email?: string;
  };
}

export interface PayoutWithdrawDetail {
  available_amount: number;
  payout_type: string;
  payment_methods: PayoutMethod[];
}

export interface PayoutRequestBasic {
  request_id: string;
  payout_type: string;
  payout_method: string;
  status: string;
  date_created: string;
  amount: number;
  currency_symbol: string;
}

export interface PayoutRequest extends PayoutRequestBasic {
  id: number;
  transactions_count: number;
}

export interface WithdrawDetail {
  currency_symbol: string;
  available_amount: number;
  payout_type: string;
  payment_methods: PayoutMethod[];
}

// Payout wizard
export interface PayoutWizardModel {
  first_name: string;
  last_name: string;
  headline?: string;
  language: string;
  bio?: string;
  photo?: string;
  profile_verification_status: string;
  reason?: string;
  country?: string;
  mobile?: string;
  national_id_front?: string;
  national_id_back?: string;
  passport?: string;
  business_licence?: string;
  thkee_terms_of_use: boolean;
  marketing_terms: boolean;
  NID?: string;
  photo_url?: string;
  business_licence_url?: string;
  passport_url?: string;
  national_id_url?: string;
  step?: number;
}

export interface BankAccountModel {
  swift_code: string;
  account_number: string;
  beneficiary_name?: string;
  bank_name: string;
  post_code: string;
  first_name_in_bank: string;
  last_name_in_bank: string;
  birth_date: string;
  mobile: string;
  branch_name: string;
  branch_address: string;
  customer_id_type: string;
  customer_id: string;
  address: string;
  city: string;
  country: string;
  routing_number?: string;
}

// Admin
export interface InstructorUserDetails {
  id: number;
  first_name: string;
  last_name: string;
  fullname: string;
  headline: string;
  language: string;
  bio: string;
  mobile: string | null;
  national_id_front?: string;
  national_id_back?: string;
  passport?: string;
  business_licence?: string;
  thkee_terms_of_use: boolean;
  marketing_terms: boolean;
  payment_methods: InstructorPaymentMethod[];
  profile_verification_status?: string;
  verified?: boolean;
}

export interface InstructorPaymentMethod {
  id: number;
  name: string;
  is_active: boolean;
  is_connected: boolean;
  details?: InstructorPaymentDetails;
  bank_verification_status?: string;
}

export interface InstructorPaymentDetails {
  swift_code: string;
  account_number: string;
  beneficiary_name: string;
  post_code: string;
  first_name_in_bank: string;
  last_name_in_bank: string;
  birth_date: string;
  mobile: string;
  customer_id_type: string;
  customer_id?: string;
  address: string;
  city: string;
  country: string;
  email?: string;
  branch_address?: string;
  branch_name?: string;
}

// Account details
export interface BankAccountDetails {
  id: number;
  payout_method: {
    id: number;
    name: string;
  };
  is_active: boolean;
  is_connected: boolean;
  date_created: string;
  date_updated: string;
  settings: BankAccountDetailsSettings;
  bank_verification_status: string;
}

export interface BankAccountDetailsSettings {
  city?: string;
  mobile?: string;
  address?: string;
  country?: string;
  bank_name?: string;
  post_code?: string;
  birth_date?: string;
  swift_code?: string;
  branch_name?: string;
  customer_id?: string;
  account_number?: string;
  branch_address?: string;
  routing_number?: string;
  beneficiary_name?: string;
  customer_id_type?: string;
  last_name_in_bank?: string;
  first_name_in_bank?: string;
}
