import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as _ from 'lodash';
import { firstValueFrom, map, Observable } from 'rxjs';
import {
  AnnotationDetail,
  ContentTypeModels,
  FeedbackState,
  Logger,
  selectFeedback,
  selectSelectedAnnotation,
} from 'thkee-common';

const log = new Logger('FeedbackComponent');

@UntilDestroy()
@Component({
  selector: 'app-common-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  @Output() submitEvent: EventEmitter<Partial<AnnotationDetail>> = new EventEmitter<Partial<AnnotationDetail>>();
  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();

  formFeedback = new FormGroup({});
  modelFeedback!: Partial<AnnotationDetail>;
  fieldsFeedback: FormlyFieldConfig[] = [];

  feedback$!: Observable<FeedbackState>;
  annotation$!: Observable<AnnotationDetail | undefined>;
  fieldId$!: Observable<string>;
  fieldKey$!: Observable<string>;
  fieldModel$!: Observable<ContentTypeModels | undefined>;
  fieldValue$!: Observable<string>;

  constructor(private readonly store: Store) {}

  async ngOnInit() {
    this.annotation$ = this.store.select(selectSelectedAnnotation);
    this.feedback$ = this.store.select(selectFeedback);
    this.fieldId$ = this.feedback$.pipe(map((feedback) => feedback.fieldId));
    this.fieldKey$ = this.feedback$.pipe(map((feedback) => feedback.fieldKey));
    this.fieldModel$ = this.feedback$.pipe(map((feedback) => feedback.fieldModel));
    this.fieldValue$ = this.feedback$.pipe(map((feedback) => feedback.fieldValue));

    this.annotation$.pipe(untilDestroyed(this)).subscribe((annotation) => {
      annotation = _.cloneDeep(annotation);
      this.modelFeedback = {
        ...annotation,
      };
      this.initFields();
    });
  }

  async initFields() {
    const fieldValue = await firstValueFrom(this.fieldValue$);
    const fieldKey = await firstValueFrom(this.fieldKey$);

    this.fieldsFeedback = [
      {
        key: 'value',
        type: 'input',
        defaultValue: fieldValue,
        props: {
          tips: 'This is a tip message for course title',
          disabled: true,
          wrapClass: 'readonly input-group feedback-input-group mb-0 border-0',
        },
        expressions: {
          'props.label': (field: FormlyFieldConfig) => _.startCase(fieldKey.replace(/_/g, ' ').toLowerCase()),
        },
      },
      {
        key: 'feedback',
        type: 'textarea',
        props: {
          label: 'Admin Feedback',
          labelClass: '!text-base',
          tips: 'This is a tip message for course title',
          placeholder: 'Add feedback here for instructor...',
          minHeight: '80px',
          maxHeight: '150px',
        },
      },
      {
        key: 'required_action',
        type: 'checkbox',
        props: {
          label: 'Required Action?',
          required: true,
        },
      },
    ];
  }

  onSubmit() {
    this.submitEvent.emit(this.modelFeedback);
  }
  onCancel() {
    this.cancel.emit();
  }
}
