import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UntilDestroy, untilDestroyed } from '@shared';
import { CombinationSingleInputItem, CombinationSingleInputValue } from '@shared/components/combination-single-input/combination-single-input.component';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { PayoutService } from '@shared/services/payout/payout.service';
import { RangeDate } from 'projects/thkee-common/src/lib/components/date-range-picker/date-range-picker.component';
import { PaginationChange } from 'projects/thkee-common/src/lib/components/pagination/pagination.component';
import { debounceTime } from 'rxjs';
import { AdminPaidPayoutsQuery, AdminPayoutPaidRequestsSummary, AdminPayoutRequest, Pagination, PayoutType, QueryFormConverter, QueryStringFilterService } from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-paid-payout-all',
  templateUrl: './paid-payout-all.component.html',
  styleUrls: ['./paid-payout-all.component.scss'],
  providers: [QueryStringFilterService.forComponent()]
})
export class PaidPayoutAllComponent implements OnInit, AfterViewInit {
  paidPayoutsPagination?: Pagination<AdminPayoutRequest>;

  // Filter form
  filterForm = new FormGroup({});
  filterFormModel: any = {};
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Type</div>`,
    },
    {
      key: 'payout_type',
      fieldGroup: [

        {
          key: PayoutType.onDemand,
          type: 'checkbox',
          className: '',
          props: {
            label: 'On-demand',
            required: true,
          },
        },
        {
          key: PayoutType.monthly,
          type: 'checkbox',
          className: '',
          props: {
            label: 'Monthly',
            required: true,
          },
        },
      ]
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Payout Status</div>`,
    },
    {
      key: 'status',
      fieldGroup: [
        {
          key: 'paid',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Paid',
            required: true,
          },
        },
        {
          key: 'inactive',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Inactive',
            required: true,
          },
        },
        {
          key: 'failed',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Failed',
            required: true,
          },
        },
      ]
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Transactions</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'no_of_transactions_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'no_of_transactions_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '20',
          },
        },
      ],
    },
  ];
  showingDropdown = '';
  query?: AdminPaidPayoutsQuery;
  summary?: AdminPayoutPaidRequestsSummary;

  searchOptions: CombinationSingleInputItem[] = [
    {
      id: 'id',
      title: 'ID',
      placeholder: 'Enter ID',
    },
    {
      id: 'request_id',
      title: 'Request ID',
      placeholder: 'Enter Request ID',
    },
    {
      id: 'name',
      title: 'Instructor Name',
      placeholder: 'Enter Instructor Name',
    }
  ];
  searchValue: CombinationSingleInputValue = { key: 'id' };

  private queryFormConverter = new QueryFormConverter<AdminPaidPayoutsQuery>(
    ['payout_type', 'status']
  );

  constructor(
    private breadcrumbService: BreadcrumbService,
    private queryStringFilterService: QueryStringFilterService<AdminPaidPayoutsQuery>,
    private payoutService: PayoutService
  ) {}

  ngOnInit(): void {
    this.initialUiData();
    this.getPaidPayouts();
    this.getSummary();
  }

  ngAfterViewInit(): void {
    this.setupFilter();
  }

  filterByDate(rangeDate: RangeDate) {
    this.queryStringFilterService.patch({
      ...rangeDate.end && { date_created_before: rangeDate.end },
      ...rangeDate.start && { date_created_after: rangeDate.start },
    });
  }

  search($event: CombinationSingleInputValue) {
    const { key, value } = $event;
    if (value) {
      this.queryStringFilterService.patch({ search: [key, value].join(':') });
      return;
    }

    this.queryStringFilterService.remove(['search']);
  }

  paginate($event: PaginationChange) {
    this.queryStringFilterService.patch($event);
  }

  resetFilter() {
    this.filterForm.reset();
  }

  // Dropdown methode
  dropdown(type: string) {
    if (this.showingDropdown !== type) {
      this.showingDropdown = type;
      return;
    }
    this.showingDropdown = '';
  }

  // Status methode
  getStatus(status: string = 'Paid') {
    let style = { bg: '', text: '' };
    if (status === 'Paid') {
      style.bg = 'bg-teal-50';
      style.text = 'text-teal-600';
    } else if (status === 'Failed') {
      style.bg = 'bg-rose-50';
      style.text = 'text-rose-600';
    } else {
      style.bg = 'bg-neutral-50';
      style.text = 'text-neutral-600';
    }
    return style;
  }

  // Payment history
  public summaryItems: { title: string; field: keyof AdminPayoutPaidRequestsSummary; isCurrency?: boolean }[] = [
    {
      title: 'Total Amount Paid-out',
      field: 'total_paid_amount',
      isCurrency: true,
    },
    {
      title: 'Number of Payess',
      field: 'total_payees',
    },
    {
      title: 'Total Payouts',
      field: 'total_payouts',
    },
    {
      title: 'Total Transactions',
      field: 'total_transactions',
    },
    {
      title: 'Paypal Paid Amount',
      field: 'paypal_amount',
      isCurrency: true,
    },
    {
      title: 'Bank Transfer Amount',
      field: 'bank_transfer_amount',
      isCurrency: true,
    },
    {
      title: 'Payoneer Paid Amount',
      field: 'payoneer_amount',
      isCurrency: true,
    },
  ];

  private initialUiData() {
    let breadcrumb: any = [
      {
        label: 'E-Commerce',
        url: '/ecommerce/dashboard',
      },
      {
        label: 'Payouts',
        url: '/ecommerce/payout/paid-payout/all',
      },
      {
        label: 'Paid Payouts',
        url: '',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumb);
  }

  private setupFilter() {
    this.filterForm.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value =>
      this.queryStringFilterService.patch(this.queryFormConverter.toQuery(value as any))
    );

    this.queryStringFilterService.initialValue.subscribe(query => {
      this.query = query;
      if (this.query.search) {
        const [key, value] = this.query.search.split(':');
        if (key || value) {
          this.searchValue = { key, value };
        }
      }
      this.filterForm.patchValue(this.queryFormConverter.toForm(query));
    })
  }

  private getPaidPayouts() {
    this.queryStringFilterService.valueChanges.pipe(
      debounceTime(400),
      untilDestroyed(this)
    ).subscribe(query => {
      this.payoutService.getPaidPayouts(query)
        .subscribe(data => this.paidPayoutsPagination = data);
    });
  }

  private getSummary() {
    this.payoutService.getPaidPayoutsSummary().subscribe(summary => {
      this.summary = summary;
    })
  }
}
