export const MAX_Z_INDEX = 2147483647;

export const registerElementAttributesChange = (element: HTMLElement, attributes: string[], callback: (attribute: string, newValue: string, oldValue: string) => void) => {
  function callbackx(mutationList: MutationRecord[]) {
    mutationList.forEach((mutation) => {
      switch (mutation.type) {
        case 'attributes': {
          if ( !mutation.attributeName || !attributes.includes(mutation.attributeName || '')) {
            break;
          }
          const newValue = (mutation.target as HTMLElement).getAttribute(mutation.attributeName) || '';
          callback(mutation.attributeName, newValue, mutation.oldValue || '')
          break;
        }
      }
    });
  }
  const observer = new MutationObserver(callbackx);
  observer.observe(element, {
    attributeFilter: attributes,
    attributeOldValue: true,
    subtree: false,
  });
};

