import { createFeature, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { errorActions } from './error.actions';

export interface ErrorState {
  [section: string]: string[]; // This allows dynamic keys
}

export const initialState: ErrorState = {};

export const ErrorReducer = createReducer(
  initialState,
  on(errorActions.clientError, (state, { section, status, error }) => {
    let newS = {
      ...state,
    };
    newS[section] = _.uniq(_.flatten(_.compact([state[section], ...error])));
    return newS;
  })
);

export const errorFeature = createFeature({
  name: 'error',
  reducer: ErrorReducer,
});
