<div class="relative p-2" (click)="open()">
  <input #datepicker [disabled]="isDisabled" (changeDate)="changeDate($event)" type="text" class="absolute opacity-0 bottom-0 w-full p-0 m-0 z-0" [placeholder]="placeholder || ''">
  <div class="flex gap-1 items-center text-nowrap relative z-10">
    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
      <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
    </svg>
    <span *ngIf="model">{{datepicker.value}}</span>
    <span *ngIf="!model" class="text-slate-300">{{placeholder || ''}}</span>
  </div>
</div>
