import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ModalComponent, UntilDestroy, untilDestroyed } from '@shared';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { PayoutService } from '@shared/services/payout/payout.service';
import { RangeDate } from 'projects/thkee-common/src/lib/components/date-range-picker/date-range-picker.component';
import { PaginationChange } from 'projects/thkee-common/src/lib/components/pagination/pagination.component';
import { NestedCheckboxDirective } from 'projects/thkee-common/src/lib/directives/nested-checkbox.directive';
import { catchError, combineLatest, debounceTime, distinctUntilChanged, filter, of } from 'rxjs';
import { AdminPayoutRequestDetailSummary, AdminPayoutRequestTransactionQuery, AdminPayoutTransaction, AdminPayoutTransactionDetail, Pagination, PayoutTransactionStatus, QueryFormConverter, QueryStringFilterService, RouterStoreService, TabItem, ToastService } from 'thkee-common';

@UntilDestroy()
@Component({
  selector: 'app-payout-request-transaction',
  templateUrl: './payout-request-transaction.component.html',
  styleUrls: ['./payout-request-transaction.component.scss'],
  providers: [QueryStringFilterService.forComponent()]
})
export class PayoutRequestTransactionComponent implements OnInit, AfterViewInit {
  @ViewChild('holdConfirmed', { read: ModalComponent })
  private holdTranConfirmingModal!: ModalComponent;

  @ViewChild('holdAllModal', { read: ModalComponent })
  private holdAllModal!: ModalComponent;
  @ViewChild('approveAllModal', { read: ModalComponent })
  private approveAllModal!: ModalComponent;
  @ViewChild('transactionCheckboxToggle', { read: NestedCheckboxDirective })
  private transactionCheckboxToggle?: NestedCheckboxDirective;

  selectedTransactions: AdminPayoutTransaction[] = [];
  requestSummary?: AdminPayoutRequestDetailSummary;
  transactionsPagination?: Pagination<AdminPayoutTransaction>;
  requestId = '';
  /**
   * if it presents, it mean that we all processing all transactions,
   * if not only process the selected transactions
   */
  requestAnalytics?: number;
  holdingTran?: AdminPayoutTransactionDetail;
  // Pageination items
  paginationForm = new FormGroup({});
  paginationFormModel: any = {};
  paginationFormFields: FormlyFieldConfig[] = [
    {
      key: 'page',
      type: 'select',
      defaultValue: '5',
      props: {
        wrapAppendClass: ['!mb-3'],
        label: '',
        placeholder: '',
        multiple: false,
        stayPlaceholder: true,
        disabled: false,
        tips: 'Select the number of items displayed into the table',
        stylish: true,
        options: [
          { label: '5', value: '5' },
          { label: '10', value: '10' },
          { label: '15', value: '15' },
        ],
      },
      expressions: {},
    },
  ];

  // Filter form
  filterForm = new FormGroup({});
  filterFormFields: FormlyFieldConfig[] = [
    // {
    //   template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Type</div>`,
    // },
    // {
    //   key: 'on_demand',
    //   type: 'checkbox',
    //   className: '',
    //   props: {
    //     label: 'On-demand',
    //     required: true,
    //   },
    // },
    // {
    //   key: 'monthly',
    //   type: 'checkbox',
    //   className: '',
    //   props: {
    //     label: 'Monthly',
    //     required: true,
    //   },
    // },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Payout Status</div>`,
    },
    {
      key: 'payout_status',
      fieldGroup: [
        {
          key: 'paid',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Paid',
            required: true,
          },
        },
        {
          key: 'inactive',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Inactive',
            required: true,
          },
        },
        {
          key: 'failed',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Failed',
            required: true,
          },
        },
      ]
    },
  ];

  processingHolding = false;

  approvingTransactionId?: number;
  revertingTransactionId?: number;

  holdingAll = false;
  approvingMany = false;

  // Hold Reason form
  holdForm = new FormGroup({});
  holdFormModel: any = {};
  holdFormFields: FormlyFieldConfig[] = [
    {
      key: 'reason',
      type: 'input',
      props: {
        label: 'Reason',
        labelClass: 'font-bold text-black text-lg pb-2.5',
        required: true,
        placeholder: 'Enter a reason',
      },
      validation: {
        messages: {
          required: $localize`Reason is required!`,
        },
      }
    },
    {
      key: 'description',
      type: 'textarea',
      props: {
        label: 'Description',
        labelClass: 'font-bold text-black text-lg pb-2.5',
        placeholder: 'Enter a description...',
        required: true,
        minHeight: '110px',
        maxHeight: '100%',
      },
      validation: {
        messages: {
          required: $localize`Description is required!`,
        },
      }
    },
  ];

  payoutStatusTabs: TabItem[] = [
    { title: 'All', route: '../all' },
    { title: 'Approved', route: '../ready' },
    { title: 'Hold', route: '../on_hold' }
  ];
  supportPayoutStatus = false;
  query?: AdminPayoutRequestTransactionQuery;

  planToProcessTransactionIds: number[] = [];
  private reqId$ = this.routerStore.getParam('payoutRequestId').pipe(
    filter(Boolean),
    distinctUntilChanged()
  );
  private payoutStatus$ = this.routerStore.getParam('payoutStatus').pipe(
    filter(Boolean),
    distinctUntilChanged()
  );
  private queryFormConverter = new QueryFormConverter<AdminPayoutRequestTransactionQuery>(['payout_status']);
  disableAllButton = false;

  constructor(
    private routerStore: RouterStoreService,
    private breadcrumbService: BreadcrumbService,
    private payoutService: PayoutService,
    private toastService: ToastService,
    private queryFilterService: QueryStringFilterService<AdminPayoutRequestTransactionQuery>,
  ) { }

  ngOnInit(): void {
    this.getTransactions();
    this.initialUiData();
  }

  ngAfterViewInit(): void {
    this.setupFilter();
  }

  filterByDate(rangeDate: RangeDate) {
    this.queryFilterService.patch({
      ...rangeDate.end && { date_created_before: rangeDate.end },
      ...rangeDate.start && { date_created_after: rangeDate.start },
    });
  }

  handleSelectedTransactionsChange($event: AdminPayoutTransaction[]) {
    this.selectedTransactions = $event;
    this.disableAllButton = this.selectedTransactions.some(t => t.payout_status !== 'requested');
  }

  paginate($event: PaginationChange) {
    this.queryFilterService.patch($event);
  }

  holdTransaction() {
    if (!this.holdingTran || this.holdForm.invalid) {
      return;
    }

    this.processingHolding = true;
    this.payoutService
      .holdTransactions(
        Number(this.requestId),
        { ...this.holdForm.value, transaction_ids: [this.holdingTran.id] } as any
      )
      .pipe(
        catchError(() => of(false))
      )
      .subscribe(ok => {
        this.processingHolding = false;
        if (!ok) {
          this.showErrorMessage();
          return;
        }
        this.queryFilterService.refresh();
        this.holdTranConfirmingModal.close();
        this.holdingTran = undefined;
        this.toastService.message({
          type: 'message',
          message: 'Hold transaction successfully'
        })
        this.holdForm.reset();
      })
  }

  closeHoldingConfirmationModal() {
    this.holdingTran = undefined;
    this.holdTranConfirmingModal.close();
  }

  // Dropdown methode
  isDropdown?: 'batchOption' | 'filter' | 'search';
  dropdown(type: 'batchOption' | 'filter' | 'search') {
    this.isDropdown = this.isDropdown === type ? undefined : type;
  }

  openApproveMany(appliedAll?: boolean, tranIds?: number[]) {
    if (appliedAll) {
      this.getRequestTransactionAnalytic();
      this.dropdown('batchOption');
      this.planToProcessTransactionIds = [];
    } else {
      this.requestAnalytics = undefined;
      this.planToProcessTransactionIds = tranIds ? tranIds : this.selectedTransactions.map(t => t.id);
    }
    this.approveAllModal.open();
  }

  openHoldMany(appliedAll = false) {
    if (appliedAll) {
      this.getRequestTransactionAnalytic();
      this.dropdown('batchOption');
    } else {
      this.requestAnalytics = undefined;
    }
    this.holdAllModal.open();
  }

  confirmHoldingTransaction(tran: AdminPayoutTransaction) {
    this.holdTranConfirmingModal.open();
    this.payoutService
      .getTransactionDetail(tran.id)
      .subscribe(transaction => this.holdingTran = transaction);
  }

  undoTransaction(data: AdminPayoutTransaction) {
    this.revertingTransactionId = data.id;
    const isOnhold = data.payout_status === 'on_hold';
    const action = isOnhold
      ? this.payoutService.unholdRequestTransaction(Number(this.requestId), data.id)
      : this.payoutService.unholdRequestTransaction(Number(this.requestId), data.id);
    action
      .pipe(
        catchError(() => of(false))
      )
      .subscribe(ok => {
        this.revertingTransactionId = undefined;
        if (!ok) {
          this.showErrorMessage();
          return;
        }
        this.queryFilterService.refresh();
        this.toastService.message({
          message: isOnhold ? 'Unhold transaction successfully' : 'The transaction status is "Requested" now',
          type: 'message'
        });
      })
  }

  approveMany() {
    this.approvingMany = true;
    const appliedIds = this.requestAnalytics
      ? undefined
      : this.planToProcessTransactionIds;
    this.payoutService.approveRequestTransactions(
      Number(this.requestId),
      appliedIds
    )
      .subscribe(() => {
        this.approvingMany = false;
        this.queryFilterService.refresh();
        this.approveAllModal.close();
        this.toastService.message({
          message: 'All transactions are now approved',
          type: 'message'
        })
      })
  }

  holdMany() {
    if (this.holdForm.invalid) {
      return;
    }

    const appliedIds = this.requestAnalytics
      ? {}
      : { transaction_ids: this.selectedTransactions.map(c => c.id ) };

    this.holdingAll = true;
    this.payoutService.holdTransactions(
      Number(this.requestId),
      {
        ...this.holdForm.value as any,
        ...appliedIds
      }
    )
      .pipe(catchError(() => of(false)))
      .subscribe(ok => {
        this.holdingAll = false;
        if (!ok) {
          this.showErrorMessage();
          return;
        }

        this.holdAllModal.close();
        this.queryFilterService.refresh();
        this.toastService.message({
          message: 'All transactions are now on hold',
          type: 'message'
        });
        this.holdForm.reset();
      });
  }

  transTrackBy(_: any, trans: AdminPayoutTransaction) {
    return trans.id;
  }

  resetFilter() {
    this.filterForm.reset();
  }

  private getTransactions() {
    combineLatest([
      this.payoutStatus$,
      this.queryFilterService.valueChanges
    ])
      .pipe(
        debounceTime(400),
        untilDestroyed(this)
      ).subscribe(([payoutStatus, query]) => {
        this.transactionCheckboxToggle?.uncheck();
        const status: AdminPayoutRequestTransactionQuery = payoutStatus === 'all'
          ? {}
          : { payout_status: payoutStatus as PayoutTransactionStatus };
        this.payoutService.getPayoutRequestTransactions(
          Number(this.requestId), { ...query, ...status }
        )
          .subscribe(data => {
            this.transactionsPagination = data;
          });
      });
  }

  private getRequestSummary() {
    this.payoutService
      .getPayoutRequestDetailSummary(Number(this.requestId))
      .subscribe(summary => this.requestSummary = summary);
  }

  private getRequestTransactionAnalytic() {
    this.payoutService.getPayoutRequestedTransactionAmount(Number(this.requestId))
      .subscribe(data => this.requestAnalytics = data);
  }

  private showErrorMessage() {
    this.toastService.message({
      type: 'error',
      message: $localize`Sorry, something went wrong. Please try again later.`
    });
  }

  private setupFilter() {
    this.filterForm.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.queryFilterService.patch(this.queryFormConverter.toQuery(value as any));
    });

    this.queryFilterService.initialValue.subscribe(query => {
      this.query = query;
      this.filterForm.patchValue(this.queryFormConverter.toForm(query));
    });
  }

  private initialUiData() {
    this.payoutStatus$.pipe(untilDestroyed(this))
      .subscribe(status => this.supportPayoutStatus = status === 'all');

    this.reqId$.pipe(
      untilDestroyed(this)
    ).subscribe(requestId => {
      this.requestId = requestId;
      this.getRequestSummary();
      let breadcrumb: any = [
        {
          label: 'E-Commerce',
          url: '/ecommerce/dashboard',
        },
        {
          label: 'Payouts',
          url: '/ecommerce/payout/paid-payout/all',
        },
        {
          label: 'Payout Request',
          url: '/ecommerce/payout/payouts-requests/all',
        },
        {
          label: `Ahmed Hany #${requestId}`,
          url: '',
        },
      ];
      this.breadcrumbService.setBreadcrumbs(breadcrumb);
    });
  }
}
