import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ModalComponent, UntilDestroy, selectUserId, untilDestroyed } from '@shared';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, combineLatest, distinctUntilChanged, filter, firstValueFrom, map } from 'rxjs';
import {
  AnnotationDetail,
  AnnotationService,
  CategoryV2,
  ContentTypeApps,
  ContentTypeModels,
  CourseActions,
  CourseV2,
  FeedbackState,
  FileProps,
  LANGUAGES,
  LoaderService,
  Logger,
  ProjectService,
  RouterStoreService,
  generateUuid,
  selectCategories,
  selectContentType,
  selectCourse,
  selectFeedback,
  selectTopics,
} from 'thkee-common';
import { HeaderActions } from '../components/course-header/course-header.component';

const log = new Logger('CourseDetailsComponent');

@UntilDestroy()
@Component({
  selector: 'app-general',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class CourseDetailsComponent implements OnInit {
  @Input() isDefaultView: boolean = true;
  @ViewChild('feedbackModal') feedbackModal!: ModalComponent;
  @ViewChild('changeHistory') changeHistory!: ModalComponent;

  COURSE_MODEL = ContentTypeModels.COURSE;
  courseState: string = '0';
  courseId: string = '';

  readOnly: boolean = true;
  options: FormlyFormOptions = {};
  formDetails = new FormGroup({ subcategory: new FormControl() });
  modelDetails!: CourseV2;
  fieldsDetails: FormlyFieldConfig[] = [];
  categoryData: CategoryV2[] = [];

  topicOptions$!: Observable<{ label: string; value: string | number }[]>;
  course$!: Observable<CourseV2>;
  categories$!: Observable<CategoryV2[]>;
  categoryOptions$!: Observable<{ label: string; value: string | number }[]>;
  userId$!: Observable<number>;
  courseId$!: Observable<string>;
  feedback$!: Observable<FeedbackState>;
  isLoading$ = this.loaderService.response('loading-instructor-courses-details');

  constructor(
    private store: Store,
    private routerStore: RouterStoreService,
    private projectService: ProjectService,
    private annotationService: AnnotationService,
    private breadcrumbService: BreadcrumbService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.userId$ = this.store.select(selectUserId);
    this.courseId$ = this.routerStore.getParam('courseId');
    this.course$ = this.store.select(selectCourse);

    this.courseId$.pipe(untilDestroyed(this)).subscribe((courseId) => {
      this.courseId = courseId;
    });

    this.topicOptions$ = this.store
      .select(selectTopics)
      .pipe(map((topics) => topics.map((topic) => ({ label: _.startCase(topic.name), value: topic.id }))));

    this.categories$ = this.store.select(selectCategories);
    this.categoryOptions$ = this.categories$.pipe(
      map((categories) => categories.map((category) => ({ label: _.startCase(category.name), value: category.id })))
    );

    this.categories$.pipe(untilDestroyed(this)).subscribe((categories) => {
      // need this since we can't convert props.options for subcategory field to be an observable
      // we still used function based formly expression
      this.categoryData = categories;
    });

    this.feedback$ = this.store.select(selectFeedback);

    combineLatest([this.course$, this.categories$])
      .pipe(
        filter(([course, categories]) => !!course && categories.length > 0),
        untilDestroyed(this)
      )
      .subscribe(([course]) => {
        this.breadcrumbGenerator(course.title);
        this.modelDetails = {
          ...this.modelDetails,
          ...course,
        };
        this.initFields();
      });

    // handle feedback modal open/close
    this.store
      .select(selectFeedback)
      .pipe(
        map((feedback) => feedback.isFeedbackModalOpen),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe((isFeedbackModalOpen) => {
        if (this.feedbackModal) {
          if (isFeedbackModalOpen) {
            this.feedbackModal.open();
          } else {
            this.feedbackModal.close();
          }
        }
      });
  }

  breadcrumb: { label: string; url: string }[] = [];
  breadcrumbGenerator(courseTitle: string) {
    this.breadcrumb = [
      {
        label: 'Courses',
        url: '/courses',
      },
      {
        label: 'Overview',
        url: '/courses/overview',
      },
      {
        label: `${courseTitle}`,
        url: '',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(this.breadcrumb);
  }

  initFields(disabled: boolean = this.readOnly) {
    if (!disabled) {
      this.courseState = '1';
    }

    this.fieldsDetails = [
      {
        fieldGroupClassName: 'card block border border-neutral-100 py-9 px-6 mb-4 rounded-[10px]',
        fieldGroup: [
          {
            className: 'section-label block border-b-[2px] border-neutral-200 pb-3 mb-3',
            template: '<h5>Details</h5>',
          },
          {
            key: 'title',
            type: 'input',
            wrappers: ['feedback-field'],
            defaultValue: '#SAP MM Material Management',
            className: 'relative',
            props: {
              label: 'Title',
              minLength: 6,
              maxLength: 60,
              placeholder: 'e.g. Learn Blender in 60 minutes',
              required: true,
              disabled: disabled,
              tips: 'Course title',
              updated: {
                by: 'Instructor',
                date: this.formatDate(this.modelDetails.date_updated),
              },
              openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
            },
            validation: {
              messages: {
                minLength: "Title can't be lower than 6 characters.",
              },
            },
            expressions: {
              'props.feedback': this.getFeedbackCount('title'),
            },
          },
          {
            key: 'subtitle',
            type: 'input',
            wrappers: ['feedback-field'],
            defaultValue: 'Learn how to create anything from shapes in Blender!',
            className: 'relative',
            props: {
              label: 'Subtitle',
              minLength: 6,
              maxLength: 250,
              placeholder: 'e.g. Learn how to create anything from shapes in Blender!',
              disabled: disabled,
              tips: 'Course subtitle',
              // updated: {
              //   by: 'Instructor',
              //   date: 'Aug 03, 2022 10:24:00 AM',
              // },
              openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
            },
            validation: {
              messages: {
                minLength: "This field can't be lower than 6 characters.",
              },
            },
            expressions: {
              'props.feedback': this.getFeedbackCount('subtitle'),
            },
          },
          {
            key: 'topics',
            type: 'select',
            defaultValue: ['559409e1-6873-4afd-b93d-b29088bad599', '430a8092-90a7-436e-9553-595ecde6e0be'],
            wrappers: ['feedback-field'],
            className: 'relative',
            props: {
              label: 'Topics',
              placeholder: 'Choose topic',
              multiple: true,
              stayPlaceholder: true,
              disabled: disabled,
              tips: 'Course topics',
              // updated: {
              //   by: 'Instructor',
              //   date: 'Aug 03, 2022 10:24:00 AM',
              // },
              openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
            },
            expressions: {
              'props.options': this.topicOptions$,
              'props.feedback': this.getFeedbackCount('topics'),
            },
          },
          {
            key: 'desc',
            type: 'textarea',
            defaultValue:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur commodo placerat fringilla. Sed vel justo sit amet diam mollis pulvinar. Nunc vitae dignissim mi. Cras blandit sapien a rutrum varius. Mauris in nunc ut dolor tincidunt semper ac id risus. Morbi sit amet massa vitae turpis vehicula iaculis. Maecenas convallis rutrum felis at ultrices. Maecenas cursus, leo in bibendum sollicitudin, metus nulla faucibus purus, eu ornare tellus lorem at nisl. Morbi nibh velit, consectetur et interdum eu, ornare ut ex. In malesuada vel tortor at euismod. Pellentesque mollis neque nisi, in tincidunt sem blandit ut. Pellentesque mauris tellus, malesuada vitae nunc ut, mollis ultricies urna. Duis posuere velit ut porta facilisis. Curabitur dictum orci felis, vitae faucibus nisi mattis quis. Nullam iaculis massa a magna finibus dapibus. Praesent dictum ultricies elit, interdum vestibulum felis imperdiet id. Nam rutrum enim in finibus malesuada. Nam auctor interdum urna, nec facilisis est aliquet eu. Mauris dictum, ligula non fermentum sollicitudin, justo elit elementum dolor, et luctus felis nunc sed ipsum. Morbi laoreet semper eros, ac vestibulum ante molestie sit amet. Integer tellus justo, fermentum quis aliquet at, maximus in sapien. Etiam in accumsan nisl. Integer bibendum, arcu eget fringilla fermentum, enim dolor laoreet purus, eu bibendum ipsum felis eu dolor. In dui risus, tincidunt eget condimentum vitae, aliquet ut tortor. Donec blandit pharetra orci, non mattis diam euismod vel. Suspendisse potenti.',
            wrappers: ['feedback-field'],
            className: 'relative',
            props: {
              label: 'Description',
              minLength: 100,
              maxLength: 1000,
              placeholder: 'Course description...',
              minHeight: '170px',
              maxHeight: '300px',
              disabled: disabled,
              tips: 'Course description',
              // updated: {
              //   by: 'Instructor',
              //   date: 'Aug 03, 2022 10:24:00 AM',
              // },
              openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
            },
            expressions: {
              'props.feedback': this.getFeedbackCount('desc'),
            },
          },
          {
            fieldGroupClassName: 'grid grid-cols-2 gap-6 border-b border-neutral-200 mb-3',
            fieldGroup: [
              {
                key: 'skill_level',
                type: 'select',
                defaultValue: 'Beginner',
                wrappers: ['feedback-field'],
                className: 'relative',
                props: {
                  wrapAppendClass: ['border-none', '!mb-0'],
                  label: 'Level',
                  placeholder: 'Choose a level',
                  stylish: true,
                  options: [
                    { label: 'All', value: 'all' },
                    { label: 'Beginner', value: 'beginner' },
                    { label: 'Intermediate', value: 'intermediate' },
                    { label: 'Expert', value: 'expert' },
                  ],
                  disabled: disabled,
                  tips: 'Course level',
                  // updated: {
                  //   by: 'Instructor',
                  //   date: 'Aug 03, 2022 10:24:00 AM',
                  // },
                  openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
                },
                expressions: {
                  'props.feedback': this.getFeedbackCount('skill_level'),
                },
              },
              {
                key: 'language',
                type: 'select',
                wrappers: ['feedback-field'],
                className: 'relative',
                props: {
                  wrapAppendClass: ['border-none', '!mb-0'],
                  label: 'Language',
                  placeholder: 'Select language',
                  stylish: true,
                  options: _.sortBy(
                    _.entries(LANGUAGES).map(([key, value]) => ({ label: value, value: key })),
                    (lang) => lang.label
                  ),
                  disabled: disabled,
                  tips: 'Course language',
                  updated: {
                    by: 'Instructor',
                    date: this.formatDate(this.modelDetails.date_updated),
                  },
                  openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
                },
                expressions: {
                  'props.feedback': this.getFeedbackCount('language'),
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'grid grid-cols-2 gap-6 border-b border-neutral-200 mb-3',
            fieldGroup: [
              {
                key: 'category',
                type: 'select',
                defaultValue: 'e6d8a0ba-7b5c-4b81-b5ae-5698cba860a1',
                wrappers: ['feedback-field'],
                className: 'relative',
                props: {
                  wrapAppendClass: ['border-none', '!mb-0'],
                  label: 'Category',
                  placeholder: 'Choose a category',
                  required: true,
                  disabled: disabled,
                  tips: 'Course category',
                  updated: {
                    by: 'Instructor',
                    date: this.formatDate(this.modelDetails.date_updated),
                  },
                  openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
                },
                expressions: {
                  'props.options': this.categoryOptions$,
                  'props.feedback': this.getFeedbackCount('category'),
                },
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    field.formControl?.valueChanges.subscribe((value) => {
                      this.formDetails.controls['subcategory'].setValue('');
                      // this.modelDetails.subcategory = '';
                    });
                  },
                },
              },
              {
                key: 'subcategory',
                type: 'select',
                wrappers: ['feedback-field'],
                className: 'relative',
                props: {
                  wrapAppendClass: ['border-none', '!mb-0'],
                  label: 'Sub category',
                  placeholder: 'Choose sub-category',
                  stylish: true,
                  options: [],
                  disabled: disabled,
                  tips: 'Course sub category',
                  openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
                },
                expressions: {
                  'props.disabled': () => {
                    if (this.modelDetails['category'] && !disabled) {
                      return false;
                    }
                    return true;
                  },
                  // 'props.options': async (field) =>
                  //   await combineLatest([this.categories$])
                  //     .pipe(
                  //       // distinctUntilChanged(([prevCategories, prevValue], [currCategories, currValue]) => prev),
                  //       tap(([categories]) => log.debug('>>> categories formValue', categories, this.modelDetails)),
                  //       filter(([categories]) => categories.length > 0),
                  //       map(([categories]) =>
                  //         categories
                  //           .filter((category) => category.id === this.modelDetails['category'])
                  //           .flatMap((category) =>
                  //             category.subcategories?.map((subcategory) => ({
                  //               label: _.startCase(subcategory.name),
                  //               value: subcategory.id,
                  //             }))
                  //           )
                  //       ),
                  //       tap((options) => log.debug('>>> subcategory options', options)),
                  //       distinctUntilChanged()
                  //     )
                  //     .toPromise(),
                  'props.options': (field) => {
                    const selectedCtgValue = this.modelDetails['category'];
                    let selectedCtg = _.find(this.categoryData, { id: selectedCtgValue });
                    if (selectedCtg && selectedCtg.subcategories?.length) {
                      const sub = selectedCtg.subcategories.map((category: any) => ({
                        label: _.startCase(category.name),
                        value: category.id,
                      }));
                      return sub;
                    }
                    return [];
                  },
                  'props.feedback': this.getFeedbackCount('subcategory'),
                },
              },
            ],
          },
        ],
      },
      {
        className: 'block border border-neutral-100 card mb-4 pt-6 px-6 rounded-[10px] relative',
        key: 'image',
        type: 'file',
        wrappers: ['feedback-field'],
        props: {
          wrapAppendClass: ['border-none'],
          label: 'Cover Image',
          placeholder: 'Upload your course image here. Supported files are .jpg, .jpeg, or .png.',
          preview: true,
          previewType: 'image',
          allowedTypes: ['image/png', 'image/jpg', 'image/jpeg'],
          uploadType: 'dashboard',
          disabled: disabled,
          tips: 'Course cover image',
          openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
        } as FileProps,
        expressions: {
          'props.metadata': combineLatest([this.course$, this.userId$]).pipe(
            map(([course, userId]) => ({
              user: userId,
              course: course.id,
            }))
          ),
          'props.previewUrl': this.course$.pipe(map((course) => course.image_url)),
          'props.feedback': this.getFeedbackCount('image'),
        },
      },
      {
        className: 'block border border-neutral-100 card mb-4 pt-6 px-6 rounded-[10px] relative',
        key: 'promo_video',
        type: 'file',
        wrappers: ['feedback-field'],
        props: {
          wrapAppendClass: ['border-none'],
          label: 'Promotional Video',
          placeholder: "Upload your course's promotional video here. Maximum of 200MB.",
          preview: true,
          previewType: 'video',
          hideLabel: true,
          allowedTypes: ['video/mp4', 'video/mkv', 'video/mov'],
          uploadType: 'dashboard',
          disabled: disabled,
          tips: 'Course promotional video',
          openFeedback: (field: FormlyFieldConfig) => this.onFeedbackOpen(field),
          onUpload: (upload, field) => {
            log.debug('upload: ', upload);
            firstValueFrom(this.courseId$).then((courseId) => {
              this.store.dispatch(
                CourseActions.uploadStart({
                  referenceId: `${courseId}-${field.key}`,
                  fileName: upload.name,
                  fileType: upload.type,
                })
              );
            });
          },
          onProgress: (progress, field) => {
            log.debug('progress: ', progress);
            firstValueFrom(this.courseId$).then((courseId) => {
              this.store.dispatch(
                CourseActions.uploadProgress({
                  referenceId: `${courseId}-${field.key}`,
                  bytesTotal: progress.bytesTotal,
                  bytesUploaded: progress.bytesUploaded,
                  progressPercent: progress.progressPercent,
                })
              );
            });
          },
          onComplete: (field) => {
            log.debug('complete');
            firstValueFrom(this.courseId$).then((courseId) => {
              this.store.dispatch(
                CourseActions.uploadComplete({
                  referenceId: `${courseId}-${field.key}`,
                })
              );
            });
          },
        } as FileProps,
        expressions: {
          'props.metadata': combineLatest([this.course$, this.userId$]).pipe(
            map(([course, userId]) => ({
              user: userId,
              course: course.id,
            })),
            distinctUntilChanged()
          ),
          'props.previewUrl': this.course$.pipe(
            map((course) => {
              return course.promo_video_url;
            }),
            distinctUntilChanged()
          ),
          'props.feedback': this.getFeedbackCount('promo_video'),
        },
      },
    ];
  }

  formatDate(dateString: string = '') {
    return moment(dateString).format('MMMM DD, YYYY');
  }

  actionEvent(event: HeaderActions) {
    log.debug('actionEvent: ', event);
    this.readOnly = true;
    this.initFields(true);
    this.courseState = '0';

    if (event === 'update') {
      this.store.dispatch(CourseActions.upsertCourse({ course: this.modelDetails }));
    } else if (event === 'accept') {
      this.projectService.toStatePublished(this.modelDetails.project);
    } else if (event === 'reject') {
      this.projectService.toStateRejected(this.modelDetails.project);
    }
  }

  async onFeedbackOpen(field: FormlyFieldConfig) {
    log.debug('onFeedbackOpen', field.key);

    this.store.dispatch(
      CourseActions.openFeedbackModal({
        fieldId: this.courseId,
        fieldKey: String(field.key),
        fieldModel: ContentTypeModels.COURSE,
        fieldValue: field.formControl?.value,
      })
    );
  }

  async onFeedbackSubmit(annotation: Partial<AnnotationDetail>) {
    log.debug('onFeedbackSubmit');
    const contentType = await firstValueFrom(
      this.store.select(selectContentType(ContentTypeApps.COURSES, ContentTypeModels.COURSE))
    );
    const fieldKey = await firstValueFrom(this.feedback$.pipe(map((feedback) => feedback.fieldKey)));
    this.store.dispatch(
      CourseActions.upsertAnnotation({
        annotation: {
          ...annotation,
          id: annotation.id ?? generateUuid(),
          content_type: contentType?.id ?? 0,
          course: this.courseId,
          feedback: annotation.feedback ?? '',
          field: fieldKey,
          object_id: this.courseId,
          required_action: annotation.required_action ?? false,
          model: ContentTypeModels.COURSE,
        },
      })
    );
  }

  onFeedbackCancel() {
    log.debug('onFeedbackCancel');

    this.store.dispatch(CourseActions.closeFeedbackModal());
  }

  private getFeedbackCount(fieldKey: string): Observable<number> {
    return this.annotationService.getAnnotationCount(fieldKey, ContentTypeModels.COURSE, this.courseId);
  }
}
