import { Component } from '@angular/core';
import { FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

export interface DatepickerProps extends FormlyFieldProps {
  format?: string;
}


@Component({
  selector: 'thk-formly-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css']
})
export class FormlyDatepickerComponent extends FieldType<FieldTypeConfig<DatepickerProps>> {
}
